export const PermittedGoalRouteNames = {
  Dashboard: 'dashboard',
  ChurchMinistryAndChurchPresentation: 'church-ministry-and-church-presentations',
  GideonCard: 'gideon-card',
  FaithFund: 'faith-fund',
  AuxiliaryScripture: 'auxiliary-scripture',
  CampDesignated: 'camp-designated',
  Placements: 'placements',
  PersonalWorkersTestaments: 'personal-workers-testaments',
  Membership: 'membership'
}
export const PermittedGoalRouteNamesArrayInOrder = [
  'church-ministry-and-church-presentations',
  'gideon-card',
  'faith-fund',
  'auxiliary-scripture',
  'camp-designated',
  'placements',
  'personal-workers-testaments',
  'membership'
]
