import axios from 'axios'
import dateData from '@/json/date.json'
import { CARD_API_CONFIG } from '../config/card.api.config.js'
import { REPORT_CONFIG } from '../config/report.config.js'

/* eslint-disable */
export const state = () => ({
  restockingVisits: [],
  restockingVisitSummary: [],
  videoShowings: [],
  videoShowingSummary: [],
  facilities: [],
  listSize: 3,
  gcfOrgKey: '',
  facilityTypes: [],
  noteTypes: [],
  contactRelationshipTypes: [],
  meetingTypes: [],
  restockingVisitDetails: {
    facility: {
      address_cnt_key: null
    }
  },
  homeStats: {
    facilityCount: 0,
    videoCount: 0,
    restockingCount: 0
  },
  videoVisitDetails: {},
  videoCSVUrl: '',
  restockingVisitCSVUrl: '',
  restockingVisitPDFUrl: '',
  videoShowingsPDFUrl: '',
  facilityCSVUrl: '',
  facilityPDFUrl: '',
  facilityDetailCSVUrl: '',
  facilityDetailPDFUrl: '',
  facilityDetails: {
    summary: {
      org_name: ''
    },
    videoList: [],
    restockingList: [],
    addresses: [],
    contactInfo: {
      fax_phone_info: [],
      email_info: []
    },
    contacts: [],
    notes: []
  },
  addressData: {
    adr_key: '',
    axe_key: '',
    cnt_key: '',
    city: '',
    state: '',
    state_key: '',
    address_line_1: '',
    address_line_2: '',
    address_line_3: '',
    postal_code: ''
  }
})

export const getters = {
  facilityCSVUrl: state => {
    return state.facilityCSVUrl
  },
  facilityPDFUrl: state => {
    return state.facilityPDFUrl
  },
  facilityDetailPDFUrl: state => {
    return state.facilityDetailPDFUrl
  },
  facilityDetailCSVUrl: state => {
    return state.facilityDetailCSVUrl
  },
  restockingVisits: state => {
    return state.restockingVisits
  },
  restockingVisitSummary: state => {
    return state.restockingVisitSummary
  },
  videoShowings: state => {
    return state.videoShowings
  },
  videoShowingSummary: state => {
    return state.videoShowingSummary
  },
  facilities: state => {
    return state.facilities
  },
  facilitiesForSelection: state => {
    let facilitiesSelection = state.facilities
      .sort((a, b) => {
        let alpha = a.facility_name.toUpperCase()
        let beta = b.facility_name.toUpperCase()
        return alpha < beta ? -1 : alpha > beta ? 1 : 0
      })
      .map(tr => {
        return { text: tr.facility_name, value: tr.gcf_org_key }
      })
    return facilitiesSelection
  },
  addressData: state => {
    return state.addressData
  },
  getGCFOrgKey: state => {
    return state.gcfOrgKey
  },
  monthNames: state => {
    return state.monthNames
  },
  getFacilitySummary: state => {
    return state.facilityDetails.summary
  },
  getFacilityVideoList: state => {
    return state.facilityDetails.videoList
  },
  getFacilityRestockingList: state => {
    return state.facilityDetails.restockingList
  },
  getLastFacilityRestockingVisit: state => {
    return state.facilityDetails.restockingList[0]
  },
  getLastVideoList: state => {
    return state.facilityDetails.videoList[0]
  },
  getAddresses: state => {
    return state.facilityDetails.addresses
  },
  getContactInfo: state => {
    return state.facilityDetails.contactInfo
  },
  getContacts: state => {
    return state.facilityDetails.contacts
  },
  getHomeStats: state => {
    return state.homeStats
  },
  getNotes: state => {
    return state.facilityDetails.notes
  },
  getFacilityTypes: state => {
    return state.facilityTypes
  },
  getNoteTypes: state => {
    return state.noteTypes
  },
  getContactRelationshipTypes: state => {
    return state.contactRelationshipTypes
  },
  getMeetingTypes: state => {
    return state.meetingTypes
  },
  getRestockingVisitDetails: state => {
    return state.restockingVisitDetails
  },
  getVideoVisitDetails: state => {
    return state.videoVisitDetails
  },
  videoCSVUrl: state => {
    return state.videoCSVUrl
  },
  restockingVisitCSVUrl: state => {
    return state.restockingVisitCSVUrl
  },
  restockingVisitPDFUrl: state => {
    return state.restockingVisitPDFUrl
  },
  videoShowingsPDFUrl: state => {
    return state.videoShowingsPDFUrl
  }
}

export const SET_ADDRESS_DATA = 'SET_ADDRESS_DATA'
export const SET_RESTOCKING_VISITS = 'SET_RESTOCKING_VISITS'
export const SET_RESTOCKING_VISIT_SUMMARY = 'SET_RESTOCKING_VISIT_SUMMARY'
export const SET_VIDEO_SHOWINGS = 'SET_VIDEO_SHOWINGS'
export const SET_VIDEO_SHOWING_SUMMARY = 'SET_VIDEO_SHOWING_SUMMARY'
export const SET_FACILITIES = 'SET_FACILITIES'
export const SET_GCF_ORG_KEY = 'SET_GCF_ORG_KEY'
export const SET_HOME_STATS = 'SET_HOME_STATS'
export const SET_FACILITY_SUMMARY = 'SET_FACILITY_SUMMARY'
export const SET_FACILITY_DETAILS = 'SET_FACILITY_DETAILS'
export const SET_FACILITY_VIDEO_LIST = 'SET_FACILITY_VIDEO_LIST'
export const SET_FACILITY_RESTOCKING_LIST = 'SET_FACILITY_RESTOCKING_LIST'
export const SET_FACILITY_ADDRESSES = 'SET_FACILITY_ADDRESSES'
export const SET_FACILITY_CONTACT_INFO = 'SET_FACILITY_CONTACT_INFO'
export const SET_FACILITY_CONTACTS = 'SET_FACILITY_CONTACTS'
export const SET_FACILITY_NOTES = 'SET_FACILITY_NOTES'
export const SET_FACILITY_TYPES = 'SET_FACILITY_TYPES'
export const SET_NOTE_TYPES = 'SET_NOTE_TYPES'
export const SET_CONTACT_RELATIONSHIP_TYPES = 'SET_CONTACT_RELATIONSHIP_TYPES'
export const SET_MEETING_TYPES = 'SET_MEETING_TYPES'
export const SET_RESTOCKING_VISIT_DETAILS = 'SET_RESTOCKING_VISIT_DETAILS'
export const SET_VIDEO_VISIT_DETAILS = 'SET_VIDEO_VISIT_DETAILS'
export const SET_FACILITY_CSV_URL = 'SET_FACILITY_CSV_URL'
export const SET_FACILITY_PDF_URL = 'SET_FACILITY_PDF_URL'
export const SET_FACILITY_DETAIL_CSV_URL = 'SET_FACILITY_DETAIL_CSV_URL'
export const SET_FACILITY_DETAIL_PDF_URL = 'SET_FACILITY_DETAIL_PDF_URL'
export const SET_RESTOCKING_VISIT_CSV_URL = 'SET_RESTOCKING_VISIT_CSV_URL'
export const SET_RESTOCKING_VISIT_PDF_URL = 'SET_RESTOCKING_VISIT_PDF_URL'
export const SET_VIDEO_SHOWINGS_PDF_URL = 'SET_VIDEO_SHOWINGS_PDF_URL'
export const SET_VIDEO_PRESENTATION_CSV_URL = 'SET_VIDEO_PRESENTATION_CSV_URL'

export const mutations = {
  [SET_ADDRESS_DATA](state, data) {
    state.addressData = data
  },
  [SET_RESTOCKING_VISITS](state, data) {
    state.restockingVisits = data
  },
  [SET_RESTOCKING_VISIT_SUMMARY](state) {
    let restockingVisits = state.restockingVisits
    state.restockingVisitSummary = restockingVisits.slice(0, state.listSize).map(visit => {
      let activityDate = new Date(visit.activity_date)
      let monthName = dateData.months.find(mi => parseInt(mi.value) === activityDate.getMonth() + 1).abbr
      return {
        title: visit.facility_name,
        description: visit.city,
        visit_key: visit.mtg_key,
        destination: `/programs/gc/profile/restocking-visit-details`,
        date: {
          month: monthName,
          day: activityDate.getDate(),
          year: activityDate.getFullYear()
        }
      }
    })
  },
  [SET_VIDEO_SHOWINGS](state, data) {
    state.videoShowings = data
  },
  [SET_HOME_STATS](state, data) {
    state.homeStats = data
  },
  [SET_VIDEO_SHOWING_SUMMARY](state) {
    let videoShowings = state.videoShowings
    state.videoShowingSummary = videoShowings.slice(0, state.listSize).map(videoShowing => {
      let activityDate = new Date(videoShowing.activity_date)
      let monthName = dateData.months.find(mi => parseInt(mi.value) === activityDate.getMonth() + 1).abbr
      return {
        title: videoShowing.facility_name,
        schedule: videoShowing.city,
        description: videoShowing.presenter_name,
        visit_key: videoShowing.mtg_key,
        destination: `/programs/gc/profile/video-visit`,
        date: {
          month: monthName,
          day: activityDate.getDate(),
          year: activityDate.getFullYear()
        }
      }
    })
  },
  [SET_FACILITIES](state, data) {
    state.facilities = data
  },
  [SET_GCF_ORG_KEY](state, data) {
    state.gcfOrgKey = data
  },
  [SET_FACILITY_SUMMARY](state, data) {
    state.facilityDetails.summary = data
  },
  [SET_FACILITY_DETAILS](state, data) {
    state.facilityDetails = data
  },
  [SET_FACILITY_VIDEO_LIST](state, data) {
    state.facilityDetails.videoList = data
  },
  [SET_FACILITY_RESTOCKING_LIST](state, data) {
    state.facilityDetails.restockingList = data
  },
  [SET_FACILITY_ADDRESSES](state, data) {
    state.facilityDetails.addresses = data
    // item_description is for anything of the ServiceCard type
    state.facilityDetails.addresses.map(ad => {
      ad.item_description = 'address'
    })
  },
  [SET_FACILITY_CONTACT_INFO](state, data) {
    state.facilityDetails.contactInfo = data
    // item_description is for anything of the ServiceCard type
    state.facilityDetails.contactInfo.fax_phone_info.map(ph => {
      ph.item_description = 'phone number'
    })
    state.facilityDetails.contactInfo.email_info.map(ph => {
      ph.item_description = 'email address'
    })
  },
  [SET_FACILITY_CONTACTS](state, data) {
    state.facilityDetails.contacts = data
  },
  [SET_FACILITY_NOTES](state, data) {
    state.facilityDetails.notes = data
  },
  [SET_FACILITY_TYPES](state, data) {
    state.facilityTypes = data
  },
  [SET_NOTE_TYPES](state, data) {
    state.noteTypes = data
  },
  [SET_CONTACT_RELATIONSHIP_TYPES](state, data) {
    state.contactRelationshipTypes = data
  },
  [SET_RESTOCKING_VISIT_DETAILS](state, data) {
    state.restockingVisitDetails = data
  },
  [SET_VIDEO_VISIT_DETAILS](state, data) {
    state.videoVisitDetails = data
  },
  [SET_MEETING_TYPES](state, data) {
    state.meetingTypes = data
  },
  [SET_FACILITY_CSV_URL](state, data) {
    state.facilityCSVUrl = data
  },
  [SET_FACILITY_PDF_URL](state, data) {
    state.facilityPDFUrl = data
  },
  [SET_FACILITY_DETAIL_CSV_URL](state, data) {
    state.facilityDetailCSVUrl = data
  },
  [SET_FACILITY_DETAIL_PDF_URL](state, data) {
    state.facilityDetailPDFUrl = data
  },
  [SET_RESTOCKING_VISIT_CSV_URL](state, data) {
    state.restockingVisitCSVUrl = data
  },
  [SET_RESTOCKING_VISIT_PDF_URL](state, data) {
    state.restockingVisitPDFUrl = data
  },
  [SET_VIDEO_SHOWINGS_PDF_URL](state, date) {
    state.videoShowingsPDFUrl = date
  },
  [SET_VIDEO_PRESENTATION_CSV_URL](state, data) {
    state.videoCSVUrl = data
  }
}

export const actions = {
  async clearVideoVisitDetails({ commit }) {
    await commit('SET_VIDEO_VISIT_DETAILS', {})
  },
  async clearRestockingVisitDetails({ commit }) {
    let emptyRSV = {
      facility: {
        address_cnt_key: null
      }
    }
    await commit('SET_RESTOCKING_VISIT_DETAILS', emptyRSV)
  },
  async clearFacilityDetails({ commit }) {
    let emptyDetails = {
      summary: {},
      videoList: [],
      restockingList: [],
      addresses: [],
      contactInfo: {
        fax_phone_info: [],
        email_info: []
      },
      contacts: [],
      notes: []
    }
    await commit('SET_FACILITY_DETAILS', emptyDetails)
  },
  async setSelectedGCFOrgKey({ commit }, data) {
    await commit('SET_GCF_ORG_KEY', data)
  },
  async getRestockingVisits({ commit, rootGetters }) {
    const response = await apiCalls.getRestockingVisits(rootGetters['user/userCampKey'])
    if (response && response.status === 200) {
      await commit('SET_RESTOCKING_VISITS', response.data.visits)
      await commit('SET_RESTOCKING_VISIT_SUMMARY')
    }
  },
  async getHomeStats({ commit, rootGetters }) {
    const response = await apiCalls.getHomeStats(rootGetters['user/userCampKey'])
    if (response && response.status === 200) {
      await commit('SET_HOME_STATS', response.data)
    }
  },
  async getVideoShowings({ commit, rootGetters }, data) {
    const response = await apiCalls.getVideoShowings(rootGetters['user/userCampKey'], data.startDate, data.endDate)
    if (response && response.status === 200) {
      await commit('SET_VIDEO_SHOWINGS', response.data.visits)
      await commit('SET_VIDEO_SHOWING_SUMMARY')
    }
  },
  async getFacilities({ commit, rootGetters }) {
    const response = await apiCalls.getFacilities(rootGetters['user/userCampKey'])
    if (response && response.status === 200) {
      await commit('SET_FACILITIES', response.data.facilities)
    }
  },
  async getProfile({ commit }, gcfOrgKey) {
    const response = await apiCalls.getFacilitySummary(gcfOrgKey)
    if (response && response.status === 200) {
      await commit('SET_FACILITY_SUMMARY', response.data.summary)
    }
  },
  async getFacilityVideoVisits({ commit }, gcfOrgKey) {
    const response = await apiCalls.getFacilityVideoVisitList(gcfOrgKey)
    if (response && response.status === 200) {
      await commit('SET_FACILITY_VIDEO_LIST', response.data.list)
    }
  },
  async getFacilityRestockingVisits({ commit }, gcfOrgKey) {
    const response = await apiCalls.getFacilityRestockingVisitList(gcfOrgKey)

    if (response && response.status === 200) {
      await commit('SET_FACILITY_RESTOCKING_LIST', response.data.list)
    }
  },
  async getFacilityAddresses({ commit }, gcfOrgKey) {
    const response = await apiCalls.getFacilityAddresses(gcfOrgKey)

    if (response && response.status === 200) {
      await commit('SET_FACILITY_ADDRESSES', response.data.addresses)
    }
  },
  async getFacilityContactInfo({ commit }, gcfOrgKey) {
    const response = await apiCalls.getFacilityContactInfo(gcfOrgKey)

    if (response && response.status === 200) {
      await commit('SET_FACILITY_CONTACT_INFO', response.data)
    }
  },
  async getFacilityContacts({ commit }, gcfOrgKey) {
    const response = await apiCalls.getFacilityContacts(gcfOrgKey)
    if (response && response.status === 200) {
      await commit('SET_FACILITY_CONTACTS', response.data)
    }
  },
  async getFacilityNotes({ commit }, gcfOrgKey) {
    const response = await apiCalls.getFacilityNotes(gcfOrgKey)
    if (response && response.status === 200) {
      await commit('SET_FACILITY_NOTES', response.data)
    }
  },
  async retrieveRestockingVisitDetails({ commit }, mtgKey) {
    let isNew = !mtgKey
    const response = await apiCalls.getRestockingVisitDetails(mtgKey, isNew)
    if (response && response.status === 200) {
      await commit('SET_RESTOCKING_VISIT_DETAILS', response.data)
    }
  },
  async retrieveVideoVisitDetails({ commit }, mtgKey) {
    const response = await apiCalls.retrieveVideoVisitDetails(mtgKey)
    if (response && response.status === 200) {
      await commit('SET_VIDEO_VISIT_DETAILS', response.data)
    }
  },
  async retrieveFacilityTypes({ commit, state }) {
    if (state.facilityTypes.length === 0) {
      const response = await apiCalls.getFacilityTypes()
      if (response && response.status === 200) {
        await commit('SET_FACILITY_TYPES', response.data)
      }
    }
  },
  async getNoteTypes({ commit }) {
    const response = await apiCalls.getNoteTypes()
    if (response && response.status === 200) {
      await commit('SET_NOTE_TYPES', response.data)
    }
  },
  async getContactRelationshipTypes({ commit }) {
    const response = await apiCalls.getContactRelationshipTypes()
    if (response && response.status === 200) {
      await commit('SET_CONTACT_RELATIONSHIP_TYPES', response.data)
    }
  },
  async retrieveMeetingTypes({ commit, state }) {
    if (state.meetingTypes.length === 0) {
      const response = await apiCalls.getMeetingTypes()
      if (response && response.status === 200) {
        await commit('SET_MEETING_TYPES', response.data)
      }
    }
  },
  async addFacility({ }, facility) {
    const response = await apiCalls.addFacility(facility)
    if (response && response.status === 200) {
      return response.data
    }
    return false
  },
  async upsertFacility({ }, data) {
    const response = await apiCalls.upsertFacility(data)
    if (response && response.status === 200) {
      return response.data
    }
    return false
  },
  async editFacility({ }, facility) {
    const response = await apiCalls.editFacility(facility)
    if (response && response.status === 200) {
      return response.data
    }
    return false
  },
  async upsertAddress({ }, address) {
    const response = await apiCalls.upsertAddress(address)
    if (response && response.status === 200) {
      return response.data
    }
    return false
  },
  async upsertEmail({ }, email) {
    const response = await apiCalls.upsertEmail(email)
    if (response && response.status === 200) {
      return response.data
    }
    return false
  },
  async upsertPhone({ }, phone) {
    const response = await apiCalls.upsertPhone(phone)
    if (response && response.status === 200) {
      return response.data
    }
    return false
  },
  async upsertContact({ }, phone) {
    const response = await apiCalls.upsertContact(phone)
    if (response && response.status === 200) {
      return response.data
    }
    return false
  },
  async upsertNote({ }, phone) {
    const response = await apiCalls.upsertNote(phone)
    if (response && response.status === 200) {
      return response.data
    }
    return false
  },
  async upsertRestockingVisit({ }, data) {
    const response = await apiCalls.upsertRestockingVisit(data)
    if (response && response.status === 200) {
      return response.data
    }
    return false
  },
  async deleteVideoPresentation({ }, data) {
    const response = await apiCalls.deleteVideoPresentation(data)
    if (response && response.status === 200) {
      return response.data
    }
    return false
  },
  async upsertVideoVisit({ }, data) {
    const response = await apiCalls.upsertVideoVisit(data)
    if (response && response.status === 200) {
      return response.data
    }
    return false
  },
  getVideoCSVUrl({ commit, rootGetters }, data) {
    let orgKey = rootGetters['user/userCampKey']
    let lngKey = rootGetters['user/userLanguageKey']
    const csvUrl = REPORT_CONFIG.getVideoShowingsReport(orgKey, lngKey, data.startDate, data.endDate, 'excel')
    commit('SET_VIDEO_PRESENTATION_CSV_URL', csvUrl)
  },
  getRestockingVisitCSVUrl({ commit, rootGetters }) {
    let orgKey = rootGetters['user/userCampKey']
    let lngKey = rootGetters['user/userLanguageKey']
    const csvUrl = REPORT_CONFIG.getRestockingVisitReport(orgKey, lngKey, 'excel')
    commit('SET_RESTOCKING_VISIT_CSV_URL', csvUrl)
  },
  getRestockingVisitPDFUrl({ commit, rootGetters }) {
    let orgKey = rootGetters['user/userCampKey']
    let lngKey = rootGetters['user/userLanguageKey']
    const pdfUrl = REPORT_CONFIG.getRestockingVisitReport(orgKey, lngKey, 'pdf')
    commit('SET_RESTOCKING_VISIT_PDF_URL', pdfUrl)
  },
  getVideoShowingsPDFUrl({ commit, rootGetters }, data) {
    let orgKey = rootGetters['user/userCampKey']
    let lngKey = rootGetters['user/userLanguageKey']
    const pdfUrl = REPORT_CONFIG.getVideoShowingsReport(orgKey, lngKey, data.startDate, data.endDate, 'pdf')
    commit('SET_VIDEO_SHOWINGS_PDF_URL', pdfUrl)
  },
  getFacilitiesReportsUrls({ commit, rootGetters }) {
    let orgKey = rootGetters['user/userCampKey']
    let lngKey = rootGetters['user/userLanguageKey']
    const csvUrl = REPORT_CONFIG.gideonCardFacilitiesReport(orgKey, lngKey, 'excel')
    const pdfUrl = REPORT_CONFIG.gideonCardFacilitiesReport(orgKey, lngKey, 'pdf')
    commit('SET_FACILITY_CSV_URL', csvUrl)
    commit('SET_FACILITY_PDF_URL', pdfUrl)
  },
  getFacilityDetailReportUrls({ commit, rootGetters }, orgKey) {
    let lngKey = rootGetters['user/userLanguageKey']
    const csvUrl = REPORT_CONFIG.gideonCardFacilityDetailsReport(orgKey, lngKey, 'excel')
    const pdfUrl = REPORT_CONFIG.gideonCardFacilityDetailsReport(orgKey, lngKey, 'pdf')
    commit('SET_FACILITY_DETAIL_CSV_URL', csvUrl)
    commit('SET_FACILITY_DETAIL_PDF_URL', pdfUrl)
  },
  async setAddressData({ commit }, data) {
    await commit('SET_ADDRESS_DATA', data)
  }
}

export const apiCalls = {
  getHomeStats(orgKey) {
    const route = CARD_API_CONFIG.getHomeStats(orgKey)
    return axios.get(route)
  },
  getRestockingVisits(orgKey) {
    const route = CARD_API_CONFIG.getRestockingVisits(orgKey)
    return axios.get(route)
  },
  getVideoShowings(orgKey, startDate, endDate) {
    const route = CARD_API_CONFIG.getVideoShowings(orgKey, startDate, endDate)
    return axios.get(route)
  },
  getFacilities(orgKey) {
    const route = CARD_API_CONFIG.getFacilities(orgKey)
    return axios.get(route)
  },
  getFacilitySummary(gcfOrgKey) {
    const route = CARD_API_CONFIG.getFacilitySummary(gcfOrgKey)
    return axios.get(route)
  },
  getFacilityRestockingVisitList(gcfOrgKey) {
    const route = CARD_API_CONFIG.getFacilityRestockingVisitList(gcfOrgKey)
    return axios.get(route)
  },
  getFacilityVideoVisitList(gcfOrgKey) {
    const route = CARD_API_CONFIG.getFacilityVideoVisitList(gcfOrgKey)
    return axios.get(route)
  },
  getFacilityAddresses(gcfOrgKey) {
    const route = CARD_API_CONFIG.getFacilityAddresses(gcfOrgKey)
    return axios.get(route)
  },
  getFacilityContactInfo(gcfOrgKey) {
    const route = CARD_API_CONFIG.getFacilityContactInfo(gcfOrgKey)
    return axios.get(route)
  },
  getFacilityContacts(gcfOrgKey) {
    const route = CARD_API_CONFIG.getFacilityContacts(gcfOrgKey)
    return axios.get(route)
  },
  getFacilityNotes(gcfOrgKey) {
    const route = CARD_API_CONFIG.getFacilityNotes(gcfOrgKey)
    return axios.get(route)
  },
  getRestockingVisitDetails(mtgKey, isNew) {
    const route = CARD_API_CONFIG.getRestockingVisitDetails(mtgKey, isNew)
    return axios.get(route)
  },
  retrieveVideoVisitDetails(mtgKey) {
    const route = CARD_API_CONFIG.getVideoVisitDetails(mtgKey)
    return axios.get(route)
  },
  getFacilityTypes() {
    const route = CARD_API_CONFIG.getFacilityTypes()
    return axios.get(route)
  },
  getNoteTypes() {
    const route = CARD_API_CONFIG.getNoteTypes()
    return axios.get(route)
  },
  getContactRelationshipTypes() {
    const route = CARD_API_CONFIG.getContactRelationshipTypes()
    return axios.get(route)
  },
  getMeetingTypes() {
    const route = CARD_API_CONFIG.getMeetingTypes()
    return axios.get(route)
  },
  addFacility(data) {
    const route = CARD_API_CONFIG.addFacility()
    return axios.post(route, data)
  },
  editFacility(data) {
    const route = CARD_API_CONFIG.editFacility()
    return axios.post(route, data)
  },
  deleteVideoPresentation(data) {
    const route = CARD_API_CONFIG.deleteVideoPresentation(data.mtg_key, data.ind_key)
    return axios.get(route)
  },
  upsertAddress(data) {
    const route = CARD_API_CONFIG.upsertCardAddress()
    return axios.post(route, data)
  },
  upsertFacility(data) {
    const route = CARD_API_CONFIG.upsertFacility()
    return axios.post(route, data)
  },
  upsertEmail(data) {
    const route = CARD_API_CONFIG.upsertCardEmail()
    return axios.post(route, data)
  },
  upsertPhone(data) {
    const route = CARD_API_CONFIG.upsertCardPhone()
    return axios.post(route, data)
  },
  upsertContact(data) {
    const route = CARD_API_CONFIG.upsertCardContact()
    return axios.post(route, data)
  },
  upsertNote(data) {
    const route = CARD_API_CONFIG.upsertCardNote()
    return axios.post(route, data)
  },
  upsertRestockingVisit(data) {
    const route = CARD_API_CONFIG.upsertRestockingVisit()
    return axios.post(route, data)
  },
  upsertVideoVisit(data) {
    const route = CARD_API_CONFIG.upsertVideoVisit()
    return axios.post(route, data)
  }
}

export const card = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
