import { MEMBER_API_CONFIG } from '@/config/member.api.config'
import axios from 'axios'

/* eslint-disable */
export const state = () => ({
  announcement: {},
  events: [],
  officer_status: {},
  prayerRequest: [],
  slides: [],
  stats: [
    {
      data: {
        before: '',
        current: -1,
        full: 0,
        after: ''
      },
      title: 'Scripture Orders',
      href: '#'
    },
    {
      data: {
        before: '',
        current: -1,
        full: 0,
        after: ''
      },
      title: 'New Members',
      href: '#'
    },
    {
      data: {
        before: '$',
        current: -1,
        full: 0,
        after: ''
      },
      title: 'Scripture Receipts',
      href: '#'
    }
  ],
  successStory: {
    title: '',
    description: ''
  },
  testimony: {
    title: '',
    description: ''
  }
})

export const getters = {
  announcement: state => {
    return !!state.announcement
      ? {
        title: state.announcement.ann_title,
        text: state.announcement.ann_text
      }
      : {}
  },
  homeStats: state => {
    return state.stats
  },
  prayerRequest: state => {
    return state.prayerRequest
  },
  bannerSlides: state => {
    return state.slides
  },
  officerStatus: state => {
    return state.officer_status
  },
  successStory: state => {
    return state.successStory
  },
  testimony: state => {
    return state.testimony
  },
  upcomingEvents: state => {
    return state.events || []
  }
}

export const SET_ANNOUNCEMENT = 'SET_ANNOUNCEMENT'
export const SET_EVENTS = 'SET_EVENTS'
export const SET_OFFICER_STATUS = 'SET_OFFICER_STATUS'
export const SET_PRAYER_REQUEST = 'SET_PRAYER_REQUEST'
export const SET_SLIDES = 'SET_SLIDES'
export const SET_STATS = 'SET_STATS'
export const SET_SUCCESS_STORY = 'SET_SUCCESS_STORY'
export const SET_TESTIMONY = 'SET_TESTIMONY'

export const mutations = {
  [SET_ANNOUNCEMENT](state, data) {
    state.announcement = data
  },
  [SET_EVENTS](state, data) {
    state.events = data
  },
  [SET_OFFICER_STATUS](state, data) {
    state.officer_status = data
  },
  [SET_PRAYER_REQUEST](state, data) {
    state.prayerRequest = data
  },
  [SET_SLIDES](state, data) {
    state.slides = data
  },
  [SET_STATS](state, data) {
    state.stats[0].data.current = data.totalPlCampActuals
    state.stats[0].data.full = data.totalPlCampGoals
    state.stats[1].data.current = data.totalMem
    let reciptcurrent = data.totalRSCampActuals
    state.stats[2].data.full = data.totalRSCampGoals
    state.stats[2].data.current = !!reciptcurrent ? reciptcurrent.toFixed(0) : 0
  },
  [SET_SUCCESS_STORY](state, data) {
    state.successStory = data || {
      title: '',
      description: 'Click below to submit or view success stories.'
    }
  },
  [SET_TESTIMONY](state, data) {
    state.testimony = data || {
      title: '',
      description: 'Click below to submit or view testimonies.'
    }
  }
}

export const actions = {
  async setOfficerToolbarDefault({ commit, rootGetters }, payload) {
    try {
      if (!rootGetters['user/userId']) {
        return false
      }
      const response = await apiCalls.setOfficerToolbarDefault(payload)
      if (response && response.status === 200) {
        await Promise.all([
          await (commit('user/SET_DEMOGRAPH', response.data.profile, {
            root: true
          }),
            await commit('SET_OFFICER_STATUS', {
              officer: response.data.officer,
              zone_officer: response.data.zone_officer,
              state_officer: response.data.state_officer
            }),
            await commit('user/SAVE_OFFICER_TOOLBAR_REGIONS_ZONES_SELECTION', response.data.profile.region_zone, {
              root: true
            }),
            await commit('user/SAVE_OFFICER_TOOLBAR_COUNTRIES_STATES_SELECTION', rootGetters['user/userStateKey'], {
              root: true
            }),
            await commit('user/SAVE_OFFICER_TOOLBAR_CAMPS_SELECTION', response.data.profile.camp_key, {
              root: true
            }))
        ])
        return true
      } else {
        return false
      }
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async setHomePageWidgetData({ commit, rootGetters }, payload) {
    try {
      if (!rootGetters['user/userId']) {
        return false
      }
      const responseApi = await apiCalls.setHomePageWidgetData(payload)
      const response = responseApi[0]
      const response2 = responseApi[1]
      if (response && response.status === 200) {
        await Promise.all([
          await commit('user/SET_DEMOGRAPH_CHURCH_VISITS', response.data.member, {
            root: true
          }),
          await commit('SET_ANNOUNCEMENT', response.data.announcement),
          await commit('SET_SUCCESS_STORY', response.data.story),
          await commit('SET_TESTIMONY', response.data.testimony),
          await commit('SET_EVENTS', response.data.events),
          await commit('SET_PRAYER_REQUEST', response.data.prayer)
        ])
        if (response2 && response2.status === 200) {
          await Promise.all([await commit('SET_STATS', response2.data)])
        }
        return true
      } else {
        throw 'bad response from setHomePageWidgetData call'
      }
    } catch (e) {
      console.error(e)
      await commit('SET_PRAYER_REQUEST', {})
      return false
    }
  },
  async setHomeBannerWidgetData({ commit, rootGetters }) {
    try {
      const response = await apiCalls.setHomeBannerWidgetData(rootGetters['user/userId'])
      if (response && response.status === 200) {
        let fresh_slides = response.data
          .map(amd => {
            amd.link = amd.link.replace('[current_user]', rootGetters['user/userId'])
            return amd
          })
        await Promise.all([await commit('SET_SLIDES', fresh_slides)])
      } else {
        throw 'bad response from setHomePageWidgetData call'
      }
    } catch (e) {
      return false
    }
  }
}

export const apiCalls = {
  setHomePageWidgetData(payload) {
    const route = MEMBER_API_CONFIG.getHomePageWidgetData(payload.ind_key, payload.org_key, 'en')
    const route2 = MEMBER_API_CONFIG.getMembersCampDashboard_Home(payload.org_key, payload.lng_key)
    const request = axios.get(route)
    const request2 = axios.get(route2)
    let result = axios.all([request, request2])
    return result
  },
  setOfficerToolbarDefault(payload) {
    const route = MEMBER_API_CONFIG.getToolbarDefaults(payload.ind_key, payload.org_key)
    let result = axios.get(route)
    return result
  },
  setHomeBannerWidgetData(indkey) {
    const route = MEMBER_API_CONFIG.getBannerWidgetData(indkey)
    let result = axios.get(route)
    return result
  }
}

export const home = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
