/* eslint-disable */
export const MEMBER_API_ACTIVE_ROUTE = `${process.env.VUE_APP_API_ROUTE_ADDRESS}/member`

export const MEMBER_API_CONFIG = {
  deleteProspect: () => `${MEMBER_API_ACTIVE_ROUTE}/prospect/delete_tc3`,
  findChurch: () => `${MEMBER_API_ACTIVE_ROUTE}/church/find`,
  findIndividual: () => `${MEMBER_API_ACTIVE_ROUTE}/individual/find`,
  getAddressFormOptions: (camp_key, lng_key) =>
    `${MEMBER_API_ACTIVE_ROUTE}/GetAddressFormOptions_tc3/${camp_key}/${lng_key}`,
  getAnnouncements: (ind_key, org_key) => `${MEMBER_API_ACTIVE_ROUTE}/GetAnnouncements/${ind_key}/${org_key}`,
  getCampStateLeadership: camp_or_state => `${MEMBER_API_ACTIVE_ROUTE}/camp_state/leadership/${camp_or_state}`,
  getCountryForCamp: org_key => `${MEMBER_API_ACTIVE_ROUTE}/country/GetByCamp/${org_key}`,
  getStateLeadership: (org_key, lng_key) => `${MEMBER_API_ACTIVE_ROUTE}/state/leadership/${org_key}/${lng_key}`,
  getCampType: camp_key => `${MEMBER_API_ACTIVE_ROUTE}/GetCampType/${camp_key}`,
  getCommitteeMembers: (lng_key, ind_key) => `${MEMBER_API_ACTIVE_ROUTE}/committee/members/${lng_key}/${ind_key}`,
  getCountryZone: (c_key, flag) => `${MEMBER_API_ACTIVE_ROUTE}/country/zone/${c_key}/${flag}`,
  getCountryZones: c_key => `${MEMBER_API_ACTIVE_ROUTE}/country/zones/${c_key}`,
  getEmailFormOptions: lng_key => `${MEMBER_API_ACTIVE_ROUTE}/GetEmailFormOptions_tc3/${lng_key}`,
  getHomePageWidgetData: (ind_key, camp_key, lng_key) => `${MEMBER_API_ACTIVE_ROUTE}/home/widgets/${ind_key}/${camp_key}/${lng_key}`,
  getToolbarDefaults: (ind_key, camp_key) => `${MEMBER_API_ACTIVE_ROUTE}/toolbardefaults/${ind_key}/${camp_key}`,
  getBannerWidgetData: ind_key => `${MEMBER_API_ACTIVE_ROUTE}/home/banner/${ind_key}`,
  getFriends: (camp_key, lng_key) => `${MEMBER_API_ACTIVE_ROUTE}/GetFriends/${camp_key}/${lng_key}`,
  getIndividualCommPreferences: (ind_key, lng_key) =>
    `${MEMBER_API_ACTIVE_ROUTE}/CommunicationPreferences_tc3/${ind_key}/${lng_key}`,
  getIndividualCommunications: (entity_key, lng_key) =>
    `${MEMBER_API_ACTIVE_ROUTE}/communication/${entity_key}/${lng_key}`,
  getIndividualEditFormOptions: (ind_key, lng_key) =>
    `${MEMBER_API_ACTIVE_ROUTE}/GetIndividualEditFormOptions_tc3/${ind_key}/${lng_key}`,
  getMemberCommitteeAndRoles: (entity_key, lng_key) =>
    `${MEMBER_API_ACTIVE_ROUTE}/committeeroles/${entity_key}/${lng_key}`,
  getMemberPaymentInfo: ind_key => `${MEMBER_API_ACTIVE_ROUTE}/paymentinfo/${ind_key}`,
  getMemberPopup: ind_key => `${MEMBER_API_ACTIVE_ROUTE}/home/memberpopup/${ind_key}`,
  getMemberAvatar: mbr_key => `${MEMBER_API_ACTIVE_ROUTE}/getAvatar/${mbr_key}`,
  getMembers: (camp_key, lng_key) => `${MEMBER_API_ACTIVE_ROUTE}/GetMembers/${camp_key}/${lng_key}`,
  getMembersCampDashboard: (camp_key, lng_key) => `${MEMBER_API_ACTIVE_ROUTE}/camp/dashboard/${camp_key}/${lng_key}`,
  getMembersStateDashboard: (state_key, lng_key) =>
    `${MEMBER_API_ACTIVE_ROUTE}/state/dashboard/${state_key}/${lng_key}`,
  getMembersCampDashboard_Home: (camp_key, lng_key) =>
    `${MEMBER_API_ACTIVE_ROUTE}/camp/dashboard_home/${camp_key}/${lng_key}`,
  getMembershipWidgetData: (camp_key, lng_key, fiscal_year, limit) =>
    `${MEMBER_API_ACTIVE_ROUTE}/GetMembershipWidgetData/${camp_key}/${lng_key}/${fiscal_year}/${limit}`,
  getNewMembers: (camp_key, lng_key) => `${MEMBER_API_ACTIVE_ROUTE}/GetNewMembers/${camp_key}/${lng_key}`,
  getOfficers: (camp_key, lng_key) => `${MEMBER_API_ACTIVE_ROUTE}/GetOfficers/${camp_key}/${lng_key}`,
  getPhoneFormOptions: (camp_key, lng_key) =>
    `${MEMBER_API_ACTIVE_ROUTE}/GetPhoneFormOptions_tc3/${camp_key}/${lng_key}`,
  getPreferredLanguages: () => `${MEMBER_API_ACTIVE_ROUTE}/preferredlanguages`,
  getProfile: ind_key => `${MEMBER_API_ACTIVE_ROUTE}_tc3/${ind_key}`,
  getProspectFormOptions: (camp_key, lng_key) =>
    `${MEMBER_API_ACTIVE_ROUTE}/GetProspectFormOptions_tc3/${camp_key}/${lng_key}`,
  getProspectNotes: (entity_key, lng_key) => `${MEMBER_API_ACTIVE_ROUTE}/prospect/notes/${entity_key}/${lng_key}`,
  getProspectNotesOptions: (lku_description_code, camp_key, lng_key) =>
    `${MEMBER_API_ACTIVE_ROUTE}/GetProspectNoteFormOptions_tc3/${lku_description_code}/${camp_key}/${lng_key}`,
  getProspects: (camp_key, lng_key) => `${MEMBER_API_ACTIVE_ROUTE}/GetProspects/${camp_key}/${lng_key}`,
  getSpouseFormOptions: lng_key => `${MEMBER_API_ACTIVE_ROUTE}/GetSpouseFormOptions_tc3/${lng_key}`,
  getStateRegionArea: state_key => `${MEMBER_API_ACTIVE_ROUTE}/state/region/area/${state_key}`,
  getStatesForCountry: cnt_key => `${MEMBER_API_ACTIVE_ROUTE}/states/${cnt_key}`,
  getUsStates: () => `${MEMBER_API_ACTIVE_ROUTE}/GetUsStates_tc3/`,
  getWidgetPrayerRequest: org_key => `${MEMBER_API_ACTIVE_ROUTE}/prayer/${org_key}`,
  removeMemberPopup: (pop_key, ind_key) => `${MEMBER_API_ACTIVE_ROUTE}/home/removememberpopup/${pop_key}/${ind_key}`,
  saveAddProspect: () => `${MEMBER_API_ACTIVE_ROUTE}/addprospect_tc3`,
  saveAddress: () => `${MEMBER_API_ACTIVE_ROUTE}/addressupsert_tc3`,
  saveAutopay: () => `${MEMBER_API_ACTIVE_ROUTE}/payment/autopay_tc3`,
  saveDues: () => `${MEMBER_API_ACTIVE_ROUTE}/payment/dues_tc3`,
  saveIODues: () => `${MEMBER_API_ACTIVE_ROUTE}/payment/io_dues_tc3`,
  saveEmail: () => `${MEMBER_API_ACTIVE_ROUTE}/emailupsert_tc3`,
  saveLanguage: () => `${MEMBER_API_ACTIVE_ROUTE}/languageupsert_tc3`,
  saveFax: () => `${MEMBER_API_ACTIVE_ROUTE}/faxupsert_tc3`,
  saveIndividualDetails: () => `${MEMBER_API_ACTIVE_ROUTE}/UpdateIndividualDetails_tc3`,
  saveLoginEmail: () => `${MEMBER_API_ACTIVE_ROUTE}/loginemailupsert_tc3`,
  savePhone: () => `${MEMBER_API_ACTIVE_ROUTE}/phoneupsert_tc3`,
  saveProspectNote: () => `${MEMBER_API_ACTIVE_ROUTE}/prospectnote/upsert_tc3`,
  saveNonMember: () => `${MEMBER_API_ACTIVE_ROUTE}/nonmember_tc3`,
  saveSpouse: () => `${MEMBER_API_ACTIVE_ROUTE}/spouse_tc3`,
  setMenu: ind_key => `${MEMBER_API_ACTIVE_ROUTE}/menu/${ind_key}`,
  turnOffAnnualMembershipAutoPay:(cst_key)=>  `${MEMBER_API_ACTIVE_ROUTE}/deleteautopay/${cst_key}` ,
  upsertPhoto: ind_key => `${MEMBER_API_ACTIVE_ROUTE}/photoupsert_tc3/${ind_key}`
}
