import TranslationService from '@/services/TranslationService'

/* eslint-disable */
export const state = {
  languageCode: '',
  menuTranslations: []
}

export const mutations = {
  SET_LANGUAGE_CODE(state, languageCode) {
    state.languageCode = languageCode
  },
  SET_MENU_TRANSLATIONS(state, translations) {
    state.menuTranslations = translations
  }
}

export const actions = {
  fetchMenuTranslations({ commit }, languageCode) {
    return TranslationService.getMenuTranslations(languageCode)
      .then(response => {
        commit('SET_LANGUAGE_CODE', languageCode)
        commit('SET_MENU_TRANSLATIONS', response.data)
      })
      .catch(error => {
        console.error(`There was an error fetching menu translations: ${error}`)
      })
  }
}

export const getters = {}

export const translation = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
