/* eslint-disable */
import axios from 'axios'
import { API_CONFIG } from '../config/api.config.js'

export const state = () => ({
  reports: [],
  reportcategories: [],
  reportsubcategories: []
})

export const getters = {
  reports: state => {
    return state.reports
  },
  reportcategories: state => {
    return state.reportcategories
  },
  reportsubcategories: state => {
    return state.reportsubcategories
  }
}

export const SET_REPORT = 'SET_REPORT'
export const SET_REPORTCATEGORIES = 'SET_REPORTCATEGORIES'
export const SET_REPORTSUBCATEGORIES = 'SET_REPORTSUBCATEGORIES'

export const mutations = {
  [SET_REPORT](state, data) {
    state.reports = data
  },
  [SET_REPORTCATEGORIES](state, data) {
    state.reportcategories = data
  },
  [SET_REPORTSUBCATEGORIES](state, data) {
    state.reportsubcategories = data
  }
}

export const actions = {
  async loadReports({ commit, rootGetters }) {
    try {
      let response = await apiCalls.loadReportData(rootGetters['user/userLanguageKey'])
      if (response && response.status === 200) {
        await commit('SET_REPORT', response.data.reports)
        await commit('SET_REPORTCATEGORIES', response.data.reportcategories)
        await commit('SET_REPORTSUBCATEGORIES', response.data.reportsubcategories)
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getReportStream({ rootGetters }, payload) {
    try {
      let response = await apiCalls.getReportStream(payload)
      if (response && response.status === 200) {
        return response
      }
      throw 'There was an issue.'
    } catch (error) {
      console.error(error)
      return false
    }
  }
}

const apiCalls = {
  loadReportData(languageKey) {
    const route = API_CONFIG.getReports(languageKey)
    return axios.get(route)
  },
  getReportStream(reportData) {
    const route = API_CONFIG.getReportStream()
    return axios.post(route, reportData, { responseType: 'blob' })
  }
}

export const report = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
