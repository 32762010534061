import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueI18n from 'vue-i18n'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import JsonExcel from 'vue-json-excel'
import VueCompositionApi from '@vue/composition-api'
import Loading from 'vue-loading-overlay'
import './styles/global.scss'
import 'vue-loading-overlay/dist/vue-loading.css'
import { messages } from 'vue-bootstrap4-calendar' // you can include your own translation here if you want!
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import VModal from 'vue-js-modal'
import AddToCalendar from 'vue-add-to-calendar'
import VueTimepicker from 'vue2-timepicker'
import FullCalendar from 'vue-full-calendar'
import interceptSetup from './interceptapitraffic'
import VueFormulate from '@braid/vue-formulate'
import VueBreadcrumbs from 'vue-2-breadcrumbs'
import browserDetect from 'vue-browser-detect-plugin'
import Vuelidate from 'vuelidate'
import VueGtag from 'vue-gtag'
import Textra from 'vue-textra'
import VueFormulateDatepickerPlugin from 'vue-formulate-datepicker'
import VStickyElement from 'vue-sticky-element'
Vue.use(browserDetect)
Vue.use(FullCalendar)

/* eslint-disable */
Object.defineProperty(Vue.prototype, '$axios', {
  value: axios
})
Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV !== 'production'
Vue.config.debug = process.env.NODE_ENV !== 'production'
Vue.config.silent = process.env.NODE_ENV === 'production'

Vue.use(VueI18n)
Vue.use(VueCompositionApi)
Vue.use(AddToCalendar)
Vue.use(VueTimepicker)
Vue.use(VueBreadcrumbs)
Vue.use(Vuelidate)
Vue.use(Textra)
Vue.use(VStickyElement)
Vue.use(
  Loading,
  {
    // props
    color: '#000000',
    loader: 'spinner',
    width: 64,
    height: 64,
    backgroundColor: '#ffffff',
    opacity: 0.5,
    zIndex: 999
  },
  {
    // slots
  }
)

window.i18n = new VueI18n({
  locale: 'en',
  messages
})

Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true,
  dialog: true,
  dynamicDefaults: {
    mymodal: 'mymodal'
  }
})

Vue.use(VueSweetalert2)
Vue.use(VueFormulate, {
  plugins: [VueFormulateDatepickerPlugin]
})
Vue.component('downloadExcel', JsonExcel)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(
  VueGtag,
  {
    config: {
      id: 'G-E9D79R9CH9'
    }
  },
  router
)
Vue.filter('toCurrency', function (value) {
  var parsedValue = parseFloat(value).toFixed(2) === 'NaN' ? 0 : value
  var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
  });
  return formatter.format(parsedValue);
});
interceptSetup()

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
