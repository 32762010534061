/* eslint-disable */
import axios from 'axios'
import { CONVENTION_API_CONFIG } from '../config/convention.api.config.js'

export const state = () => ({
  conventions: [],
  convention_info: {},
  convention_form: {
    evn_key: null,
    evn_name: '',
    evn_start_date: null,
    evn_end_date: null,
    evn_additional_info: '',
    evn_registration_link: '',
    evn_state_assoc_key: null,
    evn_state_assoc_name: '',
    evn_delete_flag: false,
    evn_active_flag: false,
    evt_key: '6AD38DDC-44BD-4953-820B-C96604190A4B',
    evt_description: 'Convention',
    evl_key: null,
    evl_name: '',
    evl_adr_line1: '',
    evl_adr_line2: null,
    evl_city: '',
    evl_state: null,
    evl_postal_code: '',
    evc_key: null,
    evc_name: '',
    evc_adr_line1: '',
    evc_adr_line2: null,
    evc_city: '',
    evc_state: null,
    evc_postal_code: '',
    evc_email: '',
    evc_phone_number: '',
    evc_other_info: '',
    evh_key: null,
    evh_name: '',
    evh_adr_line1: '',
    evh_adr_line2: null,
    evh_city: '',
    evh_state: null,
    evh_postal_code: '',
    evh_email: '',
    evh_phone_number: '',
    evh_website: '',
    photos: [],
    documents: []
  }
})

export const getters = {
  conventions: state => {
    return state.conventions
  },
  conventions_active: state => {
    return state.conventions.filter(c => c.event_active_flag)
  },
  convention_info: state => {
    return state.convention_info
  },
  empty_convention: state => {
    return state.convention_form
  }
}

export const SET_CONVENTIONS = 'SET_CONVENTIONS'
export const SET_CONVENTION_INFO = 'SET_CONVENTION_INFO'

export const mutations = {
  [SET_CONVENTIONS](state, data) {
    state.conventions = data
  },
  [SET_CONVENTION_INFO](state, data) {
    state.convention_info = data
  }
}

export const actions = {
  async loadConventions({ commit, rootGetters }) {
    try {
      let response = await apiCalls.loadConventions(rootGetters['user/userCampKey'])
      if (response && response.status === 200) {
        await commit('SET_CONVENTIONS', response.data)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadConventionInfo({ commit, rootGetters }, event_key) {
    try {
      let response = await apiCalls.loadConventionInfo(event_key)
      if (response && response.status === 200) {
        await commit('SET_CONVENTION_INFO', response.data)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async addConvention({ rootGetters }, convention) {
    try {
      let response = await apiCalls.addConvention(
        rootGetters['user/userId'],
        rootGetters['user/userCampKey'],
        convention
      )
      if (response && response.status === 200) {
        return response.data
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async toggleActiveConvention({ rootGetters }, convention) {
    try {
      let response = await apiCalls.toggleActiveConvention(rootGetters['user/userCampKey'], convention)
      if (response && response.status === 200) {
        return response.data
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async deleteConvention({}, convention) {
    try {
      let response = await apiCalls.deleteConvention(convention)
      if (response && response.status === 200) {
        return response.data
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async editConventionPhoto({ rootGetters }, photo) {
    try {
      let response = await apiCalls.editConventionPhoto(rootGetters['user/userId'], photo)
      if (response && response.status === 200) {
        return response.data
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async editConventionDocument({ rootGetters }, document) {
    try {
      let response = await apiCalls.editConventionDocument(rootGetters['user/userId'], document)
      if (response && response.status === 200) {
        return response.data
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async editConventionLink({ rootGetters }, link) {
    try {
      let response = await apiCalls.editConventionLink(rootGetters['user/userId'], link)
      if (response && response.status === 200) {
        return response.data
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
}

const apiCalls = {
  loadConventions(campKey) {
    const route = CONVENTION_API_CONFIG.getConventionsByCamp(campKey)
    return axios.get(route)
  },
  loadConventionInfo(eventKey) {
    const route = CONVENTION_API_CONFIG.getConventionInfo(eventKey)
    return axios.get(route)
  },
  addConvention(ind_key, cmp_key, convention) {
    const ignoreFields = ({ documents, photos, ...rest }) => rest
    const newCon = { ...ignoreFields(convention) }
    let formData = new FormData()
    for (var itm in newCon) {
      if (newCon[itm] !== null) {
        formData.append(itm, newCon[itm])
      }
    }
    for (var itm in convention.photos) {
      if (convention.photos[itm] !== null) {
        formData.append(itm, convention.photos[itm])
      }
    }
    for (var itm in convention.documents) {
      if (convention.documents[itm] !== null) {
        formData.append(itm, convention.documents[itm])
      }
    }

    const route = CONVENTION_API_CONFIG.addConvention(ind_key, cmp_key)
    return axios.post(route, formData)
  },
  toggleActiveConvention(cmp_key, convention) {
    const route = CONVENTION_API_CONFIG.toggleActiveConvention(cmp_key)
    return axios.post(route, convention)
  },
  deleteConvention(convention) {
    const route = CONVENTION_API_CONFIG.deleteConvention()
    return axios.post(route, convention)
  },
  editConventionPhoto(ind_key, photo) {
    const route = CONVENTION_API_CONFIG.editConventionPhoto(ind_key)
    return axios.post(route, photo)
  },
  editConventionDocument(ind_key, document) {
    const route = CONVENTION_API_CONFIG.editConventionDocument(ind_key)
    return axios.post(route, document)
  },
  editConventionLink(ind_key, link) {
    const route = CONVENTION_API_CONFIG.editConventionLink(ind_key)
    return axios.post(route, link)
  },
}

//TODO: UPDATE THESE WHEN WE HAVE THEM
const reportCalls = {}

export const stateConvention = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
