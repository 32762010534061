import Vue from 'vue'
import axios from 'axios'

export const alertSuccess = message => {
  Vue.swal({
    title: message,
    toast: true,
    position: 'top',
    showConfirmButton: false,
    padding: '12px',
    timer: 5000,
    grow: 'row',
    icon: 'success',
    timerProgressBar: true,
    width: '400px'
  })
}

export const alertError = message => {
  Vue.swal({
    title: message,
    toast: true,
    position: 'top',
    showConfirmButton: false,
    padding: '12px',
    timer: 5000,
    grow: 'row',
    icon: 'error',
    timerProgressBar: true,
    width: '400px'
  })
}

export const ErrorMessages = {
  NoEndPointError: 'No API endpoint is provided',
  FallbackMessage: 'Oops !! Something went wrong. Please try again later.',
  NetworkError: 'Network Error. Please check your internet connection',
  ServerError: 'The server is facing some issues right now.',
  RedirectionError: 'The server got redirected to another destination.',
  ForbiddenError: "You don't have the  permission to access this resource",
  ResourceNotFound: 'The resource you are looking is not found.'
}

export default async function axiosErrorHandler ({
  endpoint,
  payload,
  apiType,
  successMessage = '',
  errorMessage = ''
}) {
  if (endpoint.trim() === '') {
    alertError(ErrorMessages.NoEndPointError)
    throw new Error(ErrorMessages.NoEndPointError)
  }

  try {
    let response = {}
    if (apiType === 'get') {
      response = await axios.get(endpoint)
    } else if (apiType === 'post') {
      response = await axios.post(endpoint, payload)
    } else if (apiType === 'put') {
      response = await axios.put(endpoint, payload)
    } else if (apiType === 'delete') {
      response = await axios.delete(endpoint)
    } else if (apiType === 'patch') {
      response = await axios.patch(endpoint, payload)
    }

    if (response.status >= 200 && response.status < 300) {
      successMessage !== '' && alertSuccess(successMessage)
      return response.data || 'Success'
    }
  } catch (error) {
    if (error) {
      if (error.response) {
        let resErrMsg = error.response.data.hasOwnProperty('Message') ? error.response.data.Message : error.message
        if (error.response.status === 403) {
          alertError(ErrorMessages.ForbiddenError)
        } else if (error.response.status === 404) {
          alertError(ErrorMessages.ResourceNotFound)
        } else if (error.response.status === 500) {
          alertError(ErrorMessages.ServerError)
        } else {
          alertError((errorMessage !== '' && errorMessage) || resErrMsg)
        }
      } else if (error.request) {
        alertError(ErrorMessages.NetworkError)
      }
    }
  }
}
