/* eslint-disable */
import axios from 'axios'
import { API_CONFIG } from '../config/api.config.js'

export const state = () => ({
  reportParameters: [],
  apiEndpoint: '',
  reportName: '',
  reportParentKey: '',
  selectedReportKey: '',
  cascadeRpdKey: '',
  cascadeRpdName: '',
  stateCamps: [],
  reportFileType: '',
  sitecoreName: ''
})

export const getters = {
  reportParametersList: state => {
    return state.reportParameters
  },
  apiEndpoint: state => {
    return state.apiEndpoint
  },
  reportName: state => {
    return state.reportName
  },
  sitecoreName: state => {
    return state.sitecoreName
  },
  reportParentKey: state => {
    return state.reportParentKey
  },
  selectedReportKey: state => {
    return state.selectedReportKey
  },
  cascadeRpdKey: state => {
    return state.cascadeRpdKey
  },
  cascadeRpdName: state => {
    return state.cascadeRpdName
  },
  stateCamps: state => {
    return state.stateCamps
  },
  reportFileType: state => {
    return state.reportFileType
  }
}

export const SET_REPORTPARAMETERS = 'SET_REPORTPARAMETERS'
export const SET_APIENDPOINT = 'SET_APIENDPOINT'
export const SET_REPORTNAME = 'SET_REPORTNAME'
export const SET_SITECORE_NAME = 'SET_SITECORE_NAME'
export const SET_REPORT_PARENTKEY = 'SET_REPORT_PARENTKEY'
export const SET_SELECTED_REPORT_KEY = 'SET_SELECTED_REPORT_KEY'
export const SET_CASCADE_RPD_KEY = 'SET_CASCADE_RPD_KEY'
export const SET_CASCADE_RPD_NAME = 'SET_CASCADE_RPD_NAME'
export const SET_STATE_CAMPS = 'SET_STATE_CAMPS'
export const SET_REPORT_FILE_TYPE = 'SET_REPORT_FILE_TYPE'

export const mutations = {
  [SET_REPORTPARAMETERS](state, data) {
    state.reportParameters = data
  },
  [SET_APIENDPOINT](state, data) {
    state.apiEndpoint = data
  },
  [SET_REPORTNAME](state, data) {
    state.reportName = data
  },
  [SET_SITECORE_NAME](state, data) {
    state.sitecoreName = data
  },
  [SET_REPORT_PARENTKEY](state, data) {
    state.reportParentKey = data
  },
  [SET_SELECTED_REPORT_KEY](state, data) {
    state.selectedReportKey = data
  },
  [SET_CASCADE_RPD_KEY](state, data) {
    state.cascadeRpdKey = data
  },
  [SET_CASCADE_RPD_NAME](state, data) {
    state.cascadeRpdName = data
  },
  [SET_STATE_CAMPS](state, data) {
    state.stateCamps = data
  },
  [SET_REPORT_FILE_TYPE](state, data) {
    state.reportFileType = data
  }
}

export const actions = {
  async loadReportParameters({ commit, rootGetters }, params) {
    try {
      let response = await apiCalls.loadReportParametersData(
        params.reportKey,
        params.reportOrgKey,
        rootGetters['user/userId'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_REPORTPARAMETERS', response.data.parameters)
        await commit('SET_APIENDPOINT', response.data.parameters.find(p => p.name == 'api_endpoint').value)
        await commit('SET_REPORTNAME', response.data.reportName)
        await commit('SET_REPORT_PARENTKEY', response.data.reportParentKey)
        await commit('SET_CASCADE_RPD_KEY', response.data.cascadeRpdKey)
        await commit('SET_CASCADE_RPD_NAME', response.data.cascadeRpdName)
        await commit('SET_SITECORE_NAME', response.data.sitecoreKey)
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async setReportParamParentKey({ commit }, data) {
    await commit('SET_REPORT_PARENTKEY', data)
  },
  async setSelectedReportKey({ commit }, data) {
    await commit('SET_SELECTED_REPORT_KEY', data)
  },
  async setReportFileType({ commit }, data) {
    await commit('SET_REPORT_FILE_TYPE', data)
  },
  async loadCampsByStateArea({ commit, rootGetters }, params) {
    try {
      let response = await apiCalls.getCampsByStateArea(
        params.rpd_key,
        params.site_key,
        rootGetters['user/userId'],
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('SET_STATE_CAMPS', response.data)
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }
}

const apiCalls = {
  loadReportParametersData(reportKey, orgKey, individualKey, languageKey) {
    const route = API_CONFIG.getReportParameters(reportKey, orgKey, individualKey, languageKey)
    return axios.get(route)
  },
  getCampsByStateArea(rpd_key, site_key, ind_key, lng_key) {
    const route = API_CONFIG.getCampsByStateArea(rpd_key, site_key, ind_key, lng_key)
    return axios.get(route)
  }
}

export const reportParameter = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
