/* eslint-disable */
import axios from 'axios'
import { API_CONFIG } from '../config/api.config.js'

export const AREAREPORTURLS = 'AREAREPORTURLS'
export const AREAREPORTYEARS = 'AREAREPORTYEARS'
export const AREAS = 'AREAS'
export const ASSOCIATIONREPORTYEARS = 'ASSOCIATIONREPORTYEARS'
export const ASSOCIATIONS = 'ASSOCIATIONS'
export const CAMPS = 'CAMPS'
export const CAMPURLS = 'CAMPURLS'
export const COUNTRIES = 'COUNTRIES'
export const MONTHLYCAMPS = 'MONTHLYCAMPS'
export const MONTHLYREPORTURLS = 'MONTHLYREPORTURLS'
export const MONTHLYREPORTYEARS = 'MONTHLYREPORTYEARS'
export const MONTHLYSTATES = 'MONTHLYSTATES'
export const NATIONALURLS = 'NATIONALURLS'
export const REGIONREPORTURLS = 'REGIONREPORTURLS'
export const REGIONREPORTYEARS = 'REGIONREPORTYEARS'
export const REGIONS = 'REGIONS'
export const REPORTURLS = 'REPORTURLS'
export const REPORTYEARS = 'REPORTYEARS'
export const SET_ASSOCIATIONREPORTURLS = 'SET_ASSOCIATIONREPORTURLS'
export const STATEREPORTURLS = 'STATEREPORTURLS'
export const STATEREPORTYEARS = 'STATEREPORTYEARS'
export const STATES = 'STATES'
export const WORLDREPORTURLS = 'WORLDREPORTURLS'
export const WORLDREPORTYEARS = 'WORLDREPORTYEARS'
export const ZONES = 'ZONES'

export const state = () => ({
  areaReportUrls: {},
  areaReportYears: {},
  areas: {},
  associationReportUrls: {},
  associationReportYears: {},
  associations: {},
  camps: {},
  campUrls: {},
  countries: {},
  monthlyCamps: {},
  monthlyStates: {},
  monthlyReportUrls: {},
  monthlyReportYears: {},
  nationalUrls: {},
  regionReportUrls: {},
  regionReportYears: {},
  regions: {},
  reportReportUrls: {},
  reportReportYears: {},
  stateReportUrls: {},
  stateReportYears: {},
  states: {},
  worldReportUrls: {},
  worldReportYears: {},
  zones: {}
})

export const getters = {
  zones: state => {
    return state.zones
  },
  states: state => {
    return state.states
  },
  stateReportYears: state => {
    return state.stateReportYears
  },
  stateReportUrls: state => {
    return state.stateReportUrls
  },
  areas: state => {
    return state.areas
  },
  areaReportYears: state => {
    return state.areaReportYears
  },
  areaReportUrls: state => {
    return state.areaReportUrls
  },
  reportReportYears: state => {
    return state.reportReportYears
  },
  reportReportUrls: state => {
    return state.reportReportUrls
  },
  associations: state => {
    return state.associations
  },
  associationReportYears: state => {
    return state.associationReportYears
  },
  associationReportUrls: state => {
    return state.associationReportUrls
  },
  camps: state => {
    return state.camps
  },
  campUrls: state => {
    return state.campUrls
  },
  countries: state => {
    return state.countries
  },
  regions: state => {
    return state.regions
  },
  nationalUrls: state => {
    return state.nationalUrls
  },
  regionReportYears: state => {
    return state.regionReportYears
  },
  regionReportUrls: state => {
    return state.regionReportUrls
  },
  worldReportYears: state => {
    return state.worldReportYears
  },
  worldReportUrls: state => {
    return state.worldReportUrls
  },
  monthlyStates: state => {
    return state.monthlyStates
  },
  monthlyCamps: state => {
    return state.monthlyCamps
  },
  monthlyReportYears: state => {
    return state.monthlyReportYears
  },
  monthlyReportUrls: state => {
    return state.monthlyReportUrls
  }
}

export const mutations = {
  [ZONES](state, data) {
    state.zones = data
  },
  [STATES](state, data) {
    state.states = data
  },
  [STATEREPORTYEARS](state, data) {
    state.stateReportYears = data
  },
  [STATEREPORTURLS](state, data) {
    state.stateReportUrls = data
  },
  [AREAS](state, data) {
    state.areas = data
  },
  [AREAREPORTYEARS](state, data) {
    state.areaReportYears = data
  },
  [AREAREPORTURLS](state, data) {
    state.areaReportUrls = data
  },
  [CAMPS](state, data) {
    state.camps = data
  },
  [CAMPURLS](state, data) {
    state.campUrls = data
  },
  [COUNTRIES](state, data) {
    state.countries = data
  },
  [NATIONALURLS](state, data) {
    state.nationalUrls = data
  },
  [REPORTYEARS](state, data) {
    state.reportReportYears = data
  },
  [REPORTURLS](state, data) {
    state.reportReportUrls = data
  },
  [ASSOCIATIONS](state, data) {
    state.associations = data
  },
  [ASSOCIATIONREPORTYEARS](state, data) {
    state.associationReportYears = data
  },
  [SET_ASSOCIATIONREPORTURLS](state, data) {
    state.associationReportUrls = data
  },
  [REGIONS](state, data) {
    state.regions = data
  },
  [REGIONREPORTYEARS](state, data) {
    state.regionReportYears = data
  },
  [REGIONREPORTURLS](state, data) {
    state.regionReportUrls = data
  },
  [WORLDREPORTYEARS](state, data) {
    state.worldReportYears = data
  },
  [WORLDREPORTURLS](state, data) {
    state.worldReportUrls = data
  },
  [MONTHLYSTATES](state, data) {
    state.monthlyStates = data
  },
  [MONTHLYCAMPS](state, data) {
    state.monthlyCamps = data
  },
  [MONTHLYREPORTYEARS](state, data) {
    state.monthlyReportYears = data
  },
  [MONTHLYREPORTURLS](state, data) {
    state.monthlyReportUrls = data
  }
}

export const actions = {
  async getZones({ commit, rootGetters }) {
    try {
      let response = await apiCalls.getZones(
        '16E6A800-060A-435B-9B10-7F9DFE6B71FA',
        '651650ce-1eaa-43e0-adf8-68a1a040144c',
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('ZONES', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getStates({ commit, rootGetters }, org_key) {
    try {
      let response = await apiCalls.getStates(
        org_key,
        'ee237487-8e8c-4644-a5f7-5212de9efc8f',
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('STATES', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getStateReportYears({ commit }, data) {
    try {
      let response = await apiCalls.getStateReportYears(data.zone, data.state, data.stateReport)
      if (response && response.status === 200) {
        await commit('STATEREPORTYEARS', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getStateReportUrls({ commit }, data) {
    try {
      let response = await apiCalls.getStateReportUrls(data.zone, data.state, data.stateReport, data.stateYear)
      if (response && response.status === 200) {
        await commit('STATEREPORTURLS', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getAreas({ commit, rootGetters }, org_key) {
    try {
      let response = await apiCalls.getAreas(
        org_key,
        'b37d92e2-523c-4f59-b873-6667e82b7aad',
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('AREAS', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getAreaReportYears({ commit }, data) {
    try {
      let response = await apiCalls.getAreaReportYears(data.zone, data.state, data.area, data.areaReport)
      if (response && response.status === 200) {
        await commit('AREAREPORTYEARS', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getAreaReportUrls({ commit }, data) {
    try {
      let response = await apiCalls.getAreaReportUrls(data.zone, data.state, data.area, data.areaReport, data.areaYear)
      if (response && response.status === 200) {
        await commit('AREAREPORTURLS', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getReportYears({ commit }, data) {
    try {
      let response = await apiCalls.getReportYears(data.zone, data.report)
      if (response && response.status === 200) {
        await commit('REPORTYEARS', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getReportUrls({ commit }, data) {
    try {
      let response = await apiCalls.getReportUrls(data.zone, data.report, data.reportYear)
      if (response && response.status === 200) {
        await commit('REPORTURLS', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getAssociations({ commit, rootGetters }) {
    try {
      let response = await apiCalls.getAssociations(
        'F12E2D56-E03F-4853-95A8-29B7474E8A56',
        '81d53026-400a-44fc-a6dd-1477fdd735e5',
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('ASSOCIATIONS', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getAssociationReportYears({ commit }, associationCode) {
    try {
      let response = await apiCalls.getAssociationReportYears(associationCode)
      if (response && response.status === 200) {
        await commit('ASSOCIATIONREPORTYEARS', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async clearAssociationReportUrls({ commit }) {
    await commit('SET_ASSOCIATIONREPORTURLS', [])
  },
  async getAssociationReportUrls({ commit }, data) {
    try {
      let response = await apiCalls.getAssociationReportUrls(data.associationCode, data.year)
      if (response && response.status === 200) {
        await commit('SET_ASSOCIATIONREPORTURLS', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getCamps({ commit, rootGetters }, data) {
    try {
      let response = await apiCalls.getCamps(
        data.ind_key,
        'C07E597D-BAC5-4D2E-B623-5F9F9ACC1DCD',
        rootGetters['user/userLanguageKey'],
        data.org_key
      )
      if (response && response.status === 200) {
        await commit('CAMPS', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getCountries({ commit, rootGetters }, data) {
    try {
      let response = await apiCalls.getCountries(
        data.ind_key,
        '37bc8b8b-f050-48c2-81e4-710341b72b34',
        rootGetters['user/userLanguageKey'],
        data.org_key
      )
      if (response && response.status === 200) {
        await commit('COUNTRIES', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getRegions({ commit, rootGetters }) {
    try {
      let response = await apiCalls.getRegions(
        'EADE4881-2B31-43CC-8526-44AAB9C71602',
        '2168a5ac-4bf5-483d-9b09-b29d010d1520',
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('REGIONS', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getNationalUrls({ commit, rootGetters }, data) {
    try {
      let response = await apiCalls.getNationalUrls(
        data.ind_key,
        data.org_key,
        false,
        'rally',
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('NATIONALURLS', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getCampUrls({ commit, rootGetters }, data) {
    try {
      let response = await apiCalls.getCampUrls(
        data.ind_key,
        data.org_key,
        false,
        '909',
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('CAMPURLS', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getRegionYears({ commit }, data) {
    try {
      let response = await apiCalls.getRegionYears(data.region, data.report)
      if (response && response.status === 200) {
        await commit('REGIONREPORTYEARS', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getRegionUrls({ commit }, data) {
    try {
      let response = await apiCalls.getRegionUrls(data.region, data.report, data.reportYear)
      if (response && response.status === 200) {
        await commit('REGIONREPORTURLS', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getWorldYears({ commit }, data) {
    try {
      let response = await apiCalls.getWorldYears(data.worldReport)
      if (response && response.status === 200) {
        await commit('WORLDREPORTYEARS', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getWorldUrls({ commit }, data) {
    try {
      let response = await apiCalls.getWorldUrls(data.worldReport, data.worldReportYear)
      if (response && response.status === 200) {
        await commit('WORLDREPORTURLS', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getMonthlyStates({ commit, rootGetters }) {
    try {
      let response = await apiCalls.getMonthlyStates(
        '16E6A800-060A-435B-9B10-7F9DFE6B71FA',
        'ee237487-8e8c-4644-a5f7-5212de9efc8f',
        rootGetters['user/userLanguageKey']
      )
      if (response && response.status === 200) {
        await commit('MONTHLYSTATES', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getMonthlyCamps({ commit }, org_key) {
    try {
      let response = await apiCalls.getMonthlyCamps(org_key)
      if (response && response.status === 200) {
        await commit('MONTHLYCAMPS', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getMonthlyYears({ commit }, data) {
    try {
      let response = await apiCalls.getMonthlyYears(data.monthlyState, data.monthlyCamp)
      if (response && response.status === 200) {
        await commit('MONTHLYREPORTYEARS', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async getMonthlyUrls({ commit }, data) {
    try {
      let response = await apiCalls.getMonthlyUrls(data.monthlyState, data.monthlyCamp, data.monthlyYear)
      if (response && response.status === 200) {
        await commit('MONTHLYREPORTURLS', response.data)
      } else {
        throw 'unexpected response status: ' + response.status
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }
}

const apiCalls = {
  getZones(org_key, org_ogt_key, lng_key) {
    const route = API_CONFIG.getZones(org_key, org_ogt_key, lng_key)
    return axios.get(route)
  },
  getStates(org_key, org_ogt_key, lng_key) {
    const route = API_CONFIG.getStates(org_key, org_ogt_key, lng_key)
    return axios.get(route)
  },
  getStateReportYears(zone, state, stateReport) {
    const route = API_CONFIG.getStateReportYears(zone, state, stateReport)
    return axios.get(route)
  },
  getStateReportUrls(zone, state, stateReport, stateYear) {
    const route = API_CONFIG.getStateReportUrls(zone, state, stateReport, stateYear)
    return axios.get(route)
  },
  getAreas(org_key, org_ogt_key, lng_key) {
    const route = API_CONFIG.getAreas(org_key, org_ogt_key, lng_key)
    return axios.get(route)
  },
  getAreaReportYears(zone, state, area, areaReport) {
    const route = API_CONFIG.getAreaReportYears(zone, state, area, areaReport)
    return axios.get(route)
  },
  getAreaReportUrls(zone, state, area, areaReport, areaYear) {
    const route = API_CONFIG.getAreaReportUrls(zone, state, area, areaReport, areaYear)
    return axios.get(route)
  },
  getReportYears(zone, report) {
    const route = API_CONFIG.getReportYears(zone, report)
    return axios.get(route)
  },
  getReportUrls(zone, report, reportYear) {
    const route = API_CONFIG.getReportUrls(zone, report, reportYear)
    return axios.get(route)
  },
  getAssociations(org_key, org_ogt_key, lng_key) {
    const route = API_CONFIG.getAssociations(org_key, org_ogt_key, lng_key)
    return axios.get(route)
  },
  getAssociationReportYears(zone) {
    const route = API_CONFIG.getAssociationReportYears(zone)
    return axios.get(route)
  },
  getAssociationReportUrls(zone, reportYear) {
    const route = API_CONFIG.getAssociationReportUrls(zone, reportYear)
    return axios.get(route)
  },
  getCamps(ind_key, ogt_key, lng_key, org_key) {
    const route = API_CONFIG.getCamps(ind_key, ogt_key, lng_key, org_key)
    return axios.get(route)
  },
  getCampUrls(ind_key, org_key, is_posted_date_view_flag, report_type, lng_key) {
    const route = API_CONFIG.getCampUrls(ind_key, org_key, is_posted_date_view_flag, report_type, lng_key)
    return axios.get(route)
  },
  getCountries(ind_key, ogt_key, lng_key, org_key) {
    const route = API_CONFIG.getCountries(ind_key, ogt_key, lng_key, org_key)
    return axios.get(route)
  },
  getNationalUrls(ind_key, org_key, is_posted_date_view_flag, report_type, lng_key) {
    const route = API_CONFIG.getNationalUrls(ind_key, org_key, is_posted_date_view_flag, report_type, lng_key)
    return axios.get(route)
  },
  getRegions(org_key, org_ogt_key, lng_key) {
    const route = API_CONFIG.getRegions(org_key, org_ogt_key, lng_key)
    return axios.get(route)
  },
  getRegionYears(region, report) {
    const route = API_CONFIG.getRegionYears(region, report)
    return axios.get(route)
  },
  getRegionUrls(region, report, reportYear) {
    const route = API_CONFIG.getRegionUrls(region, report, reportYear)
    return axios.get(route)
  },
  getWorldYears(report) {
    const route = API_CONFIG.getWorldYears(report)
    return axios.get(route)
  },
  getWorldUrls(report, reportYear) {
    const route = API_CONFIG.getWorldUrls(report, reportYear)
    return axios.get(route)
  },
  getMonthlyStates(org_key, org_ogt_key, lng_key) {
    const route = API_CONFIG.getMonthlyStates(org_key, org_ogt_key, lng_key)
    return axios.get(route)
  },
  getMonthlyCamps(org_key) {
    const route = API_CONFIG.getMonthlyCamps(org_key)
    return axios.get(route)
  },
  getMonthlyYears(monthlyState, monthlyCamp) {
    const route = API_CONFIG.getMonthlyYears(monthlyState, monthlyCamp)
    return axios.get(route)
  },
  getMonthlyUrls(monthlyState, monthlyCamp, monthlyYear) {
    const route = API_CONFIG.getMonthlyUrls(monthlyState, monthlyCamp, monthlyYear)
    return axios.get(route)
  }
}

export const secureReport = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
