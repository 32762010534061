import { MEMBER_API_CONFIG } from '@/config/member.api.config'
import axios from 'axios'

/* eslint-disable */
export const state = () => ({
  menu: [],
  hrefs: [],
  loadingStatus: null,
  gifts_open: false,
  store_open: false,
  treasury_open: false
})

// some users have state home & country home menu items
// due to their security; this removes the inappropriate one
const removeFromChildItemList = (til, node, target) => {
  let ofc = til.findIndex(ti => ti.mnu_key === node)
  let target_index = -1
  if (!!ofc && ofc > -1) {
    til[ofc].ChildItemList.map((cl, clx) => {
      if (cl.mnu_key === target) {
        target_index = clx
      } else {
        cl.MenuItemList.map(mil => {
          if (mil.mnu_key === target) {
            target_index = clx
          }
        })
      }
    })
  }

  if (target_index > -1) {
    til[ofc].ChildItemList.splice(target_index, 1)
  }
}

const removeFromMenuItemList = (til, node, target) => {
  let target_index = { a: -1, b: -1 }
  let ofc = til.findIndex(ti => ti.mnu_key === node)
  if (!!ofc && ofc > -1) {
    til[ofc].ChildItemList.map((ch, chx) => {
      ch.MenuItemList.map((cl, clx) => {
        if (cl.mnu_key === target) {
          target_index.a = chx
          target_index.b = clx
        }
      })
    })
  }
  if (target_index.a > -1 && target_index.b > -1) {
    til[ofc].ChildItemList[target_index.a].MenuItemList.splice(target_index.b, 1)
  }
}

export const getters = {
  applicationMenu: state => {
    return state.menu
  },
  loadingStatus: state => {
    return state.loadingStatus
  },
  isInMenu: state => path => {
    return state.hrefs.filter(sh => sh.toLowerCase() === path.toLowerCase()).length > 0
  },
  isGiftsOpen: state => {
    return state.gifts_open
  },
  isStoreOpen: state => {
    return state.store_open
  },
  isTreasuryOpen: state => {
    return state.treasury_open
  }
}

export const SET_MENU = 'SET_MENU'
export const SET_MENU_HREFS = 'SET_MENU_HREFS'
export const SET_GIFTS_STATUS = 'SET_GIFTS_STATUS'
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS'
export const SET_STORE_STATUS = 'SET_STORE_STATUS'
export const SET_TREASURY_STATUS = 'SET_TREASURY_STATUS'

export const mutations = {
  [SET_MENU](state, data) {
    data.forEach(am => {
      am.ChildItemList.forEach(cl => {
        cl.MenuItemList.forEach(ml => {
          if (!ml.href.includes('memoryverse') && !['Make a Gift', 'Headquarters Directory', 'Ministry Training Library', 'Conversations'].includes(ml.name)) {
            ml.href += '/menu'
          }
        })
      })
    })
    state.menu = data
  },
  [SET_MENU_HREFS](state) {
    state.hrefs = []
    state.menu.map(til => {
      return til.ChildItemList.map(cil => {
        return cil.MenuItemList.map(mil => {
          state.hrefs.push(mil.href)
          if (mil.href.substring(0, 1) === '/') {
            state.hrefs.push(mil.href.replace('/menu', '/'))
            state.hrefs.push(mil.href.replace('/menu', ''))
          }
        })
      })
    })
  },
  [SET_LOADING_STATUS](state, data) {
    state.loadingStatus = data
  },
  [SET_GIFTS_STATUS](state, data) {
    state.gifts_open = data
  },
  [SET_STORE_STATUS](state, data) {
    state.store_open = data
  },
  [SET_TREASURY_STATUS](state, data) {
    state.treasury_open = data
  }
}

export const actions = {
  async setLoadingStatus({ commit }, ls) {
    await commit('SET_LOADING_STATUS', ls)
  },
  async setGiftsStatus({ commit }, gs) {
    await commit('SET_GIFTS_STATUS', gs)
  },
  async setStoreStatus({ commit }, ss) {
    await commit('SET_STORE_STATUS', ss)
  },
  async setTreasuryStatus({ commit }, ts) {
    await commit('SET_TREASURY_STATUS', ts)
  },
  async setMenu({ commit, rootGetters }, ind_key) {
    if (ind_key.length > 0) {
      const response = await apiCalls.setMenu(ind_key)
      if (response && response.status === 200) {
        await (async () => {
          response.data.TopItemList.map(til => {
            til.toggle = false
            til.ChildItemList.map(cil => {
              cil.toggle = false
            })
          })
        })()

        const _country_home_menu_key = '9f6e0dab-b07e-4642-a280-07875161b245'
        const _state_home_menu_key = '3cabb7a1-0d7d-4236-a44f-8def3e13548d'
        const _officers_menu_key = 'c8b1eb4c-691e-4443-8d0b-7b9bf50672f0'
        const _monthly_camp_reports_menu_key = 'd7b56490-9d9d-4af9-ad6b-441ac47209e2'
        const _reports_menu_key = '9e20026b-9a45-46cd-bc64-df7bd1e95d91'
        const _camp_website_security_menu_key = '3eee7729-351c-4670-bc9b-b826d7203873'
        const _camp_website_security_manager_tile = 'de3d59ca-5a37-489c-8a01-82f91f3fc821'
        const _state_goal_planner_tile = '5222f96a-3d64-4333-9e33-805f95bbe092'
        const _state_goal_planner_menu_key = '432e70e4-4e65-4fa8-935c-7a12fc6162a0'
        const _state_security_manager_tile = 'd0677692-759a-4464-8933-4ce2b67785c2'
        const _state_security_menu_key = '9609ebf9-5617-4a4e-9fc2-81b60e11c970'
        const _store_menu_key = '44844544-24a2-4c77-a41e-a2ed7eb34a9a'
        const _store_resources_menu_key = 'd5400d81-7b5b-4dba-a556-50dd8f6cf38c'

        // menu exceptions
        if (!rootGetters['user/iCanSee'](_camp_website_security_manager_tile)) {
          removeFromMenuItemList(response.data.TopItemList, _officers_menu_key, _camp_website_security_menu_key)
        }
        if (!rootGetters['user/iCanSee'](_state_security_manager_tile)) {
          removeFromMenuItemList(response.data.TopItemList, _officers_menu_key, _state_security_menu_key)
        }
        if (!rootGetters['user/iCanSee'](_state_goal_planner_tile)) {
          removeFromMenuItemList(response.data.TopItemList, _officers_menu_key, _state_goal_planner_menu_key)
        }
        if (rootGetters['user/userLogin'].campnumber[0] !== 'U') {
          removeFromChildItemList(response.data.TopItemList, _store_resources_menu_key, _store_menu_key)
        }

        switch (rootGetters['user/userLogin'].membertype) {
          case 'icc':
          case 'ioc':
          case 'ICC':
          case 'IOC':
            removeFromChildItemList(response.data.TopItemList, _officers_menu_key, _state_home_menu_key)
            removeFromMenuItemList(response.data.TopItemList, _reports_menu_key, _monthly_camp_reports_menu_key)
            break
          case 'usa':
          case 'USA':
            removeFromChildItemList(response.data.TopItemList, _officers_menu_key, _country_home_menu_key)
            break
        }

        await commit('SET_MENU', [...response.data.TopItemList])
        await commit('SET_MENU_HREFS')
        await commit('SET_GIFTS_STATUS', response.data.gifts_open)
        await commit('SET_STORE_STATUS', response.data.store_open)
        await commit('SET_TREASURY_STATUS', response.data.treasury_open)
      }
    }
  }
}

export const apiCalls = {
  setMenu(ind_key) {
    const route = MEMBER_API_CONFIG.setMenu(ind_key)
    return axios.get(route)
  }
}

export const menu = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
