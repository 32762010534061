/* eslint-disable */
import axios from 'axios'
import { MEMBER_API_CONFIG } from '../config/member.api.config.js'

export const state = () => ({
  churches: [],
  countryKey: null,
  individuals: [],
  countryStates: [],
  globalSiteSearch: '',
  searchTerms: {
    announcementsTerm: null,
    churchTerm: null,
    churchMembersTerm: null,
    committeesTerm: null,
    conventionsTerm: null,
    conversationsTerm: null,
    genericTerm: null,
    gideonCardTerm: null,
    gideonStoreTerm: null,
    managementTerm: null,
    memberChurchesTerm: null,
    membersTerm: null,
    presentersTerm: null,
    presentationsTerm: null,
    reportsTerm: null,
    scriptureTerm: null,
    securityTerm: null,
    testimonyTerm: null,
    visitsTerm: null,
    witnessTerm: null
  }
})

export const getters = {
  countryStateSelect: state => {
    return state.countryStates.map(m => {
      return {
        text: m.display_text,
        value: m.state_code
      }
    })
  },
  countryKey: state => {
    return state.countryKey
  },
  matchingChurches: state => {
    return state.churches
  },
  specificChurch: state => orgkey => {
    return state.churches.filter(p => p.org_key === orgkey)
  },
  matchingChurches: state => {
    return state.churches.map(m => {
      let addrArray = m.address_block.split(',')
      return {
        org_key: m.org_key,
        name_text: m.name_text,
        address_block: addrArray[0] + ' ' + addrArray[1],
        email_text: m.email_text,
        phone_text: m.phone_text
      }
    })
  },
  matchingIndividuals: state => {
    return state.individuals.map(m => {
      let addrArray = m.address_block.split(',')
      return {
        ind_key: m.ind_key,
        name_text: m.name_text,
        address_block: addrArray[0] + ' ' + addrArray[1],
        email_text: m.email_text,
        phone_text: m.phone_text,
        auxiliary_member: m.auxiliary_member
      }
    })
  },
  specificIndividual: state => indkey => {
    return state.individuals.filter(p => p.ind_key === indkey)
  },
  globalSiteSearch: state => {
    return state.globalSiteSearch
  },
  searchTerms: state => {
    return state.searchTerms
  }
}

export const CLEAR_SEARCHES = 'CLEAR_SEARCHES'
export const CLEAR_SEARCH_TERM = 'CLEAR_SEARCH_TERM'
export const SET_CHURCHES = 'SET_CHURCHES'
export const SET_COUNTRY_KEY = 'SET_COUNTRY_KEY'
export const SET_COUNTRY_STATES = 'SET_COUNTRY_STATES'
export const SET_INDIVIDUALS = 'SET_INDIVIDUALS'
export const SET_GLOBALSITESEARCH = 'SET_GLOBALSITESEARCH'
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM'

export const mutations = {
  [CLEAR_SEARCH_TERM](state) {
    state.globalSiteSearch = ''
  },
  [CLEAR_SEARCHES](state) {
    state.churches = []
    state.individuals = []
    state.countryStates = []
  },
  [SET_CHURCHES](state, data) {
    state.churches = data
  },
  [SET_COUNTRY_KEY](state, data) {
    state.countryKey = data[0].value
  },
  [SET_COUNTRY_STATES](state, data) {
    state.countryStates = data
  },
  [SET_INDIVIDUALS](state, data) {
    state.individuals = data
  },
  [SET_GLOBALSITESEARCH](state, data) {
    state.globalSiteSearch = data
  },
  [SET_SEARCH_TERM](state, data) {
    if (!data) {
      state.searchTerms = {
        announcementsTerm: null,
        churchTerm: null,
        churchMembersTerm: null,
        committeesTerm: null,
        conventionsTerm: null,
        genericTerm: null,
        gideonCardTerm: null,
        gideonStoreTerm: null,
        managementTerm: null,
        memberChurchesTerm: null,
        membersTerm: null,
        presentersTerm: null,
        presentationsTerm: null,
        reportsTerm: null,
        scriptureTerm: null,
        securityTerm: null,
        testimonyTerm: null,
        visitsTerm: null,
        witnessTerm: null
      }
    } else {
      // data should be some form of searchTerms, like
      // { conventionsTerm: 'Nashville' } or
      // { genericTerm: 'rally' } or
      // { scriptureTerm: 'dentist' }, etc
      for (var key in data) {
        if (state.searchTerms.hasOwnProperty(key)) {
          if (/[a-zA-Z]+Term/.test(key)) {
            state.searchTerms[key] = data[key]
          }
        }
      }
    }
  }
}

export const actions = {
  async clearSearches({ commit }) {
    commit('CLEAR_SEARCHES')
  },
  async clearSearchTerm({ commit }) {
    commit('CLEAR_SEARCH_TERM')
  },
  async getCountryForCamp({ commit }, payload) {
    try {
      let response = await apiCalls.getCountryForCamp(payload)
      if (response && response.status === 200) {
        await Promise.all([commit('SET_COUNTRY_KEY', response.data)]).then(() => {
          return true
        })
      } else {
        throw 'Bad response - country was not found'
      }
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async getStatesForCountry({ commit }, payload) {
    try {
      let response = await apiCalls.getStatesForCountry(payload)
      if (response && response.status === 200) {
        await Promise.all([commit(SET_COUNTRY_STATES, response.data)]).then(() => {
          return true
        })
      } else {
        throw 'Bad response - country was not found'
      }
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async locateIndividuals({ commit }, payload) {
    try {
      let response = await apiCalls.findIndividual(payload)
      if (response && response.status === 200) {
        await commit('SET_INDIVIDUALS', response.data)
        return true
      } else {
        throw 'Bad response - individual was not found'
      }
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async locateChurches({ commit }, payload) {
    try {
      let response = await apiCalls.findChurch(payload)
      if (response && response.status === 200) {
        await Promise.all([commit('SET_CHURCHES', response.data)]).then(() => {
          return true
        })
      } else {
        throw 'Bad response - church was not found'
      }
    } catch (e) {
      console.error(e)
      return false
    }
  },
  setGlobalSiteSearch: ({ commit }, payload) => {
    commit('SET_GLOBALSITESEARCH', payload)
  },
  async setSearchTerm({ commit }, payload) {
    await commit('SET_SEARCH_TERM', payload)
  }
}

const apiCalls = {
  findIndividual(data) {
    const route = MEMBER_API_CONFIG.findIndividual()
    return axios.post(route, data)
  },
  findChurch(data) {
    const route = MEMBER_API_CONFIG.findChurch()
    return axios.post(route, data)
  },
  getCountryForCamp(data) {
    const route = MEMBER_API_CONFIG.getCountryForCamp(data)
    return axios.get(route)
  },
  getStatesForCountry(data) {
    const route = MEMBER_API_CONFIG.getStatesForCountry(data)
    return axios.get(route)
  }
}

export const search = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
