/*eslint-disable*/
import StateDashboardService from '@/services/goals/StateDashboard.js'
import { GoalShortCodes } from '@/constants/GoalShortCodes.js'

export const SET_STATE_DASHBOARD_DATA = 'SET_STATE_DASHBOARD_DATA'
export const SET_STATE_CAMPS_DATA = 'SET_STATE_CAMPS_DATA'
export const SET_STATE_PLANNING_FISCAL_YEAR = 'SET_STATE_PLANNING_FISCAL_YEAR'
export const SET_STATE_TO_CAMP_REDIRECTION_DATA = 'SET_STATE_TO_CAMP_REDIRECTION_DATA'

export const state = () => ({
  statePlanningFiscalYear: null,
  stateDashboard: {},
  stateCampsDetails: [],
  stateToCampDashboardRedirectionData: {
    campName: '',
    isRedirectedBefore: false
  }
})

export const mutations = {
  [SET_STATE_DASHBOARD_DATA](state, payload) {
    state.stateDashboard = payload
  },
  [SET_STATE_CAMPS_DATA](state, payload) {
    state.stateCampsDetails = payload
  },
  [SET_STATE_PLANNING_FISCAL_YEAR](state, payload) {
    state.statePlanningFiscalYear = payload
  },
  [SET_STATE_TO_CAMP_REDIRECTION_DATA](state, payload) {
    state.stateToCampDashboardRedirectionData = payload
  }
}

export const getters = {
  stateDashboardDetails: state => {
    return state.stateDashboard
  },
  stateCampsDetails: state => {
    return state.stateCampsDetails
  },
  statePlanningFiscalYear(state) {
    return state.statePlanningFiscalYear
  },
  stateToCampDashboardRedirectionData(state) {
    return state.stateToCampDashboardRedirectionData
  }
}

export const actions = {
  async getStateDashboardDetails({ commit, state, dispatch }, { stateOrgKey }) {
    try {
      dispatch('menu/setLoadingStatus', true, { root: true })
      const responseData = await StateDashboardService.getStateDashboardDetails(stateOrgKey)
      if (responseData) {
        commit('SET_STATE_DASHBOARD_DATA', responseData)
        commit('SET_STATE_CAMPS_DATA', responseData.CampList)
        commit('SET_STATE_PLANNING_FISCAL_YEAR', parseInt(responseData.FiscalYear))
        dispatch('menu/setLoadingStatus', false, { root: true })
      } else {
        dispatch('menu/setLoadingStatus', false, { root: true })
      }
    } catch (e) {
      dispatch('menu/setLoadingStatus', false, { root: true })
      console.error(e)
      return false
    }
  },

  async getStateCampsDetails({ commit, state, dispatch }, { stateOrgKey, region, area, category }) {
    try {
      dispatch('menu/setLoadingStatus', true, { root: true })
      const responseData = await StateDashboardService.getStateCampsDetails(stateOrgKey, region, area, category)
      if (responseData) {
        commit('SET_STATE_CAMPS_DATA', responseData)
        dispatch('menu/setLoadingStatus', false, { root: true })
      } else {
        dispatch('menu/setLoadingStatus', false, { root: true })
      }
    } catch (e) {
      dispatch('menu/setLoadingStatus', false, { root: true })
      console.error(e)
      return false
    }
  },

  async lockUnlockCampGoals({ dispatch }, payload) {
    try {
      dispatch('menu/setLoadingStatus', true, { root: true })
      const response = await StateDashboardService.lockUnlockCampGoals(payload)
      dispatch('menu/setLoadingStatus', false, { root: true })
      return response
    } catch (e) {
      dispatch('menu/setLoadingStatus', false, { root: true })
      console.error(e)
      return false
    }
  },

  async lockState({ dispatch }, { stateOrgKey }) {
    dispatch('menu/setLoadingStatus', true, { root: true })
    const response = await StateDashboardService.lockState(stateOrgKey, { stateOrgKey })
    dispatch('menu/setLoadingStatus', false, { root: true })
    return response
  },

  async exportCSV({ commit, state, dispatch }, { stateOrgKey, csvType, fiscalYear }) {
    try {
      dispatch('menu/setLoadingStatus', true, { root: true })
      await StateDashboardService.getStateGoalsReport(stateOrgKey, csvType, fiscalYear)
      dispatch('menu/setLoadingStatus', false, { root: true })
    } catch (e) {
      console.error(e)
      dispatch('menu/setLoadingStatus', false, { root: true })
    }
  },

  async downloadCampStatusReport({ commit, state, dispatch }, { stateOrgKey, fiscalYear }) {
    try {
      dispatch('menu/setLoadingStatus', true, { root: true })
      await StateDashboardService.getCampStatusReport(stateOrgKey, fiscalYear)
      dispatch('menu/setLoadingStatus', false, { root: true })
    } catch (e) {
      console.error(e)
      dispatch('menu/setLoadingStatus', false, { root: true })
    }
  },

  async getCampQuickGoalPlanningData({ dispatch }, { stateKey, campKey }) {
    try {
      const response = await StateDashboardService.getQuickCampGoalPlanningData(stateKey, campKey)
      return formatQuickGoalPlanningResponse(response)
    } catch (e) {
      console.error(e)
    }
  },

  async saveCampQuickGoalPlanningData({ dispatch }, { campQuickGoalPlanningData }) {
    try {
      dispatch('menu/setLoadingStatus', true, { root: true })
      const response = await StateDashboardService.saveQuickCampGoalPlanningData(campQuickGoalPlanningData)
      dispatch('menu/setLoadingStatus', false, { root: true })
      return response
    } catch (e) {
      console.error(e)
      dispatch('menu/setLoadingStatus', false, { root: true })
    }
  },

  async saveCampGoalByCategory({ dispatch }, { toSaveCampGoalsDataState }) {
    try {
      dispatch('menu/setLoadingStatus', true, { root: true })
      const response = await StateDashboardService.saveCampGoalByCategory(toSaveCampGoalsDataState)
      dispatch('menu/setLoadingStatus', false, { root: true })
      return response
    } catch (e) {
      console.error(e)
      dispatch('menu/setLoadingStatus', false, { root: true })
    }
  },

  async runStateTargetGoal({ dispatch }, { targetGoalData }) {
    try {
      dispatch('menu/setLoadingStatus', true, { root: true })
      const response = await StateDashboardService.runStateTargetGoal(targetGoalData)
      dispatch('menu/setLoadingStatus', false, { root: true })
      return response
    } catch (e) {
      console.error(e)
      dispatch('menu/setLoadingStatus', false, { root: true })
    }
  },
  setStateToCampDashboardRedirectionData({ commit }, data) {
    commit(SET_STATE_TO_CAMP_REDIRECTION_DATA, data)
  },
  resetStateToCampDashboardRedirectionData({ commit }) {
    commit(SET_STATE_TO_CAMP_REDIRECTION_DATA, {
      campName: '',
      isRedirectedBefore: false
    })
  },

  async uploadStateCSV({ dispatch }, { stateOrgKey, indKey, file }) {
    try {
      dispatch('menu/setLoadingStatus', true, { root: true })
      const response = await StateDashboardService.stateCSVUpload(stateOrgKey, indKey, file)
      dispatch('menu/setLoadingStatus', false, { root: true })
      return response
    } catch (e) {
      console.error(e)
      dispatch('menu/setLoadingStatus', false, { root: true })
    }
  }
}

function formatQuickGoalPlanningResponse(data) {
  return {
    receipts: [
      {
        goalShortCode: GoalShortCodes.ChurchMinistry,
        categoryName: 'Church Ministry',
        goal: data.ChurchMinistryGoal,
        fY1Goal: data.ChurchMinistryPY1Goal,
        fY2Goal: data.ChurchMinistryPY2Actual
      },
      {
        goalShortCode: GoalShortCodes.GideonCard,
        categoryName: 'GideonCard',
        goal: data.GideonCardGoal,
        fY1Goal: data.GideonCardPY1Goal,
        fY2Goal: data.GideonCardPY2Actual
      },
      {
        goalShortCode: GoalShortCodes.FaithFund,
        categoryName: 'Faith Fund',
        goal: data.FaithFundGoal,
        fY1Goal: data.FaithFundPY1Goal,
        fY2Goal: data.FaithFundPY2Actual
      },
      {
        goalShortCode: GoalShortCodes.BarnabasFund,
        categoryName: 'Barnabas Fund',
        goal: data.BarnabasFundGoal,
        fY1Goal: data.BarnabasFundPY1Goal,
        fY2Goal: data.BarnabasFundPY2Actual
      },
      {
        goalShortCode: GoalShortCodes.AuxiliaryScripture,
        categoryName: 'Auxiliary Scripture',
        goal: data.AuxiliaryScriptureGoal,
        fY1Goal: data.AuxiliaryScripturePY1Goal,
        fY2Goal: data.AuxiliaryScripturePY2Actual
      },
      {
        goalShortCode: GoalShortCodes.CampDesignated,
        categoryName: 'Camp Designated',
        goal: data.CampDesignatedGoal,
        fY1Goal: data.CampDesignatedPY1Goal,
        fY2Goal: data.CampDesignatedPY2Actual
      }
    ],
    placements: [
      {
        goalShortCode: GoalShortCodes.GideonPlacements,
        categoryName: 'Gideon Placements',
        goal: data.GideonPlacementGoal,
        fY1Goal: data.GideonPlacementPY1Goal,
        fY2Goal: data.GideonPlacementPY2Actual
      },
      {
        goalShortCode: GoalShortCodes.AuxiliaryPlacements,
        categoryName: 'Auxiliary Placements',
        goal: data.AuxiliaryPlacementGoal,
        fY1Goal: data.AuxiliaryPlacementPY1Goal,
        fY2Goal: data.AuxiliaryPlacementPY2Actual
      }
    ],
    pwts: [
      {
        goalShortCode: GoalShortCodes.GideonPWTs,
        categoryName: 'Gideon PWTs',
        goal: data.GideonPWTGoal,
        fY1Goal: data.GideonPWTPY1Goal,
        fY2Goal: data.GideonPWTPY2Actual
      },
      {
        goalShortCode: GoalShortCodes.AuxiliaryPWTs,
        categoryName: 'Auxiliary PWTs',
        goal: data.AuxiliaryPWTGoal,
        fY1Goal: data.AuxiliaryPWTPY1Goal,
        fY2Goal: data.AuxiliaryPWTPY2Actual
      }
    ],
    churchPresentation: [
      {
        goalShortCode: GoalShortCodes.ChurchPresentation,
        categoryName: 'Church Presentation',
        goal: data.ChurchPresentationGoal,
        fY1Goal: data.ChurchPresentationPY1Goal,
        fY2Goal: data.ChurchPresentationPY2Actual
      }
    ],
    membership: [
      {
        goalShortCode: GoalShortCodes.GideonMembership,
        categoryName: 'Gideon Membership',
        goal: data.GideonMembershipGoal,
        fY1Goal: data.GideonMembershipPY1Goal,
        fY2Goal: data.GideonMembershipPY2Actual
      },
      {
        goalShortCode: GoalShortCodes.AuxiliaryMembership,
        categoryName: 'Auxiliary Membership',
        goal: data.AuxiliaryMembershipGoal,
        fY1Goal: data.AuxiliaryMembershipPY1Goal,
        fY2Goal: data.AuxiliaryMembershipPY2Actual
      }
    ]
  }
}

export const stateGoalPlanner = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
