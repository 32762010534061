/* eslint-disable */
export const state = () => ({
    cachedBreadCrumbs: {},
    currentLang: null,
    isCached: false
})

export const getters = {
    getCachedBreadCrumbs: state => {
        return state.cachedBreadCrumbs
    },
    getCurrentLang: state => {
        return state.currentLang
    },
    getIsCached: state => {
        return state.isCached
    }
}

export const SET_CACHED_BREADCRUMBS = 'SET_CACHED_BREADCRUMBS'
export const SET_CURRENT_LANG = 'SET_CURRENT_LANG'
export const SET_IS_CACHED = 'SET_IS_CACHED'

export const mutations = {
    [SET_CACHED_BREADCRUMBS](state, data) {
        state.cachedBreadCrumbs = data
    },
    [SET_CURRENT_LANG](state, data) {
        state.currentLang = data
    },
    [SET_IS_CACHED](state, data) {
        state.isCached = data
    }
}

export const actions = {
    async setCachedBreadCrumbs({ commit, rootGetters }, data) {
        let isCached = rootGetters["breadCrumbsTranslation/getIsCached"]
        if (!isCached) {
            await commit('SET_CACHED_BREADCRUMBS', data)
        }

    },
    async setCurrentLang({ commit }, data) {
        await commit('SET_CURRENT_LANG', data)
    },

    async setIsCached({ commit }, data) {
        await commit('SET_IS_CACHED', data)
    }
}

export const breadCrumbsTranslation = {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions
}