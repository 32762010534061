/* eslint-disable */
import axios from 'axios'
import { API_CONFIG } from '../config/api.config.js'
import { REPORT_CONFIG } from '../config/report.config.js'

export const state = () => ({
  calendarItem: {},
  campPrayerListing: {},
  campSpecialRequest: [],
  categories: [],
  countryPrayerListing: {},
  countrySpecialRequest: [],
  dailyData: [],
  internationalPrayerListing: {},
  internationalSpecialRequest: [],
  monthPrayerRequest: [],
  monthlyData: [],
  newRequestObject: {},
  prayerItem: {},
  prayerLevel: '',
  prayerRequestItem: {},
  selectedCalendarKey: null,
  selectedPrayerRequest: {},
  specialRequest: [],
  statePrayerListing: {},
  stateSpecialRequest: [],
  types: []
})

export const getters = {
  campPrayerListing: state => {
    return state.campPrayerListing
  },
  campSpecialRequest: state => {
    return state.campSpecialRequest
  },
  categories: state => {
    return state.categories
  },
  countryPrayerListing: state => {
    return state.countryPrayerListing
  },
  countrySpecialRequest: state => {
    return state.countrySpecialRequest
  },
  dailyData: state => {
    return state.dailyData
  },
  getCalendarItem: state => {
    return state.calendarItem
  },
  getPrayerRequestItem: state => {
    return state.prayerRequestItem
  },
  prayerItem: state => {
    return state.prayerItem
  },
  prayerLevel: state => {
    return state.prayerLevel
  },
  internationalPrayerListing: state => {
    return state.internationalPrayerListing
  },
  internationalSpecialRequest: state => {
    return state.internationalSpecialRequest
  },
  monthPrayerRequest: state => {
    return state.monthPrayerRequest
  },
  newRequestObject: state => {
    return state.newRequestObject
  },
  selectedPrayerCalendarKey: state => {
    return state.selectedCalendarKey
  },
  selectedPrayerRequest: state => {
    return state.selectedPrayerRequest
  },
  specialRequest: state => {
    return state.specialRequest
  },
  statePrayerListing: state => {
    return state.statePrayerListing
  },
  stateSpecialRequest: state => {
    return state.stateSpecialRequest
  },
  types: state => {
    return state.types
  }
}

export const SET_CAMP_PRAYER_LISTING = 'SET_CAMP_PRAYER_LISTING'
export const SET_CAMP_SPECIAL_REQUEST = 'SET_CAMP_SPECIAL_REQUEST'
export const SET_COUNTRY_PRAYER_LISTING = 'SET_COUNTRY_PRAYER_LISTING'
export const SET_COUNTRY_SPECIAL_REQUEST = 'SET_COUNTRY_SPECIAL_REQUEST'
export const SET_DAILY_DATA = 'SET_DAILY_DATA'
export const SET_INTERNATIONAL_PRAYER_LISTING = 'SET_INTERNATIONAL_PRAYER_LISTING'
export const SET_INTERNATIONAL_SPECIAL_REQUEST = 'SET_INTERNATIONAL_SPECIAL_REQUEST'
export const SET_MONTH_PRAYER_REQUEST = 'SET_MONTH_PRAYER_REQUEST'
export const SET_NEW_REQUEST_OBJECT = 'SET_NEW_REQUEST_OBJECT'
export const SET_PRAYER_CATERGORY_TYPES = 'SET_PRAYER_CATERGORY_TYPES'
export const SET_PRAYER_ITEM_AND_LEVEL = 'SET_PRAYER_ITEM_AND_LEVEL'
export const SET_SELECTED_CALENDAR_KEY = 'SET_SELECTED_CALENDAR_KEY'
export const SET_SELECTED_PRAYER_REQUEST = 'SET_SELECTED_PRAYER_REQUEST'
export const SET_SPECIAL_REQUEST = 'SET_SPECIAL_REQUEST'
export const SET_STATE_PRAYER_LISTING = 'SET_STATE_PRAYER_LISTING'
export const SET_STATE_SPECIAL_REQUEST = 'SET_STATE_SPECIAL_REQUEST'
export const STORE_CALENDAR_ITEM = 'STORE_CALENDAR_ITEM'
export const STORE_PRAYER_REQUEST_ITEM = 'STORE_PRAYER_REQUEST_ITEM'

export const mutations = {
  [SET_CAMP_PRAYER_LISTING](state, data) {
    state.campPrayerListing = data
  },
  [SET_CAMP_SPECIAL_REQUEST](state, data) {
    state.campSpecialRequest = data
  },
  [SET_COUNTRY_PRAYER_LISTING](state, data) {
    state.countryPrayerListing = data
  },
  [SET_COUNTRY_SPECIAL_REQUEST](state, data) {
    state.countrySpecialRequest = data
  },
  [SET_DAILY_DATA](state, data) {
    state.dailyData = data
  },
  [SET_SELECTED_CALENDAR_KEY](state, data) {
    state.selectedCalendarKey = data
  },
  [SET_SELECTED_PRAYER_REQUEST](state, data) {
    state.selectedPrayerRequest = data
  },
  [SET_STATE_PRAYER_LISTING](state, data) {
    state.statePrayerListing = data
  },
  [SET_INTERNATIONAL_PRAYER_LISTING](state, data) {
    state.internationalPrayerListing = data
  },
  [SET_INTERNATIONAL_SPECIAL_REQUEST](state, data) {
    state.internationalSpecialRequest = data
  },
  [SET_MONTH_PRAYER_REQUEST](state, data) {
    state.monthPrayerRequest = data
  },
  [SET_NEW_REQUEST_OBJECT](state, data) {
    state.newRequestObject = data
  },
  [SET_PRAYER_CATERGORY_TYPES](state, data) {
    state.types = data.types
    state.categories = data.categories
  },
  [SET_SPECIAL_REQUEST](state, data) {
    state.specialRequest = data
  },
  [SET_STATE_SPECIAL_REQUEST](state, data) {
    state.stateSpecialRequest = data
  },
  [STORE_CALENDAR_ITEM](state, data) {
    state.calendarItem = data
  },
  [STORE_PRAYER_REQUEST_ITEM](state, data) {
    state.prayerRequestItem = data
  },
  [SET_PRAYER_ITEM_AND_LEVEL](state, data) {
    state.prayerItem = data.item
    state.prayerLevel = data.level
  }
}

export const actions = {
  async setPrayerItemAndLevel({ commit }, pkg) {
    await commit('SET_PRAYER_ITEM_AND_LEVEL', pkg)
  },
  async getImageSrcUrl({ rootGetters }, imgPath) {
    return apiCalls.getCalendarItemImageUrl(rootGetters['user/userLanguageKey'], imgPath)
  },
  async getDailyData({ commit, rootGetters }, date) {
    const lng = rootGetters['user/userPreferredCulture'].substr(0, 2)
    const response = await apiCalls.getDailyData(rootGetters['user/userCampKey'], date, lng)
    if (response && response.status === 200) {
      await commit('SET_DAILY_DATA', response.data)
    }
  },
  async getCountryPrayerListing({ commit, rootGetters }, type = '') {
    const response = await apiCalls.getPrayerListing(rootGetters['user/userStateKey'], type)
    if (response && response.status === 200) {
      await commit('SET_COUNTRY_PRAYER_LISTING', response.data)
    }
  },
  async getCampPrayerListing({ commit, rootGetters }, type = '') {
    const response = await apiCalls.getPrayerListing(rootGetters['user/userCampKey'], type)
    if (response && response.status === 200) {
      await commit('SET_CAMP_PRAYER_LISTING', response.data)
    }
  },
  async getStatePrayerListing({ commit, rootGetters }, type = '') {
    const response = await apiCalls.getPrayerListing(rootGetters['user/userStateKey'], type)

    if (response && response.status === 200) {
      await commit('SET_STATE_PRAYER_LISTING', response.data)
    }
  },
  async getInternationalPrayerListing({ commit, rootGetters }, type = '') {
    const response = await apiCalls.getPrayerListing(rootGetters['user/internationalKey'], type)
    if (response && response.status === 200) {
      await commit('SET_INTERNATIONAL_PRAYER_LISTING', response.data)
    }
  },
  async getMonthPrayerRequest({ commit }, data) {
    const response = await apiCalls.getMonthPrayerRequest(data.cprKey, data.orgKey)
    if (response && response.status === 200) {
      await commit('SET_MONTH_PRAYER_REQUEST', response.data)
    }
  },
  async getSpecialRequest({ commit }, orgKey) {
    const response = await apiCalls.getSpecialPrayerRequest(orgKey)
    if (response && response.status === 200) {
      await commit('SET_SPECIAL_REQUEST', response.data)
    }
  },
  async getCampSpecialRequest({ commit, rootGetters }) {
    let orgKey = rootGetters['user/userCampKey']
    const response = await apiCalls.getSpecialPrayerRequest(orgKey)
    if (response && response.status === 200) {
      await commit('SET_CAMP_SPECIAL_REQUEST', response.data)
    }
  },
  async getCountrySpecialRequest({ commit, rootGetters }) {
    let orgKey = rootGetters['user/userStateKey']
    const response = await apiCalls.getSpecialPrayerRequest(orgKey)
    if (response && response.status === 200) {
      await commit('SET_COUNTRY_SPECIAL_REQUEST', response.data)
    }
  },
  async getStateSpecialRequest({ commit, rootGetters }) {
    let orgKey = rootGetters['user/userStateKey']
    const response = await apiCalls.getSpecialPrayerRequest(orgKey)
    if (response && response.status === 200) {
      await commit('SET_STATE_SPECIAL_REQUEST', response.data)
    }
  },
  async getInternationalSpecialRequest({ commit, rootGetters }) {
    let orgKey = rootGetters['user/internationalKey']
    const response = await apiCalls.getSpecialPrayerRequest(orgKey)
    if (response && response.status === 200) {
      await commit('SET_INTERNATIONAL_SPECIAL_REQUEST', response.data)
    }
  },
  async getCategoryTypes({ commit }, org_key) {
    const response = await apiCalls.getCategoryTypes(org_key)
    if (response && response.status === 200) {
      await commit('SET_PRAYER_CATERGORY_TYPES', response.data)
    }
  },
  async addCalendarItem({ commit }, params) {
    const response = await apiCalls.getCategoryTypes(params.orgKey, params.body)
    if (response && response.status === 200) {
      await commit('SET_PRAYER_CATERGORY_TYPES', response.data)
    }
  },
  async setCalendarItem({ commit }, item) {
    await commit('STORE_CALENDAR_ITEM', item)
  },
  async setNewPrayerRequestObject({ commit }, item) {
    await commit('SET_NEW_REQUEST_OBJECT', item)
  },
  async setSelectedPrayerCalendarKey({ commit }, item) {
    await commit('SET_SELECTED_CALENDAR_KEY', item)
  },
  async setSelectedPrayerRequest({ commit }, item) {
    await commit('SET_SELECTED_PRAYER_REQUEST', item)
  },
  async setPrayerRequestItem({ commit }, item) {
    await commit('STORE_PRAYER_REQUEST_ITEM', item)
  },
  async addCalendar({ }, params) {
    const response = await apiCalls.addPrayerCalendar(params.data, params.orgKey)
    if (response && response.status === 200) {
      return response.data
    }
    return false
  },
  async uploadCalendarItemImage({ }, params) {
    const response = await apiCalls.uploadCalendarItemImage(params.data, params.orgKey)
    if (response && response.status === 201) {
      return response.data
    }
    return false
  },
  async uploadPrayerCalendarCSV({ rootGetters }, params) {
    try {
      const response = await apiCalls.uploadCalendarCSV(params.formData, params.cprKey, rootGetters['user/userId'])
      if (response && response.status === 200) {
        return true
      }
      return false
    } catch (err) {
      // handle HTTP errors (i.e. HTTP 412)
      throw error
    }
  },
  async updateCalendar({ }, params) {
    const response = await apiCalls.updatePrayerCalendar(params)
    if (response && response.status === 200) {
      return response.data
    }
    return false
  },
  async deletePrayerCalendar({ }, params) {
    const response = await apiCalls.deletePrayerCalendar(params)
    if (response && response.status === 200) {
      return response.data
    }
    return false
  },
  async autoGenerate({ }, key) {
    const response = await apiCalls.autoGenerate(key)
    if (response && response.status === 200) {
      return response.data
    }
    return false
  },
  async addPrayerRequest({ }, params) {
    const response = await apiCalls.addPrayerRequest(params.data, params.orgKey)
    if (response && response.status === 200) {
      return response.data
    }
    return false
  },
  async updatePrayerRequest({ }, params) {
    const response = await apiCalls.updatePrayerRequest(params.data, params.orgKey)
    if (response && response.status === 200) {
      return response.data
    }
    return false
  },
  async updateCalendarItem({ }, params) {
    const response = await apiCalls.updateCalendarItem(params.data, params.orgKey)
    if (response && response.status === 200) {
      return response.data
    }
    return false
  },
  async deletePrayerRequest({ }, itemKey) {
    const response = await apiCalls.deletePrayerRequest(itemKey)
    if (response && response.status === 200) {
      return response.data
    }
    return false
  },
  downloadPrayerCalendarUrl({ }, cprKey) {
    return apiCalls.downloadPrayerCalendarUrl(cprKey)
  }
}

export const apiCalls = {
  getDailyData(orgKey, date, lng) {
    const route = API_CONFIG.getDailyPrayerData(orgKey, date, lng)
    return axios.get(route)
  },
  getCalendarItemImageUrl(langKey, imgPath) {
    const route = API_CONFIG.getCalendarItemImage(langKey, imgPath)
    return route
  },
  getPrayerListing(key, type) {
    let route = null
    if (type == 'editable') {
      route = API_CONFIG.getEditablePrayerListing(key)
    } else {
      route = API_CONFIG.getPrayerListing(key)
    }
    return axios.get(route)
  },
  getMonthPrayerRequest(cprKey, orgKey) {
    const route = API_CONFIG.getMonthPrayerRequest(cprKey, orgKey)
    return axios.get(route)
  },
  getCategoryTypes(orgKey) {
    const route = API_CONFIG.getCategoryTypes(orgKey)
    return axios.get(route)
  },
  getSpecialPrayerRequest(orgKey) {
    const route = API_CONFIG.getSpecialPrayerRequest(orgKey)
    return axios.get(route)
  },
  addPrayerCalendar(data, orgKey) {
    const route = API_CONFIG.addPrayerCalendar(orgKey)
    return axios.post(route, data)
  },
  uploadCalendarItemImage(formData, orgKey) {
    const route = API_CONFIG.uploadCalendarItemImage(orgKey)
    return axios.post(route, formData)
  },
  uploadCalendarCSV(formData, cprKey, userIndKey) {
    const route = API_CONFIG.uploadPrayerCalendarCSV(cprKey, userIndKey)
    return axios.post(route, formData)
  },
  downloadPrayerCalendarUrl(cprKey) {
    return REPORT_CONFIG.downloadPrayerCalendarItems(cprKey, 'excel')
  },

  autoGenerate(key) {
    const route = API_CONFIG.generatePrayer(key)
    return axios.post(route, {})
  },
  updatePrayerCalendar(data) {
    const route = API_CONFIG.updatePrayerCalendar(data.key, data.name, data.active, data.user, data.year)
    return axios.put(route)
  },
  addPrayerRequest(data, orgKey) {
    const route = API_CONFIG.addPrayerRequest(orgKey)
    return axios.post(route, data)
  },
  updatePrayerRequest(data, orgKey) {
    const route = API_CONFIG.updatePrayerRequest(orgKey)
    return axios.put(route, data)
  },
  updateCalendarItem(data, orgKey) {
    const route = API_CONFIG.updateCalendarItem(orgKey)
    return axios.put(route, data)
  },
  deletePrayerRequest(itemKey) {
    const route = API_CONFIG.deletePrayerRequest(itemKey)
    return axios.delete(route)
  },
  deletePrayerCalendar(data) {
    const route = API_CONFIG.deletePrayerCalendar(data.orgKey, data.key)
    return axios.delete(route)
  }
}

export const prayerCenter = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
