import store from '../store'
import TranslationService from '@/services/TranslationService'

/* eslint-disable */
export default class breadcrumbService {
  static getTranslation = key => {
    let getCachedTranslation = store.getters['breadCrumbsTranslation/getCachedBreadCrumbs']
    return getCachedTranslation[key]
  }
  static loadBreadcrumTranslations = async () => {
    let prefCulture = store.getters['user/userPreferredCulture']
    let cachedPreCulture = store.getters['breadCrumbsTranslation/getCurrentLang']
    let translations = null
    if (cachedPreCulture === null) {
      translations = await TranslationService.getViewTranslations('breadcrumb', prefCulture)
      // load all breadcrumb translation when app starts
    } else if (cachedPreCulture !== null && prefCulture !== cachedPreCulture) {
      await store.dispatch('breadCrumbsTranslation/setIsCached', false)
      translations = await TranslationService.getViewTranslations('breadcrumb', prefCulture)
      // Check if the user have change prefculture
    }
    if (translations !== null) {
      await Promise.all([
        await store.dispatch('breadCrumbsTranslation/setCachedBreadCrumbs', translations.data),
        await store.dispatch('breadCrumbsTranslation/setIsCached', true),
        await store.dispatch('breadCrumbsTranslation/setCurrentLang', prefCulture)
      ])
    }
  }
}
