<template>
  <div id="app" class="body">
    <div v-if="!authenticated">
      <div class="page">
        <router-view />
      </div>
    </div>
    <div v-if="authenticated">
      <app-header :ocMenu="toggleMenu" :i18n="translations.components"></app-header>
      <app-menu
        :menu='menu'
        :ocMenu='toggleMenu'
        :languageHasChanged='languageChange'
        @langChange='languageHasChanged'
      ></app-menu>
      <crumby></crumby>
      <div class="page">
        <router-view @langChange="languageHasChanged" />
      </div>
      <app-loader :isLoading="loading"></app-loader>
      <app-footer></app-footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      languageChange: false,
      languageChangeCount: 0,
      menu: false,
      nav: true,
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'user/authenticated',
      loading: 'menu/loadingStatus',
      prefCulture: 'user/userPreferredCulture',
    }),
  },
  async created() {
    if (!this.authenticated) {
      let atLogIn = window.location.pathname.indexOf('login') > -1
      if (!atLogIn) {
        let ldp = window.location.pathname.replace(
          process.env.VUE_APP_PUBLIC_PATH,
          process.env.VUE_APP_PUBLIC_PATH === '/' ? '/' : ''
        )
        await this.setLoginRedirectPath(ldp)
        await this.$router.push('/login')
      }
    }
  },
  destroyed() {
  },
  methods: {
    ...mapActions({
      setAuthenticated: 'user/setAuthenticated',
      setLoginRedirectPath: 'user/setLoginRedirectPath',
    }),
    async languageHasChanged() {
      this.languageChangeCount++

      if (this.languageChangeCount % 2) {
        this.languageChange = true // should trigger a 'watch' in Menu.vue
        await this.getLanguageTranslations()
        return (this.languageChange = false)
      }
      return true
    },
    toggleMenu() {
      this.menu = !this.menu
    },
    browserClose() {
      this.setAuthenticated(false).then(() => {
        this.$router.push('/login').catch((errr) => {})
      })
    },
    async getLanguageTranslations() {
      await this.getComponentTranslations('freshdesk-widget', 'header-logo')
        .then(response => {
          this.$set(this.translations, 'components', response)
          return true
        }).then(() => {
          this.createOrRecreateFreshDeskWidget(this.userLogin, this.prefCulture, this.translations.components['freshdesk-widget'])
        })
    },
  },
  components: {
    appFooter: () => import('./components/footer/Footer.vue'),
    appHeader: () => import('./components/header/Header.vue'),
    appLoader: () => import('./components/Loader.vue'),
    appMenu: () => import('./components/Menu.vue'),
    crumby: () => import('./components/Breadcrumbs.vue'),
  },
}
</script>
