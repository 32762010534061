/* eslint-disable */
import axios from 'axios'
import { MRP_API_CONFIG } from '../config/mrp.api.config.js'

export const state = () => ({
  mrpSiteCoreBaseURL: 'https://apps.gideons.org/TGIMRPWebAPI/mrp',
  mrpLandingPageInfo: {
    name: 'Media/Resource Portal',
    text: 'Click View More on a tile to see videos, documents, etc. associated with that particular program topic.',
    buttonText: 'View More'
  },
  mrpPrograms: [],
  mrpProgramInfo: {
    itemID: '',
    name: '',
    description: '',
    linkTitle: 'Links',
    linkListing: [],
    resourceTitle: 'Resources',
    resourceListing: [],
    topicTitle: 'Topics',
    topicsDescription: '',
    topicListing: [],
    videoTitle: 'Videos',
    videoListing: [],
    viewMoreText: 'View More',
    featuredVideoText: 'Featured Video'
  },
  mrpTopic: {
    itemID: '',
    name: '',
    description: '',
    topicSelectText: 'Topics',
    resourceTitle: 'Resources',
    resourceListing: [],
    videoTitle: 'Videos',
    videoListing: []
  },
  mrpSelectedProgramId: '',
  mrpSelectedCategory: '',
  mrpSelectedTopicId: '',
  mrpSelectedVideoId: '',
  mrpSearchText: '',
  mrpSearchVideoId: '',
  mrpProgramSearchResults: {
    totalCount: 0,
    results: []
  }
})

export const getters = {
  mrpSiteCoreBaseURL: state => {
    return state.mrpSiteCoreBaseURL
  },
  mrpLandingPageInfo: state => {
    return state.mrpLandingPageInfo
  },
  mrpPrograms: state => {
    return state.mrpPrograms
  },
  mrpProgramInfo: state => {
    return state.mrpProgramInfo
  },
  mrpSelectedProgramId: state => {
    return state.mrpSelectedProgramId
  },
  mrpSelectedCategory: state => {
    return state.mrpSelectedCategory
  },
  mrpSelectedTopicId: state => {
    return state.mrpSelectedTopicId
  },
  mrpSelectedVideoId: state => {
    return state.mrpSelectedVideoId
  },
  mrpSearchVideoId: state => {
    return state.mrpSearchVideoId
  },
  mrpSearchText: state => {
    return state.mrpSearchText
  },
  mrpTopic: state => {
    return state.mrpTopic
  },
  mrpProgramSearchResults: state => {
    return state.mrpProgramSearchResults
  }
}

export const SET_MRP_PROGRAMS = 'SET_MRP_PROGRAMS'
export const SET_MRP_PROGRAM_ID = 'SET_MRP_PROGRAM_ID'
export const SET_MRP_PROGRAM_INFO = 'SET_MRP_PROGRAM_INFO'
export const SET_MRP_SELECTED_CATEGORY = 'SET_MRP_SELECTED_CATEGORY'
export const SET_MRP_SELECTED_TOPIC_ID = 'SET_MRP_SELECTED_TOPIC_ID'
export const SET_MRP_SELECTED_VIDEO_ID = 'SET_MRP_SELECTED_VIDEO_ID'
export const SET_MRP_SEARCH_VIDEO_ID = 'SET_MRP_SEARCH_VIDEO_ID'
export const SET_MRP_SEARCHED_TEXT = 'SET_MRP_SEARCHED_TEXT'
export const SET_MRP_TOPIC = 'SET_MRP_TOPIC'
export const SET_MRP_PROGRAM_SEARCH = 'SET_MRP_PROGRAM_SEARCH'

export const mutations = {
  [SET_MRP_PROGRAMS](state, data) {
    ;(state.mrpLandingPageInfo.name = data.IndexPage.Name),
      (state.mrpLandingPageInfo.text = data.IndexPage.Description),
      (state.mrpLandingPageInfo.buttonText = data.IndexPage.ButtonText),
      (state.mrpPrograms = data.ProgramListing)
  },
  [SET_MRP_PROGRAM_INFO](state, data) {
    ;(state.mrpProgramInfo.itemID = data.Page.ItemID),
      (state.mrpProgramInfo.name = data.Page.Name),
      (state.mrpProgramInfo.itemName = data.Page.ItemName),
      (state.mrpProgramInfo.description = data.Page.Description),
      (state.mrpProgramInfo.linkListing = data.LinkListing),
      (state.mrpProgramInfo.linkTitle = data.Page.LinksTitle),
      (state.mrpProgramInfo.resourceListing = data.ResourceListing),
      (state.mrpProgramInfo.resourceTitle = data.Page.AssetsTitle),
      (state.mrpProgramInfo.topicListing = data.TopicListing),
      (state.mrpProgramInfo.topicTitle = data.Page.TopicsTitle),
      (state.mrpProgramInfo.topicDescription = data.Page.TopicsDescription),
      (state.mrpProgramInfo.videoListing = data.VideoListing),
      (state.mrpProgramInfo.videoTitle = data.Page.VideosTitle),
      (state.mrpProgramInfo.viewMoreText = data.Page.ViewMoreButtonText),
      (state.mrpProgramInfo.featuredVideoText = data.Page.FeaturedVideoText)
  },
  [SET_MRP_PROGRAM_ID](state, data) {
    state.mrpSelectedProgramId = data
  },
  [SET_MRP_SELECTED_CATEGORY](state, data) {
    state.mrpSelectedCategory = data
  },
  [SET_MRP_SELECTED_TOPIC_ID](state, data) {
    state.mrpSelectedTopicId = data
  },
  [SET_MRP_SELECTED_VIDEO_ID](state, data) {
    state.mrpSelectedVideoId = data
  },
  [SET_MRP_SEARCH_VIDEO_ID](state, data) {
    state.mrpSearchVideoId = data
  },
  [SET_MRP_SEARCHED_TEXT](state, data) {
    state.mrpSearchText = data
  },
  [SET_MRP_TOPIC](state, data) {
    ;(state.mrpTopic.itemID = data.Page.ItemID),
      (state.mrpTopic.name = data.Page.Name),
      (state.mrpTopic.description = data.Page.Description),
      (state.mrpTopic.topicSelectText = data.Page.TopicSelectText),
      (state.mrpTopic.resourceListing = data.ResourceListing),
      (state.mrpTopic.resourceTitle = data.Page.AssetsTitle),
      (state.mrpTopic.videoListing = data.VideoListing),
      (state.mrpTopic.videoTitle = data.Page.VideosTitle)
  },
  [SET_MRP_PROGRAM_SEARCH](state, data) {
    ;(state.mrpProgramSearchResults.totalCount = data.TotalCount),
      (state.mrpProgramSearchResults.results = data.Results)
  }
}

export const actions = {
  async loadMRPPrograms({ commit }, payload) {
    try {
      let response = await apiCalls.loadMRPPrograms(payload)
      if (response && response.status === 200) {
        await commit('SET_MRP_PROGRAMS', response.data)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadMRPProgramInfo({ commit }, payload) {
    try {
      let response = await apiCalls.loadMRPProgramInfo(payload)
      if (response && response.status === 200) {
        await commit('SET_MRP_PROGRAM_INFO', response.data)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadMRPTopicInfo({ commit }, payload) {
    try {
      let response = await apiCalls.loadMRPTopicInfo(payload)
      if (response && response.status === 200) {
        await commit('SET_MRP_TOPIC', response.data)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadMRPProgramSearchResults({ commit }, payload) {
    try {
      let response = await apiCalls.loadMRPProgramSearchResults(payload)
      if (response && response.status === 200) {
        await commit('SET_MRP_PROGRAM_SEARCH', response.data)
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async clearMRPProgramInfo() {
    let clearedData = {
      Page: {
        ItemID: '',
        Name: '',
        Description: ''
      },
      LinkListing: [],
      ResourceListing: [],
      TopicListing: [],
      VideoListing: []
    }
    await commit('SET_MRP_PROGRAM_INFO', clearedData)
  },
  setMRPPrograms: ({ commit }, payload) => {
    commit('SET_MRP_PROGRAMS', payload)
  },
  setMRPProgramId: ({ commit }, payload) => {
    commit('SET_MRP_PROGRAM_ID', payload)
  },
  setMRPSelectedCategory: ({ commit }, payload) => {
    commit('SET_MRP_SELECTED_CATEGORY', payload)
  },
  setMRPSelectedTopicId: ({ commit }, payload) => {
    commit('SET_MRP_SELECTED_TOPIC_ID', payload)
  },
  setMRPSelectedVideoId: ({ commit }, payload) => {
    commit('SET_MRP_SELECTED_VIDEO_ID', payload)
  },
  setMRPSearchVideoId: ({ commit }, payload) => {
    commit('SET_MRP_SEARCH_VIDEO_ID', payload)
  },
  setMRPSearchText: ({ commit }, payload) => {
    commit('SET_MRP_SEARCHED_TEXT', payload)
  }
}

const apiCalls = {
  loadMRPPrograms(payload) {
    const route = MRP_API_CONFIG.getMRPLandingPage(payload.lang, payload.landingpage)
    return axios.get(route)
  },
  loadMRPProgramInfo(payload) {
    const route = MRP_API_CONFIG.getMRPProgramPage(payload.lang, !!payload.restype? payload.restype : 'all', payload.programid)
    return axios.get(route)
  },
  loadMRPTopicInfo(payload) {
    const route = MRP_API_CONFIG.getMRPTopicPage(payload.lang, payload.topicid)
    return axios.get(route)
  },
  loadMRPProgramSearchResults(payload) {
    const route = MRP_API_CONFIG.getMRPProgramSearch(payload.lang, payload.programname, payload.term)
    return axios.get(route)
  }
}

//TODO: UPDATE THESE WHEN WE HAVE THEM
const reportCalls = {}

export const mrp = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
