/* eslint-disable */
import axios from 'axios'
import {
  MEMBER_API_CONFIG
} from '../config/member.api.config.js'
import {
  OFFICERS_API_CONFIG
} from '../config/officers.api.config'

export const state = () => ({
  committeeMembers: [],
  committeeType: '',
  selectSecurityUser: [],
  memberList: [],
  webSecurityList: [],
  webStateSecurityList: []
})

export const getters = {
  committeeMembers: state => {
    return state.committeeMembers
  },
  committeeTypeKey: state => {
    return state.committeeType
  },
  memberList: state => {
    return state.memberList
  },
  selectSecurityUser: state => {
    return state.selectSecurityUser
  },
  webSecurityList: state => {
    return state.webSecurityList
  },
  webStateSecurityList: state => {
    return state.webStateSecurityList
  }
}

export const ADD_MEMBER_TO_SECURITY_GROUP = 'ADD_MEMBER_TO_SECURITY_GROUP'
export const GET_COMMITTEE_MEMBERS = 'GET_COMMITTEE_MEMBERS'
export const GET_MEMBER_LIST = 'GET_MEMBER_LIST'
export const GET_SECURITY_SETTINGS = 'GET_SECURITY_SETTINGS'
export const GET_STATE_SECURITY_SETTINGS = 'GET_STATE_SECURITY_SETTINGS'
export const SET_COMMITTEE_TYPE = 'SET_COMMITTEE_TYPE'
export const UPDATE_SECURITY_SETTINGS = 'UPDATE_SECURITY_SETTINGS'

export const mutations = {
  [GET_SECURITY_SETTINGS](state, data) {
    state.webSecurityList = data
  },
  [SET_COMMITTEE_TYPE](state, data) {
    state.committeeType = data
  },
  [GET_STATE_SECURITY_SETTINGS](state, data) {
    state.webStateSecurityList = data
  },
  [UPDATE_SECURITY_SETTINGS](state, data) {
    state.selectSecurityUser = data
  },
  [GET_MEMBER_LIST](state, data) {
    state.memberList = data
  },
  [GET_COMMITTEE_MEMBERS](state, data) {
    state.committeeMembers = data
  }
}

export const actions = {
  async getWebSecurityManagersState({
    commit,
    rootGetters
  }, orgKey) {
    let lngKey = rootGetters['user/userLanguageKey']
    const response = await apiCalls.getWebStateSecurityManagers(orgKey, lngKey)
    if (response && response.status === 200) {
      await commit('GET_STATE_SECURITY_SETTINGS', response.data.securityList)
    }
  },
  async getWebSecurityManagersCamp({
    commit,
    rootGetters
  }) {
    let orgKey = rootGetters['user/userCampKey']
    let lngKey = rootGetters['user/userLanguageKey']
    const response = await apiCalls.getWebSecurityManagers(orgKey, lngKey)
    if (response && response.status === 200) {
      await commit('GET_SECURITY_SETTINGS', response.data.securityList)
    }
  },
  async getMemberList({
    commit,
    rootGetters
  }) {
    let orgKey = rootGetters['user/userCampKey']
    let lngKey = rootGetters['user/userLanguageKey']
    const response = await apiCalls.getMemberList(orgKey, lngKey)
    if (response && response.status === 200) {
      await commit('GET_MEMBER_LIST', response.data.memberList)
    }
  },
  async getCommitteeMembersList({
    commit,
    rootGetters
  }, cmtKey) {
    let lngKey = rootGetters['user/userLanguageKey']
    const response = await apiCalls.getCommitteeMembersList(cmtKey, lngKey)
    if (response && response.status === 200) {
      await commit('GET_COMMITTEE_MEMBERS', response.data.committeeList)
      return response.data.committeeList
    }
  },
  async getCountryMemberList({
    commit,
    rootGetters
  }) {
    let orgKey = rootGetters['user/userCountryObject']
    let lngKey = rootGetters['user/userLanguageKey']
    const response = await apiCalls.getMemberList(orgKey.key, lngKey)
    if (response && response.status === 200) {
      await commit('GET_MEMBER_LIST', response.data.memberList)
    }
  },
  async saveOrUpdateSecurityManagers({ }, obj) {
    const response = await apiCalls.saveOrUpdateSecurityManagers(obj)
    return response && response.status === 200
  },
  async updateSecurityManagers({ }, obj) {
    const response = await apiCalls.updateSecurityManagers(obj)
    return response && response.status === 200
  },
  async upsertIOApplicant({ }, obj) {
    const response = await apiCalls.upsertIOApplicant(obj)
    return response && response.status === 200
  },
  async updateStateSecurityManagers({ }, obj) {
    const response = await apiCalls.updateStateSecurityManagers(obj)
    return response && response.status === 200
  },
  async getCommitteMembersByOrganisation({
    rootGetters
  }, ctp_key) {
    let orgKey = rootGetters['user/userStateKey']
    let lngKey = rootGetters['user/userLanguageKey']
    const response = await apiCalls.getCommitteMembersByOrganisation(orgKey, lngKey, ctp_key)
    if (response && response.status === 200) {
      return response.data.memberList
    } else {
      return null
    }
  },
  async getCommittePositions({
    rootGetters
  }) {
    let orgKey = rootGetters['user/userCampKey']
    let lngKey = rootGetters['user/userLanguageKey']
    const response = await apiCalls.getCommittePositions(orgKey, lngKey)
    if (response && response.status === 200) {
      return response.data.position
    } else {
      return null
    }
  },
  async getManagementTeam({
    rootGetters
  }) {
    let orgKey = rootGetters['user/userStateKey']
    let lngKey = rootGetters['user/userLanguageKey']
    const response = await apiCalls.getManagementTeam(orgKey, lngKey)
    if (response && response.status === 200) {
      return response.data
    } else {
      return null
    }
  },
  async getYearatglance({
    rootGetters
  }) {
    let orgKey = rootGetters['user/userCampKey']
    let lngKey = rootGetters['user/userLanguageKey']
    const response = await apiCalls.loadCampDashboardData(orgKey, lngKey)
    if (response && response.status === 200) {
      return response.data
    } else {
      return null
    }
  },
  async getChurchVisited({
    rootGetters
  }) {
    let orgKey = rootGetters['user/officerToolbarSelected'].country_state
    const response = await apiCalls.getChurchVisited(orgKey)
    if (response && response.status === 200) {
      return response.data
    } else {
      return null
    }
  },
  async getChurchVisitedCountry({
    rootGetters
  }) {
    let orgKey = rootGetters['user/userCountryObject']
    const response = await apiCalls.getChurchVisited(orgKey.key)
    if (response && response.status === 200) {
      return response.data
    } else {
      return null
    }
  },
  async setCommitteeType({
    commit
  }, data) {
    await commit('SET_COMMITTEE_TYPE', data)
  }
}

export const apiCalls = {
  getWebSecurityManagers(orgKey, lngKey) {
    const route = OFFICERS_API_CONFIG.getWebSecurityManagers(orgKey, lngKey)
    return axios.get(route)
  },
  getWebStateSecurityManagers(orgKey, lngKey) {
    const route = OFFICERS_API_CONFIG.getWebStateSecurityManagers(orgKey, lngKey)
    return axios.get(route)
  },
  getMemberList(orgKey, lngKey) {
    const route = OFFICERS_API_CONFIG.getMemberList(orgKey, lngKey)
    return axios.get(route)
  },
  getCommitteeMembersList(cmtKey, lngKey) {
    const route = OFFICERS_API_CONFIG.getCommitteMembersList(cmtKey, lngKey)
    return axios.get(route)
  },
  saveOrUpdateSecurityManagers(obj) {
    const route = OFFICERS_API_CONFIG.saveOrUpdateSecurityManagers()
    return axios.post(route, obj)
  },
  updateSecurityManagers(obj) {
    const route = OFFICERS_API_CONFIG.updateWebSecuirty()
    return axios.post(route, obj)
  },
  updateStateSecurityManagers(obj) {
    const route = OFFICERS_API_CONFIG.updateStateWebSecuirty()
    return axios.post(route, obj)
  },
  getCommitteMembersByOrganisation(org_key, lng_key, ctp_key) {
    const route = OFFICERS_API_CONFIG.getCommitteMembersByOrganisation(org_key, lng_key, ctp_key)
    return axios.get(route)
  },
  getCommittePositions(org_key, lng_key) {
    const route = OFFICERS_API_CONFIG.getCommittePositions(org_key, lng_key)
    return axios.get(route)
  },
  getManagementTeam(org_key, lng_key) {
    const route = OFFICERS_API_CONFIG.getManagementTeam(org_key, lng_key)
    return axios.get(route)
  },
  loadCampDashboardData(campKey, languageKey) {
    const route = MEMBER_API_CONFIG.getMembersCampDashboard(campKey, languageKey)
    return axios.get(route)
  },
  getChurchVisited(org_key) {
    const route = OFFICERS_API_CONFIG.getChurchVisited(org_key)
    return axios.get(route)
  },
  upsertIOApplicant(obj) {
    const route = OFFICERS_API_CONFIG.upsertIOApplicantInfo()
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return axios.post(route, obj, config)
  }
}

export const officersModule = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
