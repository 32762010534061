export const GoalHelpInfo = {
  GC: 'The GideonCard Receipt Goal can be calculated by multiplying the number of camp donors by the average donation.',
  CM:
    'The Church Ministry Receipt Goal can be calculated by multiplying the number of presentations by the average camp church offering.',
  BF: ` For fiscal year 2024, Barnabas Fund donations are combined with Faith Fund receipts for achieving the USA Faith Fund goal of $28 million. Of that goal, $11 million of international non-Scripture expenses are supported by the Barnabas Fund. We hope this information is helpful as you prayerfully consider shifting a portion of the Faith Fund goal to the new Barnabas Fund goal.
 For those camps looking for guidance on fiscal year 2025 goal setting, a general principle is that 40% of the camp Faith Fund giving be shifted to the Barnabas Fund based on the portion of the non-Scripture support needs within the International Countries Fund.`,
  labels: {
    GV: '(video presentation goal)',
    TC: '(Form 825) (12 per Gideon)'
  }
}
