/* eslint-disable */
import axios from 'axios'
import { API_CONFIG } from '../config/api.config.js'

export const state = () => ({
  successStories: [],
  storySummary: [],
  selectedStoryKey: null
})

export const getters = {
  successStories: state => {
    return state.successStories
  },
  storySummary: state => {
    return state.storySummary
  },
  storyKey: state => {
    return state.selectedStoryKey
  }
}

export const SET_SUCCESS_STORIES = 'SET_SUCCESS_STORIES'
export const SET_SUCCESS_STORIES_FROM_SEARCH = 'SET_SUCCESS_STORIES_FROM_SEARCH'
export const SET_STORY_SUMMARY = 'SET_STORY_SUMMARY'
export const SET_SELECTED_STORY_KEY = 'SET_SELECTED_STORY_KEY'

export const mutations = {
  [SET_SUCCESS_STORIES](state, data) {
    if (!!data) {
      state.successStories = data.map(ss => {
        const dend = ss.description.length > 247 ? '...' : ''
        ss.desc = ss.description.padEnd(250).substr(0, 247) + dend
        const tend = ss.title.length > 62 ? '...' : ''
        ss.ttl = ss.title.padEnd(62).substr(0, 62) + tend
        return ss
      })
    }
  },
  [SET_SUCCESS_STORIES_FROM_SEARCH](state, data) {
    if (!!data) {
      state.successStories = data.map(ss => {
        const dend = ss.description.length > 247 ? '...' : ''
        ss.desc = ss.description.padEnd(250).substr(0, 247) + dend
        const tend = ss.title.length > 62 ? '...' : ''
        ss.ttl = ss.title.padEnd(62).substr(0, 62) + tend
        return ss
      })
    }
  },
  [SET_STORY_SUMMARY](state, data) {
    state.storySummary = data
  },
  [SET_SELECTED_STORY_KEY](state, data) {
    state.selectedStoryKey = data
  }
}

export const actions = {
  async getSuccessStories({ commit }, pkg) {
    try {
      let response = !!pkg.selected_key
        ? await apiCalls.getSuccessStories('en', pkg.rows, pkg.selected_key)
        : await apiCalls.getSuccessStoriesNoKey('en', pkg.rows)
      if (response && response.status === 200) {
        await Promise.all([commit('SET_SUCCESS_STORIES', response.data)]).then(() => {
          return true
        })
      } else {
        throw 'Bad response - success stories were not found'
      }
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async getSpecificStory({ commit }, art_key) {
    try {
      let response = await apiCalls.getSuccessStories('en', 4, art_key)
      if (response && response.status === 200) {
        await Promise.all([commit('SET_SUCCESS_STORIES', response.data)]).then(() => {
          return true
        })
      } else {
        throw 'Bad response - success stories were not found'
      }
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async searchArticles({ commit }, searchCriteria) {
    try {
      let response = await apiCalls.searchArticles(searchCriteria)
      if (response && response.status === 200) {
        await Promise.all([commit('SET_SUCCESS_STORIES_FROM_SEARCH', response.data)]).then(() => {
          return true
        })
      } else {
        throw 'Bad response - testimonies were not found'
      }
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async setSelectedStoryKey({ commit }, key) {
    await commit('SET_SELECTED_STORY_KEY', key)
  }
}

const apiCalls = {
  getSuccessStories(lng_key, rows, key) {
    const route = API_CONFIG.getSuccessStories(lng_key, rows, key)
    return axios.get(route)
  },
  getSuccessStoriesNoKey(lng_key, rows) {
    const route = API_CONFIG.getSuccessStoriesNoKey(lng_key, rows)
    return axios.get(route)
  },
  searchArticles(search_criteria) {
    const route = API_CONFIG.searchArticles(
      search_criteria.search_for,
      search_criteria.art_type,
      search_criteria.rows,
      search_criteria.lng_key
    )
    return axios.get(route)
  }
}

export const successStory = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
