/* eslint-disable */
export const TRANSLATION_API_ACTIVE_ROUTE = `${process.env.VUE_APP_API_ROUTE_ADDRESS}/mrp`

export const TRANSLATION_API_CONFIG = {
  // getAuth(mbrkey) {
  //     return `${TRANSLATION_API_ACTIVE_ROUTE}/getceauth/${mbrkey}`
  // },
  // getHomePage(languageCode) {
  //     return `${TRANSLATION_API_ACTIVE_ROUTE}/gettchome/${languageCode}`
  // },
  getModuleTranslations(moduleName, languageCode) {
    return `${TRANSLATION_API_ACTIVE_ROUTE}/translations/${moduleName}/${languageCode}`
  },
  getMenuTranslations(languageCode) {
    return `${TRANSLATION_API_ACTIVE_ROUTE}/translations/components/menu/${languageCode}`
  },
  getViewTranslations(viewName, languageCode) {
    return `${TRANSLATION_API_ACTIVE_ROUTE}/translations/views/${viewName}/${languageCode}`
  },
  postComponentTranslations(languageCode) {
    return `${TRANSLATION_API_ACTIVE_ROUTE}/translations/component/${languageCode}`
  }
}
