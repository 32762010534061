/* eslint-disable */
export const CHURCH_API_ACTIVE_ROUTE = `${process.env.VUE_APP_API_ROUTE_ADDRESS}/church`

export const CHURCH_API_CONFIG = {
  addChurch: (ind_key, cmp_key) => `${CHURCH_API_ACTIVE_ROUTE}/add/${ind_key}/${cmp_key}`,
  addChurchContact: cnt_key => `${CHURCH_API_ACTIVE_ROUTE}/add/contact/${cnt_key}`,
  addChurchGideonRep: () => `${CHURCH_API_ACTIVE_ROUTE}/add/gideonrep`,
  addChurchPresenter: () => `${CHURCH_API_ACTIVE_ROUTE}/presenter/add/edit`,
  addChurchServiceTime: () => `${CHURCH_API_ACTIVE_ROUTE}/service/create`,
  addUpdateChurchVisitNote: () => `${CHURCH_API_ACTIVE_ROUTE}/note/create`,
  deleteChurch: (chu_org_key, chu_user) => `${CHURCH_API_ACTIVE_ROUTE}/delete/${chu_org_key}/${chu_user}`,
  deleteChurchAddress: (adr_key, adr_user) => `${CHURCH_API_ACTIVE_ROUTE}/address/delete/${adr_key}/${adr_user}`,
  deleteChurchEmail: (eml_key, eml_user) => `${CHURCH_API_ACTIVE_ROUTE}/email/delete/${eml_key}/${eml_user}`,
  deleteChurchImage: (img_key, ind_key) => `${CHURCH_API_ACTIVE_ROUTE}/image/delete/${img_key}/${ind_key}`,
  deleteChurchPhone: (phn_key, phn_user) => `${CHURCH_API_ACTIVE_ROUTE}/phone/delete/${phn_key}/${phn_user}`,
  deleteChurchServiceTime: (cti_key, cti_user) => `${CHURCH_API_ACTIVE_ROUTE}/service/delete/${cti_key}/${cti_user}`,
  deletePresentation: (mtg_key, mtg_user) => `${CHURCH_API_ACTIVE_ROUTE}/presentation/delete/${mtg_key}/${mtg_user}`,
  getAllDenominations: lng_key => `${CHURCH_API_ACTIVE_ROUTE}/denominations/${lng_key}`,
  getAllPresentations: (org_key, camp_key, lng_key) =>
    `${CHURCH_API_ACTIVE_ROUTE}/getAllPresentations/${org_key}/${camp_key}/${lng_key}`,
  getAllPresentationsByCamp: (camp_key, lng_key) =>
    `${CHURCH_API_ACTIVE_ROUTE}/getAllPresentations/${camp_key}/null/${lng_key}`,
  getAllPresentersByCamp: (org_key, lng_key) => `${CHURCH_API_ACTIVE_ROUTE}/presenters/all/${org_key}/${lng_key}`,
  getAllVisitsByCamp: (camp_key, lng_key, limit, showall) => `${CHURCH_API_ACTIVE_ROUTE}/visits/${camp_key}/${lng_key}/${limit}/${showall}`,
  getAllVisitsByChurch: (church_key, lng_key) => `${CHURCH_API_ACTIVE_ROUTE}/visitsbychurch/${church_key}/${lng_key}`,
  getChurchCommunications: (entity_key, lng_key) => `${CHURCH_API_ACTIVE_ROUTE}/communication/${entity_key}/${lng_key}`,
  getChurchContacts: (org_key, lng_key) => `${CHURCH_API_ACTIVE_ROUTE}/contacts/${org_key}/${lng_key}`,
  getChurchDetail: (org_key, lng_key) => `${CHURCH_API_ACTIVE_ROUTE}/detail/${org_key}/${lng_key}`,
  getChurchDetailSelect: (org_key, lng_key) => `${CHURCH_API_ACTIVE_ROUTE}/detail/select/${org_key}/${lng_key}`,
  getChurches: (camp_key, lng_key) => `${CHURCH_API_ACTIVE_ROUTE}/GetChurches/${camp_key}/${lng_key}`,
  getVisibleChurches: (camp_key, lng_key) => `${CHURCH_API_ACTIVE_ROUTE}/GetVisibleChurches/${camp_key}/${lng_key}`,
  getChurchMembersAndFriends: (org_key, lng_key) => `${CHURCH_API_ACTIVE_ROUTE}/members/friends/${org_key}/${lng_key}`,
  getChurchMinistryWidgetData: (camp_key, lng_key, fiscal_year, limit) =>
    `${CHURCH_API_ACTIVE_ROUTE}/GetChurchMinistryWidgetData/${camp_key}/${lng_key}/${fiscal_year}/${limit}`,
  getChurchPresentationMediaTypes: () => `${CHURCH_API_ACTIVE_ROUTE}/presentation/media/type`,
  getChurchRankingTypes: () => `${CHURCH_API_ACTIVE_ROUTE}/rankingType/all`,
  getChurchRelationshipStrengths: () => `${CHURCH_API_ACTIVE_ROUTE}/relationship/strengths`,
  getChurchSupportOptions: () => `${CHURCH_API_ACTIVE_ROUTE}/levelOfSupportType/all`,
  getChurchVisitContactTypes: lng_key => `${CHURCH_API_ACTIVE_ROUTE}/visit/contact/types/${lng_key}`,
  getMeetingsHeldByCamp: (camp_key, limit) => `${CHURCH_API_ACTIVE_ROUTE}/getMeetingsHeldByCamp/${camp_key}/${limit}`,
  getPresentationsAndServiceTimes: (chu_key, lng_key) =>
    `${CHURCH_API_ACTIVE_ROUTE}/GetChurchPresentationsAndServiceTimes/${chu_key}/${lng_key}`,
  getPresentationsScheduledByCamp: (camp_key, limit) =>
    `${CHURCH_API_ACTIVE_ROUTE}/GetPresentationsScheduledByCamp/${camp_key}/${limit}`,
  getPresenterHistory: (ind_key, lng_key) => `${CHURCH_API_ACTIVE_ROUTE}/presenterhistory/${ind_key}/${lng_key}`,
  getQualifiedPresenters: (camp_key, lng_key) => `${CHURCH_API_ACTIVE_ROUTE}/presenters/${camp_key}/${lng_key}`,
  getResourcesForChurch: (cmp_key, ind_key, lng_key) =>
    `${CHURCH_API_ACTIVE_ROUTE}/resources/${cmp_key}/${ind_key}/${lng_key}`,
  getSelectionListsForChurchAdd: lng_key => `${CHURCH_API_ACTIVE_ROUTE}/selection/lists/all/${lng_key}`,
  getSinglePresentation: mtg_key => `${CHURCH_API_ACTIVE_ROUTE}/getPresentation/${mtg_key}`,
  getSinglePresentationForDisplay: (mtg_key, lng_key) =>
    `${CHURCH_API_ACTIVE_ROUTE}/GetPresentationForDisplay/${mtg_key}/${lng_key}`,
  getSingleVisit: (cmp_key, nte_key, lng_key) =>
    `${CHURCH_API_ACTIVE_ROUTE}/visit/note/${cmp_key}/${nte_key}/${lng_key}`,
  getUnvisitedChurchesByCamp: camp_key => `${CHURCH_API_ACTIVE_ROUTE}/unvisited/${camp_key}`,
  getVisitsHeldByCamp: (camp_key, limit) => `${CHURCH_API_ACTIVE_ROUTE}/getVisitsHeldByCamp/${camp_key}/${limit}`,
  savePresentation: () => `${CHURCH_API_ACTIVE_ROUTE}/presentation/save`,
  upsertChurchPhoto: org_key => `${CHURCH_API_ACTIVE_ROUTE}/photoupsert_tc3/${org_key}`
}
