import axios from 'axios'
import { TRANSLATION_API_CONFIG } from '@/config/translation.api.config'

export default class TranslationService {
  /**
   * [getHomePage]
   * @param {[String]} languageCode [language code]
   * @returns {[Object]}
   */
  static getHomePage = async languageCode => {
    let request = await axios.get(TRANSLATION_API_CONFIG.getHomePage(languageCode))
    return request
  }

  /**
   * [getTranslations]
   * @param {[String]} moduleName [module name]
   * @param {[languageCode]} languageCode [language code]
   * @returns {[Object]}
   */
  static getModuleTranslations = async (moduleName, languageCode) => {
    let request = await axios.get(TRANSLATION_API_CONFIG.getModuleTranslations(moduleName, languageCode))
    return request
  }

  static getMenuTranslations = async languageCode => {
    let request = await axios.get(TRANSLATION_API_CONFIG.getMenuTranslations(languageCode))
    return request
  }

  static getViewTranslations = async (viewName, languageCode) => {
    if (!languageCode) { languageCode = 'en' }
    let request = await axios.get(TRANSLATION_API_CONFIG.getViewTranslations(viewName, languageCode))
    return request
  }
  static postComponentTranslations = async (componentNames, languageCode) => {
    if (!languageCode) { languageCode = 'en' }
    const route = TRANSLATION_API_CONFIG.postComponentTranslations(languageCode)
    let request = await axios.post(route, componentNames)
    return request
  }
}
