/* eslint-disable */
import axios from 'axios'
import { API_CONFIG } from '../config/api.config.js'
import { REPORT_CONFIG } from '../config/report.config.js'

export const state = () => ({
  loaded_opportunities: [],
  scripture_verse: '',
  selectedEncounterKey: '',
  selectedEncounter: {},
  opportunity_count_by_user: 0,
  opportunity_count_by_camp: 0,
  opportunityReportUrlCsv: '',
  opportunityReportUrlPdf: ''
})

export const getters = {
  opportunities: state => {
    return state.loaded_opportunities
  },
  opportunityReportUrlPdf: state => {
    return state.opportunityReportUrlPdf
  },
  opportunityReportUrlCsv: state => {
    return state.opportunityReportUrlCsv
  },
  scriptureVerse: state => {
    return state.scripture_verse
  },
  userSelectedEncounterKey: state => {
    return state.selectedEncounterKey
  },
  userSelectedEncounter: state => {
    return state.selectedEncounter
  },
  opportunityCountByUser: state => {
    return state.opportunity_count_by_user
  },
  opportunityCountByCamp: state => {
    return state.opportunity_count_by_camp
  }
}

export const SET_OPPORTUNITIES = 'SET_OPPORTUNITIES'
export const SET_SCRIPTURE_VERSE = 'SET_SCRIPTURE_VERSE'
export const SET_SELECTED_ENCOUNTER_KEY = 'SET_SELECTED_ENCOUNTER_KEY'
export const SET_SELECTED_ENCOUNTER = 'SET_SELECTED_ENCOUNTER'
export const SET_OPPORTUNITY_COUNT_BY_USER = 'SET_OPPORTUNITY_COUNT_BY_USER'
export const SET_OPPORTUNITY_COUNT_BY_CAMP = 'SET_OPPORTUNITY_COUNT_BY_CAMP'
export const SET_OPPORTUNITY_REPORT_URL_CSV = 'SET_OPPORTUNITY_REPORT_URL_CSV'
export const SET_OPPORTUNITY_REPORT_URL_PDF = 'SET_OPPORTUNITY_REPORT_URL_PDF'

export const mutations = {
  [SET_OPPORTUNITIES](state, data) {
    state.loaded_opportunities = data
  },
  [SET_SCRIPTURE_VERSE](state, data) {
    state.scripture_verse = data
  },
  [SET_SELECTED_ENCOUNTER_KEY](state, data) {
    state.selectedEncounterKey = data
  },
  [SET_SELECTED_ENCOUNTER](state, data) {
    state.selectedEncounter = data
  },
  [SET_OPPORTUNITY_COUNT_BY_USER](state, data) {
    state.opportunity_count_by_user = data
  },
  [SET_OPPORTUNITY_COUNT_BY_CAMP](state, data) {
    state.opportunity_count_by_camp = data
  },
  [SET_OPPORTUNITY_REPORT_URL_CSV](state, data) {
    state.opportunityReportUrlCsv = data
  },
  [SET_OPPORTUNITY_REPORT_URL_PDF](state, data) {
    state.opportunityReportUrlPdf = data
  }
}

export const actions = {
  async loadRecentOpportunities({ commit, rootGetters }) {
    const userCampKey = rootGetters[`user/userCampKey`]
    const userLngKey = rootGetters['user/userLanguageKey']
    const userId = rootGetters[`user/userId`]
    const lang = rootGetters['user/userPreferredCulture']

    try {
      let response = await apiCalls.getOpportunities(userCampKey, userLngKey, true, userId, lang)
      if (response && response.status === 200) {
        await commit('SET_OPPORTUNITIES', response.data.opportunities)
        await commit('SET_SCRIPTURE_VERSE', response.data.scriptureVerse.article)
        await commit('SET_OPPORTUNITY_COUNT_BY_USER', response.data.opportunityCountByUser)
        await commit('SET_OPPORTUNITY_COUNT_BY_CAMP', response.data.opportunityCountByCamp)
        await commit(
          SET_OPPORTUNITY_REPORT_URL_CSV,
          reportCalls.getWitnessOpportunityReportCsv(userCampKey, userLngKey)
        )
        await commit(
          SET_OPPORTUNITY_REPORT_URL_PDF,
          reportCalls.getWitnessOpportunityReportPdf(userCampKey, userLngKey)
        )
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadAllOpportunities({ commit, rootGetters }) {
    try {
      const userCampKey = rootGetters[`user/userCampKey`]
      const userLngKey = rootGetters['user/userLanguageKey']
      const userIndKey = rootGetters[`user/userId`]
      const lang = rootGetters['user/userPreferredCulture']

      let response = await apiCalls.getOpportunities(userCampKey, userLngKey, false, userIndKey, lang)
      if (response && response.status === 200) {
        await commit('SET_OPPORTUNITIES', response.data.opportunities)
        await commit(
          SET_OPPORTUNITY_REPORT_URL_CSV,
          reportCalls.getWitnessOpportunityReportCsv(userCampKey, userLngKey)
        )
        await commit(
          SET_OPPORTUNITY_REPORT_URL_PDF,
          reportCalls.getWitnessOpportunityReportPdf(userCampKey, userLngKey)
        )
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async addOrUpdateEncounter({ commit, rootGetters }, encounter) {
    let payload = {
      name: encounter.wit_name,
      content: encounter.wit_content,
      org_key: rootGetters[`user/userCampKey`],
      ind_key: rootGetters[`user/userId`],
      wit_key: encounter.wit_key ? encounter.wit_key : null,
      wit_delete_flag: encounter.wit_delete_flag
    }
    let response

    response = await apiCalls.updateEncounter(payload)
    if (response && response.status === 200) {
      return true
    } else {
      return false
    }
  },
  setSelectedEncounter: ({ commit }, payload) => {
    commit('SET_SELECTED_ENCOUNTER', payload)
  },
  async resetSelectedEncounter({ commit }) {
    commit('SET_SELECTED_ENCOUNTER', {})
  },
  setSelectedEncounterKey: ({ commit }, payload) => {
    commit('SET_SELECTED_ENCOUNTER_KEY', payload)
  },
  async loadEncounterDetails({ commit, rootGetters }, wit_key) {
    let response = await apiCalls.getEncounterDetailsByKey(
      wit_key,
      rootGetters[`user/userCampKey`],
      rootGetters['user/userLanguageKey']
    )
    if (response && response.status === 200) {
      commit('SET_SELECTED_ENCOUNTER', response.data)
      return true
    }
  }
}

const apiCalls = {
  getOpportunities(campKey, languageKey, mostRecent, indKey, lang) {
    const route = API_CONFIG.getOpportunities(campKey, languageKey, mostRecent, indKey, lang)
    return axios.get(route)
  },
  updateEncounter(payload) {
    const route = API_CONFIG.updateEncounter()
    return axios.post(route, payload)
  },
  async getEncounterDetailsByKey(wit_key, org_key, lnd_key) {
    const route = API_CONFIG.getEncounterDetailsByKey(wit_key, org_key, lnd_key)
    return axios.get(route)
  }
}

const reportCalls = {
  getWitnessOpportunityReportCsv(camp_key, lng_key) {
    return REPORT_CONFIG.getWitnessingOpportunitiesReport(camp_key, lng_key, 'excel')
  },
  getWitnessOpportunityReportPdf(camp_key, lng_key) {
    return REPORT_CONFIG.getWitnessingOpportunitiesReport(camp_key, lng_key, 'pdf')
  }
}

export const prayerAndWitnessing = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
