export const CampGoalsAllowedMaximumDigits = {
  CampDollar: 6,
  CampNonDollar: 4,
  StateTargetLimit: 8,
  CampNonDollarSubGoalPlacementsAndPWTs: 5,
  StateNonDollarTotalPlacements: 6,
  StateAllReceiptsDollar: 7
}

export const MaximumCampInputBound = {
  Dollar: 999999,
  NonDollar: 9999,
  NonDollarPlacementsAndPWTs: 99999
}
