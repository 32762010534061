import { API_CONFIG } from '@/config/api.config'
import { MEMBER_API_CONFIG } from '@/config/member.api.config'
import axios from 'axios'
import { attachment_prefix, empty_guid } from '@/json/data.json'
import { parseInt } from 'core-js/fn/number'

/* eslint-disable */
export const state = () => ({
  addable_committees: {},
  announcement_for_committee: {},
  announcement_for_state: {},
  announcement_for_edit: [],
  announcement_form: {
    aud_array: [],
    editing: false,
    expires: null,
    fileArray: [],
    geo_array: [],
    mbr_array: [],
    includeElected: false,
    message: null,
    msg_message_id: null,
    msg_member_type: 'b',
    notification: true,
    region: null,
    recipients: null,
    camp_key: null,
    state_key: null,
    country_key: null,
    tempFileArray: [],
    title: null,
    type: []
  },
  announcements: [],
  announcements_for_camp: [],
  announcements_for_edit: [],
  camp_offices: [],
  committee_members: {},
  committees_list: [],
  countries: {},
  individuals: [],
  last_camp_key: null,
  last_state_key: null,
  last_updated: null,
  management_teams: [
    {
      key: 'a0000000-0000-4000-0000-000000000020',
      title: 'Gideon Areas',
      areas: [
        {
          value: 'abda920a-f939-4de0-bad5-0ab91f476b5e',
          text: 'All Gideon Area Managers',
          all: 1,
          selected: true
        }
      ]
    },
    {
      key: 'c0000000-0000-4000-0000-000000000022',
      title: 'Auxiliary Areas',
      areas: [
        {
          value: '678cfa9f-3ced-4880-ab74-6bad634aaf74',
          text: 'All Auxiliary Area Managers',
          all: 1,
          selected: true
        }
      ]
    }
  ],
  members: [
    {
      key: 'd0000000-0000-4000-0000-000000000023',
      title: 'Gideon Members',
      areas: [
        {
          value: 'abda920a-f939-4de0-bad5-0ab91f476b5e',
          text: 'All Gideon Members',
          all: 1,
          selected: true
        }
      ]
    },
    {
      key: 'e0000000-0000-4000-0000-000000000024',
      title: 'Auxiliary Members',
      areas: [
        {
          value: '678cfa9f-3ced-4880-ab74-6bad634aaf74',
          text: 'All Auxiliary Members',
          all: 1,
          selected: true
        }
      ]
    }
  ],
  message_package: {
    mp_message: {
      msg_message_id: empty_guid,
      msg_expiration_date: null,
      msg_title: null,
      msg_message: null,
      msg_notification_flag: true,
      msg_include_elected_flag: false,
      msg_member_type: null,
      msg_add_date: null,
      msg_add_user: null,
      msg_change_date: null,
      msg_change_user: null,
      msg_delete_flag: false,
      msg_att_to_retain: null
    },
    mp_geographics: [],
    mp_audiences: []
  },
  mp_geographic: {
    geo_state_key: null,
    geo_geographic_distribution_id: empty_guid,
    geo_message_id: empty_guid,
    geo_distribution_location_key: empty_guid,
    geo_distribution_location_name: null,
    geo_distribution_location_all: false,
    geo_distribution_type: null,
    geo_add_date: null,
    geo_add_user: null,
    geo_change_date: null,
    geo_change_user: null,
    geo_delete_flag: false,
    geo_ordinal: 0
  },
  mp_audience: {
    aud_audience_distribution_id: empty_guid,
    aud_message_id: empty_guid,
    aud_geographic_distribution_id: empty_guid,
    aud_distribution_audience_key: [],
    aud_distribution_type: null,
    aud_add_date: null,
    aud_add_user: null,
    aud_change_date: null,
    aud_change_user: null,
    aud_delete_flag: false,
    aud_ordinal: 0
  },
  preserved_list_selections: [],
  regional_committee_key: null,
  regional_committees: [],
  selected_committee_keys: [],
  state_leaders: [],
  state_regions: [],
  user_committees: {
    title: '',
    areas: []
  }
})

export const uniqueByPropertyName = prop => arr =>
  Array.from(
    arr
      .reduce(
        (acc, item) => (item && item[prop] && acc.set(item[prop], item), acc), // using map (preserves ordering)
        new Map()
      )
      .values()
  )

export const getFormData = (object, fd) =>
  Object.keys(object).reduce((formData, key) => {
    formData.append(key, object[key])
    return formData
  }, fd)

export const getters = {
  announcements: state => {
    return state.announcements
      .filter(announcement => !announcement.hasOwnProperty('camp_key') || state.last_camp_key === announcement.camp_key)
      .filter(
        announcement => !announcement.hasOwnProperty('state_key') || state.last_state_key === announcement.state_key
      )
      .map(fa => {
        return {
          ...fa,
          ttl: fa.msg_title.substr(0, 33) + (fa.msg_title.length > 33 ? '...' : ''),
          preview: fa.msg_message.substr(0, 400) + (fa.msg_message.length > 400 ? '...' : '')
        }
      })
  },
  announcementForCommittee: state => {
    return state.announcement_for_committee
  },
  getAnnouncementForState: state => {
    return state.announcement_for_state
  },
  announcementsForCamp: state => {
    return state.announcements_for_camp
  },
  announcementsForEdit: state => {
    return state.announcements_for_edit
  },
  announcementCount: (state, getters, rootState, rootGetters) => {
    let last_login = rootGetters['user/lastLogin']
    let last_login_date = new Date(last_login)
    let ll_count = !last_login
      ? state.announcements.length
      : state.announcements.filter(af => new Date(af.sortdate) > last_login_date).length
    return ll_count > 99 ? '99+' : ll_count
  },
  announcementForm: state => {
    return state.announcement_form
  },
  campAllLeaders: state => {
    return state.camp_offices
  },
  campAnnouncements: state => {
    return state.announcements.filter(g => !!g.type && g.type.toLowerCase() === 'camp')
  },
  campAuxiliaryLeaders: state => {
    return state.camp_offices.filter(sl => sl.title.includes('Auxiliary'))
  },
  campGideonLeaders: state => {
    return state.camp_offices.filter(sl => sl.title.includes('Gideon'))
  },
  campMemberTypeId: state => {
    let s_members = state.members.find(sl => sl.areas[0].selected === true)
    return !!s_members ? s_members.areas[0].value : empty_guid
  },
  committeesList: state => {
    return state.committees_list
  },
  committeesListWithAllSelected: state => {
    return state.committees_list.map(cl => {
      return {
        key: cl.key,
        title: cl.title,
        areas: cl.areas.map(cla => {
          return {
            all: cla.all,
            selected: cla.all === 0,
            text: cla.text,
            value: cla.value
          }
        })
      }
    })
  },
  committeesListWithSomeSelected: state => atypeArray => {
    let mbrs = state.announcement_for_edit.filter(afe => atypeArray.includes(afe.type)).map(afm => afm.key)
    return state.committees_list.map(cl => {
      return {
        key: cl.key,
        title: cl.title,
        areas: cl.areas.map(cla => {
          return {
            all: cla.all,
            text: cla.text,
            selected: mbrs.indexOf(cla.value) > -1,
            value: cla.value
          }
        })
      }
    })
  },
  committeesListWithSomeSelectedAlt: state => {
    return state.committees_list.map(cl => {
      return {
        key: cl.key,
        title: cl.title,
        areas: cl.areas.map(cla => {
          return {
            all: cla.all,
            text: cla.text,
            selected: cla.selected,
            value: cla.value
          }
        })
      }
    })
  },
  selectedCommitteeMembers: state => {
    return !state.committee_members.areas
      ? {
        title: '',
        areas: []
      }
      : {
        key: empty_guid,
        title: state.committee_members.title,
        areas: state.committee_members.areas
          .filter(rf => rf.selected === true)
          .map(rm => {
            return {
              text: rm.text,
              value: rm.value,
              all: 0,
              selected: true
            }
          })
      }
  },
  committeeCountries: state => {
    return state.countries
  },
  committeeIndividuals: state => {
    return {
      key: empty_guid,
      title: 'Committee Members',
      areas: [...state.individuals]
    }
  },
  countryAnnouncements: state => {
    return state.announcements
  },
  countryAuxiliaryManagement: state => {
    let cam = state.management_teams.filter(sl => sl.title.includes('Auxiliary'))
    cam[0].areas[0].text = 'All Auxiliary Country Leaders'
    return cam
  },
  countryBothManagement: state => {
    let cbm = state.management_teams
    cbm[0].areas[0].text = 'All Gideon Country Leaders'
    cbm[1].areas[0].text = 'All Auxiliary Country Leaders'
    return cbm
  },
  countryGideonManagement: state => {
    let cgm = state.management_teams.filter(sl => sl.title.includes('Gideon'))
    cgm[0].areas[0].text = 'All Gideon Country Leaders'
    return cgm
  },
  moreAnnouncements: state => skip => {
    return state.announcements
      .filter(fi => fi.msg_key !== skip)
      .map(fa => {
        return {
          ...fa,
          ttl: fa.msg_title.substr(0, 33) + (fa.msg_title.length > 33 ? '...' : ''),
          preview: fa.msg_message.substr(0, 400) + (fa.msg_message.length > 400 ? '...' : '')
        }
      })
      .slice(1, 4) //  get the first 3 !== announcements[0] or skip
  },
  msg_pkg: state => {
    return state.message_package
  },
  preservedListSelections: state => {
    return state.preserved_list_selections
  },
  regionalCommittees: state => {
    return state.regional_committees.map(rc => {
      return {
        text: 'committee',
        value: rc.cmt_name,
        cmt_key: rc.cmt_key,
        cmt_org_key: rc.cmt_org_key
      }
    })
  },
  regionalCommitteeKey: state => {
    return state.regional_committee_key
  },
  selectedStateRegions: state => {
    return state.state_regions
      .map(ssr => {
        return {
          region: ssr.title,
          areas: ssr.areas
            .filter(rf => rf.selected === 'true' || rf.selected === true)
            .map(rm => {
              return {
                area: rm.text,
                value: rm.value
              }
            })
        }
      })
      .filter(cf => cf.areas.length > 0)
  },
  selectedCommitteeKeys: state => {
    return state.selected_committee_keys
  },
  selectedAllUserCommittees: state => {
    return !!state.user_committees.title
      ? {
        title: state.user_committees.title,
        areas: state.user_committees.areas
          .filter(rf => rf.all > 0)
          .map(rm => {
            return {
              text: rm.text,
              value: rm.value
            }
          })
      }
      : {
        title: '',
        areas: []
      }
  },
  selectedAllCommitteeCountries: state => {
    return !!state.countries.title
      ? {
        title: state.countries.title,
        areas: state.countries.areas
          .filter(rf => rf.all > 0)
          .map(rm => {
            return {
              text: rm.text,
              value: rm.value
            }
          })
      }
      : {
        title: '',
        areas: []
      }
  },
  selectedCommitteeCountries: state => {
    return !state.countries || !state.countries.areas
      ? null
      : {
        key: empty_guid,
        title: state.countries.title,
        areas: state.countries.areas
          .filter(rf => rf.selected === true)
          .map(rm => {
            return {
              text: rm.text,
              value: rm.value,
              all: 0,
              selected: true
            }
          })
      }
  },
  selectedUserCommittees: state => {
    if (!!state.user_committees.title) {
      if (!!state.user_committees.areas) {
        if (state.user_committees.areas[0].all === 1 && state.user_committees.areas[0].selected) {
          return {
            title: state.user_committees.title,
            areas: state.user_committees.areas
              .filter(rf => !rf.selected)
              .map(rm => {
                return {
                  text: rm.text,
                  value: rm.value
                }
              })
          }
        } else {
          return {
            title: state.user_committees.title,
            areas: state.user_committees.areas
              .filter(rf => rf.selected)
              .map(rm => {
                return {
                  text: rm.text,
                  value: rm.value
                }
              })
          }
        }
      }
    }
    return {
      title: '',
      areas: []
    }
  },
  specificAnnouncement: state => key => {
    return state.announcements.find(af => af.msg_key === key)
  },
  stateAnnouncements: state => {
    return state.announcements.filter(g => !!g.type && g.type.toLowerCase() === 'state')
  },
  stateAuxiliaryLeaders: state => {
    return state.state_leaders.filter(sl => sl.title.includes('Auxiliary'))
  },
  stateAuxiliaryManagement: state => {
    return state.management_teams.filter(sl => sl.title.includes('Auxiliary'))
  },
  stateAuxiliaryMembers: state => {
    return state.members.filter(sl => sl.title.includes('Auxiliary'))
  },
  stateGideonLeaders: state => {
    return state.state_leaders.filter(sl => sl.title.includes('Gideon'))
  },
  stateGideonManagement: state => {
    return state.management_teams.filter(sl => sl.title.includes('Gideon'))
  },
  stateGideonMembers: state => {
    return state.members.filter(sl => sl.title.includes('Gideon'))
  },
  stateAllLeaders: state => {
    return state.state_leaders
  },
  stateRegions: state => {
    return state.state_regions
  },
  stateBothManagement: state => {
    return state.management_teams
  },
  stateBothMembers: state => {
    return state.members
  },
  topAnnouncement: state => {
    return state.announcements[0]
  },
  userCommittees: state => {
    return state.user_committees
  },
  addableCommittees: state => {
    return state.addable_committees
  }
}

export const APPLY_PRESERVED_LIST = 'APPLY_PRESERVED_LIST'
export const CLEAR_ANNOUNCEMENTS_STORE = 'CLEAR_ANNOUNCEMENTS_STORE'
export const CLEAR_COMMITTEE_LIST = 'CLEAR_COMMITTEE_LIST'
export const CLEAR_SELECTED_COMMITTEE_COUNTRIES = 'CLEAR_SELECTED_COMMITTEE_COUNTRIES'
export const CLEAR_USER_COMMITTEE_SELECTIONS = 'CLEAR_USER_COMMITTEE_SELECTIONS'
export const DELETE_ANNOUNCEMENT_LOCALLY = 'DELETE_ANNOUNCEMENT_LOCALLY'
export const MARK_COMMITTEES_AS_SELECTED = 'MARK_COMMITTEES_AS_SELECTED'
export const MARK_USER_COMMITTEES_AS_SELECTED = 'MARK_USER_COMMITTEES_AS_SELECTED'
export const PRESERVE_LIST_SELECTIONS = 'PRESERVE_LIST_SELECTIONS'
export const RESET_MEMBERS = 'RESET_MEMBERS'
export const SELECT_COMMITTEE_COUNTRIES = 'SELECT_COMMITTEE_COUNTRIES'
export const SELECT_COMMITTEE_MEMBERS = 'SELECT_COMMITTEE_MEMBERS'
export const SET_ADDABLE_COMMITTEES = 'SET_ADDABLE_COMMITTEES'
export const SET_ALL_CAMP_LEADERS = 'SET_ALL_CAMP_LEADERS'
export const SET_ALL_STATE_LEADERS = 'SET_ALL_STATE_LEADERS'
export const SET_ALL_USER_COMMITTEES = 'SET_ALL_USER_COMMITTEES'
export const SET_ANNOUNCEMENT_FOR_COMMITTEE = 'SET_ANNOUNCEMENT_FOR_COMMITTEE'
export const SET_ANNOUNCEMENTS = 'SET_ANNOUNCEMENTS'
export const SET_ANNOUNCEMENTS_FOR_CAMP = 'SET_ANNOUNCEMENTS_FOR_CAMP'
export const SET_ANNOUNCEMENTS_FOR_EDIT = 'SET_ANNOUNCEMENTS_FOR_EDIT'
export const SET_AUDIENCE_FOR_EDIT = 'SET_AUDIENCE_FOR_EDIT'
export const SET_BOTH_STATE_MANAGEMENT = 'SET_BOTH_STATE_MANAGEMENT'
export const SET_CAMP_AUXILIARY_LEADERS = 'SET_CAMP_AUXILIARY_LEADERS'
export const SET_CAMP_GIDEON_LEADERS = 'SET_CAMP_GIDEON_LEADERS'
export const SET_COMMITTEE_COUNTRIES = 'SET_COMMITTEE_COUNTRIES'
export const SET_COMMITTEE_MEMBERS = 'SET_COMMITTEE_MEMBERS'
export const SET_COUNTRIES = 'SET_COUNTRIES'
export const SET_FORM = 'SET_FORM'
export const SET_INCLUDE_SWITCH = 'SET_INCLUDE_SWITCH'
export const SET_INDIVIDUALS = 'SET_INDIVIDUALS'
export const SET_LAST_UPDATED = 'SET_LAST_UPDATED'
export const SET_MEMBERS = 'SET_MEMBERS'
export const SET_MEMBERS_IN_COMMITTEES_LIST = 'SET_MEMBERS_IN_COMMITTEES_LIST'
export const SET_MESSAGE_PACKAGE_AUDIENCE = 'SET_MESSAGE_PACKAGE_AUDIENCE'
export const SET_MESSAGE_PACKAGE_FOR_DELETE = 'SET_MESSAGE_PACKAGE_FOR_DELETE'
export const SET_MESSAGE_PACKAGE_GEOGRAPHY = 'SET_MESSAGE_PACKAGE_GEOGRAPHY'
export const SET_MESSAGE_PACKAGE_MESSAGE = 'SET_MESSAGE_PACKAGE_MESSAGE'
export const SET_MESSAGE_PACKAGE_MESSAGE_INCLUDE = 'SET_MESSAGE_PACKAGE_MESSAGE_INCLUDE'
export const SET_REGIONAL_COMMITTEES = 'SET_REGIONAL_COMMITTEES'
export const SET_REGIONAL_COMMITTEE_KEY = 'SET_REGIONAL_COMMITTEE_KEY'
export const SET_SELECTED_COMMITTEES_MEMBERS = 'SET_SELECTED_COMMITTEES_MEMBERS'
export const SET_STATE_AUXILIARY_LEADERS = 'SET_STATE_AUXILIARY_LEADERS'
export const SET_STATE_AUXILIARY_MANAGEMENT = 'SET_STATE_AUXILIARY_MANAGEMENT'
export const SET_STATE_GIDEON_LEADERS = 'SET_STATE_GIDEON_LEADERS'
export const SET_STATE_GIDEON_MANAGEMENT = 'SET_STATE_GIDEON_MANAGEMENT'
export const SET_STATE_REGIONS = 'SET_STATE_REGIONS'
export const SET_STATE_REGIONS_FOR_EDIT = 'SET_STATE_REGIONS_FOR_EDIT'
export const SET_STATE_REGION_AREA = 'SET_STATE_REGION_AREA'
export const SET_THE_ANNOUNCEMENT_FOR_EDIT = 'SET_THE_ANNOUNCEMENT_FOR_EDIT'
export const SET_USER_COMMITTEES = 'SET_USER_COMMITTEES'
export const SET_USER_COMMITTEES_FOR_EDIT = 'SET_USER_COMMITTEES_FOR_EDIT'
export const SET_ANNOUNCEMENT_FOR_STATE = 'SET_ANNOUNCEMENT_FOR_STATE'

export const mutations = {
  [SET_ANNOUNCEMENTS](state, data) {
    state.announcements = data
    data.forEach(an => {
      if (!!an.filter) {
        try {
          an.filter = an.filter.toLowerCase()
          an.filter = an.filter.split(',')
        } catch (e) { }
      }
    })
  },
  [SET_ANNOUNCEMENTS_FOR_CAMP](state, data) {
    state.announcements = data
  },
  [SET_ANNOUNCEMENTS_FOR_EDIT](state, data) {
    state.announcements_for_edit = data
  },
  [SET_LAST_UPDATED](state, data) {
    state.last_updated = data
  },
  [SET_USER_COMMITTEES](state, data) {
    state.user_committees = data
  },
  [SET_USER_COMMITTEES_FOR_EDIT](state, data) {
    state.user_committees.title = 'Committees'
    state.user_committees.areas = data.map(duc => {
      return {
        all: 0,
        text: duc.name,
        selected: true,
        value: duc.key
      }
    })
  },
  [SET_INCLUDE_SWITCH](state, data) {
    // state.announcement_form does not exist when creating a new announcement, so we get an undefined error
    // state.announcement_form.includeElected = data === ('1' || true)
  },
  [SET_ANNOUNCEMENT_FOR_COMMITTEE](state, data) {
    state.announcement_for_committee = data.length > 0 ? data[0] : null
  },
  [SET_ANNOUNCEMENT_FOR_STATE](state, data) {
    state.announcement_for_state = data.length > 0 ? data[0] : null
  },
  [SET_THE_ANNOUNCEMENT_FOR_EDIT](state, data) {
    state.announcement_for_edit = data
    state.announcement_form.editing = true
    state.announcement_form.title = data.find(ft => ft.type === 'title').name
    state.announcement_form.message = data.find(ft => ft.type === 'msg').name
    state.announcement_form.msg_message_id = data.find(ft => ft.type === 'msg').key
    state.announcement_form.tempFileArray = []
    state.announcement_form.fileArray = []
    let attach = data.filter(fa => fa.type === 'att')
    if (!!attach && attach.length > 0) {
      state.announcement_form.fileArray = attach.map(fa => {
        return {
          name: fa.name.replace(attachment_prefix, ''),
          key: fa.key
        }
      })
    }
    let [_member_type, _include_elected, _notification, _expiration, ...rest] = data
      .find(fo => fo.type === 'other')
      .name.split('/')
    let [_month, _day, _year] = _expiration.split('-')
    state.announcement_form.expires = new Date(_year, parseInt(_month, 10) - 1, _day)
    state.announcement_form.notification = _notification === '1'
    state.announcement_form.includeElected = _include_elected === '1'
    state.announcement_form.msg_member_type = _member_type
    let stateKey = data.find(ft => ft.type === 'state')
    if (!!stateKey) {
      state.announcement_form.state_key = stateKey.key
    }
    state.announcement_form.type = data
      .filter(ft => ft.type === 'type')
      .map(fm => {
        return fm.name
      })
    let af_region = data.find(sf => sf.type === 'region')
    state.announcement_form.region = !!af_region ? af_region.key.toLowerCase() : null
    state.announcement_form.aud_array = data.filter(sf => sf.type === 'aud').map(sf => sf.key.toLowerCase()) || []
    state.announcement_form.geo_array = data
      .filter(sf => sf.type === 'geo')
      .map(sf => {
        return sf
      })
    state.announcement_form.mbr_array =
      data
        .filter(mb => mb.type === 'mbr')
        .map(sb => {
          return sb
        }) || []
  },
  [SET_AUDIENCE_FOR_EDIT](state, getters) {
    let selected_group = []
    try {
      if (!!state.announcement_form.type) {
        state.announcement_form.type.map(dm => {
          switch (dm) {
            case 'AreasCamp':
            case 'FullCamp':
            case 'RCommitteeCamp':
            case 'ZonesCamp':
              selected_group = state.camp_offices
              break
            case 'AreasMembers':
            case 'CampCamp':
            case 'FullMembers':
            case 'ZonesMembers':
              selected_group = state.members
              break
            case 'CommitteesMembers':
            case 'RCommitteeRCMember':
              selected_group = {
                key: empty_guid,
                title: 'Committee Members',
                areas: [...state.individuals]
              }
              break
            case 'FullState':
              selected_group = state.state_leaders
              break
            case 'AreasManagement':
            case 'ZonesManagement':
            case 'RCommitteeManagement':
              selected_group = getters.committeesListWithAllSelected
              break
            case 'RCommitteeMember':
          }
          selected_group.map(co => {
            co.areas.map(coa => {
              coa.selected = state.announcement_form.aud_array.indexOf(coa.value.toLowerCase()) > -1
            })
          })
        })
      }
    } catch (e) {
      //  this will occur if type is not an array
    }
  },
  [SET_FORM](state, data) {
    state.announcement_form = data
  },
  [SET_STATE_REGIONS](state, data) {
    state.state_regions = data
  },
  [SET_STATE_REGIONS_FOR_EDIT](state) {
    let st_areas = state.announcement_for_edit.filter(sf => sf.type === 'geo').map(sf => sf.key)
    state.state_regions.map(sr => {
      sr.areas.map(sra => {
        sra.selected = st_areas.includes(sra.value)
      })
    })
  },
  [DELETE_ANNOUNCEMENT_LOCALLY](state, data) {
    let ix = state.announcements.findIndex(ann => ann.msg_key === data)
    state.announcements.splice(ix, 1)
    let ex = state.announcements_for_edit.findIndex(ann => ann.msg_message_id === data)
    state.announcements_for_edit.splice(ex, 1)
  },
  [SET_REGIONAL_COMMITTEES](state, data) {
    state.regional_committees = data
  },
  [SET_REGIONAL_COMMITTEE_KEY](state, data) {
    state.regional_committee_key = data
  },
  [SET_STATE_REGION_AREA](state, data) {
    state.state_regions.find(ga => ga.key === data.key).areas = data.areas
  },
  [MARK_COMMITTEES_AS_SELECTED](state, data) {
    state.user_committees = data
  },
  [MARK_USER_COMMITTEES_AS_SELECTED](state) {
    let selectedKeys = state.announcement_form.mbr_array.map(member => {
      return member.key
    })
    if (!!state.user_committees && !!state.user_committees.areas) {
      state.user_committees.areas.forEach(uca => {
        if (selectedKeys.includes(uca.value)) {
          uca.selected = true
        }
      })
    }
  },
  [SET_CAMP_AUXILIARY_LEADERS](state, data) {
    state.camp_offices.find(ga => ga.key === data.key).areas = data.areas
  },
  [SET_CAMP_GIDEON_LEADERS](state, data) {
    state.camp_offices.find(ga => ga.key === data.key).areas = data.areas
  },
  [SET_COMMITTEE_MEMBERS](state, data) {
    state.committee_members = data
  },
  [SELECT_COMMITTEE_COUNTRIES](state, data) {
    state.countries.areas = state.countries.areas.map(ca => {
      return {
        all: ca.all,
        text: ca.text,
        value: ca.value,
        selected: data.includes(ca.value)
      }
    })
  },
  [SELECT_COMMITTEE_MEMBERS](state, data) {
    state.committee_members.areas = state.committee_members.areas.map(ca => {
      return {
        all: ca.all,
        text: ca.text,
        value: ca.value,
        selected: data.includes(ca.value)
      }
    })
  },
  [CLEAR_COMMITTEE_LIST](state) {
    state.committees_list = []
  },
  [CLEAR_USER_COMMITTEE_SELECTIONS](state) {
    if (!!state.user_committees && !!state.user_committees.areas) {
      state.user_committees.areas.map(ca => (ca.selected = false))
    }
  },
  [CLEAR_SELECTED_COMMITTEE_COUNTRIES](state) {
    if (!!state.countries && !!state.countries.areas) {
      state.countries.areas.map(ca => (ca.selected = false))
    }
  },
  [SET_MEMBERS_IN_COMMITTEES_LIST](state, data) {
    const uniqueFunction = uniqueByPropertyName('value')
    data.map(da => {
      da.areas = uniqueFunction(da.areas)
    })
    state.committees_list = data
  },
  [SET_MESSAGE_PACKAGE_AUDIENCE](state, data) {
    let aud_template = {
      ...state.mp_audience
    }
    aud_template.aud_distribution_audience_key = data.audience
    aud_template.aud_distribution_type = data.type
    aud_template.aud_add_user = data.user
    aud_template.aud_change_user = data.user
    aud_template.aud_ordinal = data.ordinal
    state.message_package.mp_audiences.push(aud_template)
  },
  [SET_MESSAGE_PACKAGE_GEOGRAPHY](state, data) {
    let geo_template = {
      ...state.mp_geographic
    }
    geo_template.geo_state_key = data.statekey
    geo_template.geo_distribution_location_key = data.location
    geo_template.geo_distribution_location_name = data.location_name
    geo_template.geo_distribution_location_all = data.location_all
    geo_template.geo_distribution_type = data.type
    geo_template.geo_add_user = data.user
    geo_template.geo_change_user = data.user
    geo_template.geo_ordinal = data.ordinal
    state.message_package.mp_geographics.push(geo_template)
  },
  [SET_MESSAGE_PACKAGE_MESSAGE](state, data) {
    state.message_package.mp_message.msg_message_id = state.announcement_form.msg_message_id || empty_guid
    state.message_package.mp_message.msg_expiration_date = state.announcement_form.expires
    state.message_package.mp_message.msg_title = state.announcement_form.title
    state.message_package.mp_message.msg_message = state.announcement_form.message
    state.message_package.mp_message.msg_notification_flag = state.announcement_form.notification
    state.message_package.mp_message.msg_include_elected_flag = state.announcement_form.includeElected
    state.message_package.mp_message.msg_member_type = state.announcement_form.msg_member_type || 'b'
    state.message_package.mp_message.msg_add_user = data.user
    state.message_package.mp_message.msg_change_user = data.user
    state.message_package.mp_message.msg_sender_name = data.sendername
    state.message_package.mp_message.msg_sender_email = data.senderemail
    state.message_package.mp_message.msg_delete_flag = false
  },
  [SET_MESSAGE_PACKAGE_MESSAGE_INCLUDE](state, data) {
    state.message_package.mp_message.msg_include_elected_flag = data
  },
  [SET_MESSAGE_PACKAGE_FOR_DELETE](state, data) {
    state.message_package.mp_message.msg_message_id = data.msg_message_id
    state.message_package.mp_message.msg_expiration_date = new Date().toISOString()
    state.message_package.mp_message.msg_change_user = data.change_user
    state.message_package.mp_message.msg_delete_flag = true
  },
  [RESET_MEMBERS](state) {
    state.members.map(ag => {
      ag.areas[0].selected = true
    })
  },
  [SET_MEMBERS](state, data) {
    state.announcement_form.msg_member_type = data.substr(0, 1).toLowerCase()
    let bth = data === 'Both' ? 'Members' : data
    state.members.map(ag => {
      ag.areas[0].selected = ag.title.includes(bth)
    })
  },
  [SET_COMMITTEE_COUNTRIES](state, data) {
    state.countries = data
  },
  [SET_COUNTRIES](state, data) {
    state.countries.areas = data.areas
  },
  [SET_INDIVIDUALS](state, data) {
    let si = state.individuals.length > 0 && state.individuals[0].text === 'Members' ? [] : state.individuals
    si = [...si, ...data.areas.filter(ep => ep.selected === true)]
    const uniqueFunction = uniqueByPropertyName('value')
    state.individuals = uniqueFunction(si)
  },
  [SET_STATE_AUXILIARY_LEADERS](state, data) {
    state.state_leaders.find(ga => ga.key === data.key).areas = data.areas
  },
  [SET_STATE_GIDEON_LEADERS](state, data) {
    state.state_leaders.find(ga => ga.key === data.key).areas = data.areas
  },
  [SET_BOTH_STATE_MANAGEMENT](state, data) {
    state.management_teams.find(ga => ga.key === data.key).areas = data.areas
  },
  [SET_STATE_AUXILIARY_MANAGEMENT](state, data) {
    state.management_teams.find(ga => ga.key === data.key).areas = data.areas
  },
  [SET_STATE_GIDEON_MANAGEMENT](state, data) {
    state.management_teams.find(ga => ga.key === data.key).areas = data.areas
  },
  [SET_ALL_STATE_LEADERS](state, data) {
    state.state_leaders = data
  },
  [SET_ALL_CAMP_LEADERS](state, data) {
    state.camp_offices = data
  },
  [SET_ALL_USER_COMMITTEES](state, data) {
    state.user_committees = data
  },
  [SET_ADDABLE_COMMITTEES](state, data) {
    state.addable_committees = data
  },
  [SET_SELECTED_COMMITTEES_MEMBERS](state, data) {
    state.committee_members.find(ga => ga.key === data.key).areas = data.areas
  },
  [PRESERVE_LIST_SELECTIONS](state, data) {
    state.preserved_list_selections = data
  },
  [CLEAR_ANNOUNCEMENTS_STORE](state) {
    state.announcement_for_edit = []
    state.announcement_form = {
      aud_array: [],
      editing: false,
      expires: null,
      fileArray: [],
      geo_array: [],
      mbr_array: [],
      includeElected: false,
      message: null,
      msg_message_id: null,
      msg_member_type: 'b',
      notification: false,
      region: null,
      recipients: null,
      camp_key: null,
      state_key: null,
      country_key: null,
      tempFileArray: [],
      title: null,
      type: []
    }
    state.announcements = []
    state.announcements_for_edit = []
    state.camp_offices = []
    state.committee_members = {}
    state.committees_list = []
    state.countries = {}
    state.individuals = []
    state.regional_committee_key = null
    state.regional_committees = []
    state.selected_committee_keys = []
    state.state_leaders = []
    state.state_regions = []
    state.user_committees = {}
  },
  [APPLY_PRESERVED_LIST](state) {
    if (state.preserved_list_selections.length > -1) {
      state.committees_list.map(sc => {
        sc.areas.map(sca => {
          sca.selected = false
        })
      })
      state.preserved_list_selections.map(pls => {
        state.committees_list.map(scl => {
          let located_scl = scl.areas.find(cla => cla.value === pls.value)
          if (!!located_scl) {
            located_scl.selected = true
          }
        })
      })
    }
  }
}

export const actions = {
  async getRegionalCommittees({ commit }) {
    try {
      const response = await apiCalls.getRegionalCommittees()
      if (response && response.status === 200) {
        await commit('SET_REGIONAL_COMMITTEES', response.data)
        return true
      } else {
        throw 'bad response from getRegionalCommittees call'
      }
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async getRegionalCommitteeMembers({ commit, getters }) {
    try {
      await commit('SET_USER_COMMITTEES', null)
      const response = await apiCalls.getCountryZone(getters.regionalCommitteeKey, 2)
      if (response && response.status === 200) {
        await commit('SET_USER_COMMITTEES', response.data[0])
        return true
      } else {
        throw 'bad response from getRegionalCommitteeMembers call'
      }
    } catch {
      console.error(e)
      return false
    }
  },
  async getAnnouncementForCommittee({ commit }, pkg) {
    try {
      const response = await apiCalls.getAnnouncementForCommittee(pkg.cmt_key, pkg.ind_key)
      if (response && response.status === 200) {
        await commit('SET_ANNOUNCEMENT_FOR_COMMITTEE', response.data)
        return true
      } else {
        throw 'bad response from getAnnouncementForCommittee call'
      }
    } catch (e) {
      return false
    }
  },
  async getAnnouncementForState({ commit }, state_key) {
    try {
      const response = await apiCalls.getAnnouncementForState(state_key)
      if (response && response.status === 200) {
        await commit('SET_ANNOUNCEMENT_FOR_STATE', response.data)
        return true
      } else {
        throw 'bad response from getAnnouncementForState call'
      }
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async getAnnouncementsForCamp({ commit, rootGetters }) {
    try {
      const response = await apiCalls.getAnnouncementsForCamp(rootGetters['user/userCampKey'])
      if (response && response.status === 200) {
        await commit('SET_ANNOUNCEMENTS_FOR_CAMP', response.data)
        return true
      } else {
        throw 'bad response from getAnnouncementsForCamp call'
      }
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async getAnnouncementsForEdit({ commit }, pkg) {
    try {
      const response = await apiCalls.getAnnouncementsForEdit(pkg)
      if (response && response.status === 200) {
        await commit('SET_ANNOUNCEMENTS_FOR_EDIT', response.data)
        return true
      } else {
        throw 'bad response from getAnnouncementsForEdit call'
      }
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async setStateRegionsForEdit({ commit }) {
    await commit('SET_STATE_REGIONS_FOR_EDIT')
  },

  async setDataForEditingAnnouncement({ commit, dispatch }, msg_key) {
    try {
      await dispatch('getStateLeadership')
      await dispatch('getCampStateLeadership', 'Camp')
      const response = await apiCalls.getDataForEditingAnnouncement(msg_key)
      if (response && response.status === 200) {
        let isRegional = response.data.find(sf => sf.type === 'type' && sf.name.startsWith('RC'))
        if (!!isRegional) {
          let af_region = response.data.find(sf => sf.type === 'region')
          await dispatch('getCountryZoneCountries', af_region.key)
        }
        await commit('SET_THE_ANNOUNCEMENT_FOR_EDIT', response.data)
        return true
      } else {
        throw 'bad response from setDataForEditingAnnouncement call'
      }
    } catch (e) {
      console.error('something went wrong')
      console.error(e)
      return false
    }
  },
  async getAnnouncementMessages({ commit, rootGetters, state }, pkg) {
    try {
      if (!!rootGetters['user/userId']) {
        state.last_camp_key = rootGetters[`user/userCampKey`]
        state.last_state_key = rootGetters[`user/userStateKey`]
        const response = await apiCalls.getAnnouncementMessages({
          ind_key: rootGetters['user/userId'],
          state_key: rootGetters[`user/userStateKey`],
          cmp_key: (rootGetters[`user/userLogin`].campname !== rootGetters[`user/officerToolbarSelected`].camp_name) ? rootGetters[`user/officerToolbarSelected`].camp : null,
          ga_key: rootGetters['user/userMemberTypeKey'],
          lng_key: rootGetters['user/userLanguageKey'],
          quick: !!pkg ? pkg.quick : false,
          search_term: !!pkg ? pkg.searchterm : null,
          cnt_key: rootGetters[`user/userCountryKey`],
          camp_ok: !!pkg ? pkg.camp_ok : 0,
          state_ok: !!pkg ? pkg.state_ok : 0,
          country_ok: !!pkg ? pkg.country_ok : 0,
          committee_ok: !!pkg ? pkg.committee_ok : 0,
          ann_type: !!pkg ? pkg.ann_type : null
        })
        if (response && response.status === 200) {
          // Currently replacing all announcements, but could go back to doing some caching on frontend
          let sortedArray = [...response.data].sort((a, b) => {
            return new Date(b.sortdate) - new Date(a.sortdate)
          })
          await commit('SET_ANNOUNCEMENTS', sortedArray)
          return true
        } else {
          throw 'bad response from getAnnouncementMessages call'
        }
      }
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async deleteAnnouncementMessage({ dispatch, state }) {
    try {
      let formData = new FormData()
      formData = getFormData(state.message_package.mp_message, formData)
      const response = await apiCalls.deleteAnnouncementMessage(formData)
      if (response && response.status === 200) {
        dispatch('clearAnnouncementForm') //  clean up
        return true
      }
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async addUpdateAnnouncementMessage({ dispatch, state }, pkg) {
    try {
      let formData = new FormData()
      let mpm = state.message_package.mp_message
      mpm.msg_include_elected_flag = !!pkg.include ? pkg.include : false
      mpm.msg_member_type = !!pkg.membertype ? pkg.membertype : 'b'
      if (!!pkg.messageid) {
        mpm.msg_message_id = pkg.messageid
      }
      let retained_attachments = JSON.stringify(state.announcement_form.tempFileArray.map(att => att.key))
      mpm.msg_att_to_retain = retained_attachments

      formData = getFormData(mpm, formData)

      let geos_template = {
        ...state.message_package.mp_geographics[0]
      }
      let location_keys = JSON.stringify(
        state.message_package.mp_geographics.map(jg => jg.geo_distribution_location_key)
      )
      let location_names = JSON.stringify(
        state.message_package.mp_geographics.map(jn => jn.geo_distribution_location_name)
      )
      let location_alls = JSON.stringify(
        state.message_package.mp_geographics.map(jn => jn.geo_distribution_location_all)
      )

      geos_template.geo_distribution_location_key = location_keys
      geos_template.geo_distribution_location_name = location_names
      geos_template.geo_distribution_location_all = location_alls
      formData = getFormData(geos_template, formData)

      let auds_template = {
        ...state.message_package.mp_audiences[0]
      }
      let audience_keys = JSON.stringify([
        ...new Set(state.message_package.mp_audiences.map(ji => ji.aud_distribution_audience_key))
      ])
      let audience_types = JSON.stringify(state.message_package.mp_audiences.map(jj => jj.aud_distribution_type))

      auds_template.aud_distribution_audience_key = audience_keys
      auds_template.aud_distribution_type = audience_types
      formData = getFormData(auds_template, formData)
        ;[...state.announcement_form.fileArray, ...state.announcement_form.tempFileArray].map(fa => {
          formData.append(fa.name, fa)
        })

      const response = await apiCalls.addUpdateAnnouncementMessage(formData)
      if (response && response.status === 200) {
        await dispatch('getAnnouncementMessages')
        await dispatch('clearAnnouncementForm') //  clean up
        return true
      }
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async getStateRegionArea({ commit, rootGetters }, override_key) {
    const response = await apiCalls.getStateRegionArea(!!override_key ? override_key : rootGetters['user/userStateKey'])
    if (response && response.status === 200) {
      await commit('SET_STATE_REGIONS', response.data)
    }
  },
  async getAddableCommittees({ commit, rootGetters }) {
    await commit('SET_ADDABLE_COMMITTEES', {})
    const response = await apiCalls.getAddableCommittees(
      rootGetters['user/userId'],
      rootGetters['user/userLanguageKey']
    )
    if (response && response.status === 200) {
      let newAreas = []
      let userCommittees = rootGetters['user/userCommittees']
      userCommittees.areas.forEach(uc => {
        let ix = response.data.findIndex(ci => ci.cmt_key === uc.value)
        if (ix > -1 || uc.value === empty_guid) {
          newAreas.push(uc)
        }
      })
      newAreas = newAreas.sort((a, b) => (a.text > b.text ? 1 : -1))
      if (newAreas.length > 1) {
        await commit('SET_ADDABLE_COMMITTEES', {
          title: userCommittees.title,
          key: userCommittees.key,
          areas: newAreas
        })
      }
    }
  },
  async getCountryZones({ commit }, cnt_key) {
    const response = await apiCalls.getCountryZones(cnt_key)
    if (response && response.status === 200) {
      await commit('SET_STATE_REGIONS', response.data)
    }
  },
  async getCountryZoneCountries({ commit, state }, cmt_key) {
    await commit('SET_REGIONAL_COMMITTEE_KEY', cmt_key)
    const response = await apiCalls.getCountryZone(cmt_key, 0)
    if (response && response.status === 200) {
      await commit('SET_COMMITTEE_COUNTRIES', response.data[0])
      if (
        !!state.announcement_form &&
        !!state.announcement_form.geo_array &&
        state.announcement_form.geo_array.length > 0
      ) {
        await commit(
          'SELECT_COMMITTEE_COUNTRIES',
          state.announcement_form.geo_array.map(ka => ka.key)
        )
      }
    } else {
      return false
    }
  },
  async getCountryZoneMembers({ commit, state }, cmt_key) {
    await commit('SET_REGIONAL_COMMITTEE_KEY', cmt_key)
    const response = await apiCalls.getCountryZone(cmt_key, 1)
    if (response && response.status === 200) {
      await commit('SET_COMMITTEE_MEMBERS', response.data[0])
      if (!!state.announcement_form.mbr_array && state.announcement_form.mbr_array.length > 0) {
        await commit('SELECT_COMMITTEE_MEMBERS', state.announcement_form.mbr_array)
      }
    } else {
      return false
    }
  },
  async getCampStateLeadership({ commit }, camp_or_state) {
    const response = await apiCalls.getCampStateLeadership(camp_or_state)
    if (response && response.status === 200) {
      await commit(`SET_ALL_${camp_or_state.toUpperCase()}_LEADERS`, response.data)
    }
  },
  async getStateLeadership({ commit, rootGetters }, mpt_key) {
    const response = !!mpt_key
      ? await apiCalls.getStateLeadership(
        rootGetters['user/userStateKey'],
        rootGetters['user/userLanguageKey'],
        mpt_key
      )
      : await apiCalls.getStateLeadership(rootGetters['user/userStateKey'], rootGetters['user/userLanguageKey'])
    if (response && response.status === 200) {
      await commit(`SET_ALL_STATE_LEADERS`, response.data)
    }
  },
  async getCommitteeMembers({ commit, rootGetters }, cmt_key_list, override_user) {
    await commit(`CLEAR_COMMITTEE_LIST`)
    const response = await apiCalls.getCommitteeMembers(
      cmt_key_list,
      rootGetters['user/userLanguageKey'],
      !!override_user ? override_user : rootGetters['user/userId']
    )
    if (response && response.status === 200) {
      await commit(`SET_MEMBERS_IN_COMMITTEES_LIST`, response.data)
    }
  },
  async setUserCommitteesForEdit({ commit }, committee_list) {
    await commit(`SET_USER_COMMITTEES_FOR_EDIT`, committee_list)
  },
  async setAllUserCommittees({ commit, rootGetters }, regionalCommittees) {
    let non_regional_committees = []
    rootGetters['user/userCommittees'].areas.forEach(uc => {
      let ix = regionalCommittees.findIndex(rci => rci.text === uc.text)
      if (ix === -1) {
        non_regional_committees.push(uc)
      }
    })
    await commit('SET_ALL_USER_COMMITTEES', {
      key: empty_guid,
      title: 'All Committees',
      areas: non_regional_committees
    })
  },
  async clearAnnouncementForm({ commit, state }) {
    state.announcement_for_edit = []
    state.countries = {}
    state.individuals = []
    state.state_regions = []
    state.committee_members = {}
    state.selected_committee_keys = []
    state.message_package.mp_audiences = []
    state.message_package.mp_geographics = []
      ;[...(!!state.user_committees && state.user_committees.length > 0 ? state.user_committees : [])].map(gl => {
        gl.areas.map((gr, gx) => {
          gr.selected = gx === 0
        })
      })
      ;[...(state.management_teams.length > 0 ? state.management_teams : [])].map(gl => {
        gl.areas.map((gr, gx) => {
          gr.selected = gx === 0
        })
      })
      ;[...(state.committees_list.length > 0 ? state.committees_list : [])].map(gl => {
        gl.areas.map((gr, gx) => {
          gr.selected = gx === 0
        })
      })
      ;[...(state.state_leaders.length > 0 ? state.state_leaders : [])].map(gl => {
        gl.areas.map((gr, gx) => {
          gr.selected = false
        })
      })
      ;[...(state.state_regions.length > 0 ? state.state_regions : [])].map(gl => {
        gl.areas.map((gr, gx) => {
          gr.selected = gx === 0
        })
      })
      ;[...(state.camp_offices.length > 0 ? state.camp_offices : [])].map(gl => {
        gl.areas.map((gr, gx) => {
          gr.selected = gx === 0
        })
      })
    await commit('SET_FORM', {
      aud_array: [],
      editing: false,
      expires: null,
      fileArray: [],
      geo_array: [],
      mbr_array: [],
      includeElected: false,
      message: null,
      msg_message_id: null,
      msg_member_type: 'b',
      notification: true,
      region: null,
      recipients: null,
      camp_key: null,
      state_key: null,
      country_key: null,
      tempFileArray: [],
      title: null,
      type: []
    })
  },
  async deleteAnnouncementLocally({ commit }, payload) {
    await commit('DELETE_ANNOUNCEMENT_LOCALLY', payload)
  },
  async setAnnouncementForm({ commit }, payload) {
    await commit('SET_FORM', payload)
  },
  async resetMembers({ commit }, payload) {
    await commit('RESET_MEMBERS', payload)
  },
  async setMembers({ commit }, payload) {
    await commit('SET_MEMBERS', payload)
  },
  async setMessagePackageAudience({ commit }, payload) {
    await commit('SET_MESSAGE_PACKAGE_AUDIENCE', payload)
  },
  async setMessagePackageGeography({ commit }, payload) {
    await commit('SET_MESSAGE_PACKAGE_GEOGRAPHY', payload)
  },
  async setMessagePackageMessage({ commit }, payload) {
    await commit('SET_MESSAGE_PACKAGE_MESSAGE', payload)
  },
  async setMessagePackageMessageInclude({ commit, payload }) {
    await commit('SET_MESSAGE_PACKAGE_MESSAGE_INCLUDE', payload)
  },
  async setMessagePackageForDelete({ commit, rootGetters }, msgid) {
    await commit('SET_MESSAGE_PACKAGE_FOR_DELETE', {
      msg_message_id: msgid,
      change_user: rootGetters['user/userId']
    })
  },
  async applyPreservedList({ commit }) {
    await commit('APPLY_PRESERVED_LIST')
  },
  async preserveListSelections({ commit, getters }) {
    let selectedEarlier = getters.committeeIndividuals.areas
      .map(ci => {
        return {
          all: ci.all,
          value: ci.value,
          selected: ci.selected
        }
      })
      .filter(cj => cj.all === 0 && cj.selected === true)
    await commit('PRESERVE_LIST_SELECTIONS', selectedEarlier)
  },
  async setAudienceForEdit({ commit }) {
    await commit('SET_AUDIENCE_FOR_EDIT')
  },
  async setStateRegion({ commit }, payload) {
    await commit('SET_STATE_REGIONS', payload)
  },
  async setStateRegionArea({ commit }, payload) {
    await commit('SET_STATE_REGION_AREA', payload)
  },
  async setCampAuxiliaryLeaders({ commit }, payload) {
    await commit('SET_CAMP_AUXILIARY_LEADERS', payload)
  },
  async setCampGideonLeaders({ commit }, payload) {
    await commit('SET_CAMP_GIDEON_LEADERS', payload)
  },
  async setCountries({ commit }, payload) {
    await commit('SET_COUNTRIES', payload)
  },
  async setIncludeSwitch({ commit }, payload) {
    await commit('SET_INCLUDE_SWITCH', payload)
  },
  async setIndividuals({ commit }, payload) {
    await commit('SET_INDIVIDUALS', payload)
  },
  async setStateAuxiliaryLeaders({ commit }, payload) {
    await commit('SET_STATE_AUXILIARY_LEADERS', payload)
  },
  async setStateGideonLeaders({ commit }, payload) {
    await commit('SET_STATE_GIDEON_LEADERS', payload)
  },
  async setStateGideonManagement({ commit }, payload) {
    await commit('SET_STATE_GIDEON_MANAGEMENT', payload)
  },
  async setBothStateManagement({ commit }, payload) {
    await commit('SET_BOTH_STATE_MANAGEMENT', payload)
  },
  async setStateAuxiliaryManagement({ commit }, payload) {
    await commit('SET_STATE_AUXILIARY_MANAGEMENT', payload)
  },
  async setAllStateLeaders({ commit }, payload) {
    await commit('SET_ALL_STATE_LEADERS', payload)
  },
  async setAllCampLeaders({ commit }, payload) {
    await commit('SET_ALL_CAMP_LEADERS', payload)
  },
  async setUserCommittees({ commit }, payload) {
    await commit('SET_USER_COMMITTEES', payload)
  },
  async markUserCommitteesAsSelected({ commit }) {
    await commit('MARK_USER_COMMITTEES_AS_SELECTED')
  },
  async markCommitteesAsSelected({ commit }, payload) {
    await commit('MARK_COMMITTEES_AS_SELECTED', payload)
  },
  async clearSelectedCommitteeCountries({ commit }) {
    await commit(`CLEAR_SELECTED_COMMITTEE_COUNTRIES`)
  },
  async clearUserCommitteeSelections({ commit }) {
    await commit(`CLEAR_USER_COMMITTEE_SELECTIONS`)
  },
  async clearCommitteeList({ commit }) {
    await commit(`CLEAR_COMMITTEE_LIST`)
  },
  async clearAnnouncementsStore({ commit }) {
    await commit(`CLEAR_ANNOUNCEMENTS_STORE`)
  }
}

export const apiCalls = {
  getAddableCommittees(ind_key, lng_key) {
    const route = API_CONFIG.getAddableCommittees(ind_key, lng_key)
    return axios.get(route)
  },
  getAnnouncementForCommittee(cmt_key, ind_key) {
    const route = API_CONFIG.getAnnouncementForCommittee(cmt_key, ind_key)
    return axios.get(route)
  },
  getAnnouncementForState(state_key) {
    const route = API_CONFIG.getAnnouncementForState(state_key)
    return axios.get(route)
  },
  getAnnouncementsForCamp(org_key) {
    const route = API_CONFIG.getAnnouncementsForCamp(org_key)
    return axios.get(route)
  },
  getAnnouncementsForEdit(pkg) {
    const route = API_CONFIG.getAnnouncementsForEdit()
    return axios.post(route, pkg)
  },
  getDataForEditingAnnouncement(msg_key) {
    const route = API_CONFIG.getDataForEditingAnnouncement(msg_key)
    return axios.get(route)
  },
  getAnnouncementMessages(payload) {
    const route = API_CONFIG.getAnnouncementMessages()
    return axios.post(route, payload)
  },
  getStateRegionArea(state_key) {
    const route = MEMBER_API_CONFIG.getStateRegionArea(state_key)
    return axios.get(route)
  },
  getCountryZone(c_key, flag) {
    const route = MEMBER_API_CONFIG.getCountryZone(c_key, flag)
    return axios.get(route)
  },
  getCountryZones(c_key) {
    const route = MEMBER_API_CONFIG.getCountryZones(c_key)
    return axios.get(route)
  },
  getCampStateLeadership(camp_or_state) {
    const route = MEMBER_API_CONFIG.getCampStateLeadership(camp_or_state)
    return axios.get(route)
  },
  getCommitteeMembers(cmt_key_list, lng_key, ind_key) {
    const route = MEMBER_API_CONFIG.getCommitteeMembers(lng_key, ind_key)
    return axios.post(route, cmt_key_list)
  },
  getRegionalCommittees() {
    const route = API_CONFIG.getRegionalCommittees()
    return axios.get(route)
  },
  getStateLeadership(org_key, lng_key, mpt_key) {
    const route = MEMBER_API_CONFIG.getStateLeadership(org_key, lng_key, mpt_key)
    return axios.get(route)
  },
  deleteAnnouncementMessage(formdata) {
    const route = API_CONFIG.deleteAnnouncementMessage()
    return axios.post(route, formdata)
  },
  addUpdateAnnouncementMessage(formdata) {
    const route = API_CONFIG.addUpdateAnnouncementMessage()
    return axios.post(route, formdata)
  }
}

export const announcements = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
