const baseURL = process.env.VUE_APP_API_ROUTE_ADDRESS
const goalsEndpoints = [
  // Camp
  ['CampDashboard', (CAMP_KEY, LANG_KEY) => `${baseURL}/goal/camp/${CAMP_KEY}/dashboard?languageKey=${LANG_KEY}`],
  ['CampLockGoalsReport', (CAMP_KEY, FISCAL_YEAR) => `${baseURL}/goal/camp/${CAMP_KEY}/lockedreport/${FISCAL_YEAR}`],
  ['ChurchMinistryGoals', (CAMP_KEY, LANG_KEY) => `${baseURL}/church/goals/${CAMP_KEY}/${LANG_KEY}`],
  ['CampGoalsLockOrUnlockUpdate', () => `${baseURL}/goal/camp/lockGoals`],
  [
    'GoalsGet[ChurchMinistryAndPresentations]',
    (CAMP_KEY, LANG_KEY) => `${baseURL}/church/goals/${CAMP_KEY}/${LANG_KEY}`
  ],
  ['GoalsUpdate[ChurchMinistryAndPresentations]', () => `${baseURL}/church/goals/upsert`],
  ['GoalsGet[CampDesignated]', (CAMP_KEY, LANG_KEY) => `${baseURL}/campdesignated/goals/${CAMP_KEY}/${LANG_KEY}`],
  ['GoalsUpdate[CampDesignated]', () => `${baseURL}/campdesignated/goals/upsert`],
  ['GoalsGet[GideonCardBible]', (CAMP_KEY, LANG_KEY) => `${baseURL}/gideoncardbible/goals/${CAMP_KEY}/${LANG_KEY}`],
  ['GoalsUpdate[GideonCardBible]', () => `${baseURL}/gideoncardbible/goals/upsert`],
  ['GoalsGet[AuxiliaryScripture]', (CAMP_KEY, LANG_KEY) => `${baseURL}/auxiliary/goals/${CAMP_KEY}/${LANG_KEY}`],
  ['GoalsGet[Placements]', (CAMP_KEY, LANG_KEY) => `${baseURL}/placements/goals/${CAMP_KEY}/${LANG_KEY}`],
  ['GoalsUpdate[Placements]', () => `${baseURL}/placements/goals/upsert`],
  ['GoalsUpdate[AuxiliaryScripture]', () => `${baseURL}/auxiliary/goals/upsert`],
  ['GoalsGet[PWTs]', (CAMP_KEY, LANG_KEY) => `${baseURL}/pwt/goals/${CAMP_KEY}/${LANG_KEY}`],
  ['GoalsUpdate[PWTs]', () => `${baseURL}/pwt/goals/upsert`],
  ['GoalsGet[Membership]', (CAMP_KEY, LANG_KEY) => `${baseURL}/membership/goals/${CAMP_KEY}/${LANG_KEY}`],
  ['GoalsUpdate[Membership]', () => `${baseURL}/membership/goals/upsert`],
  [
    'CustomizeMAGet[ChurchMinistryAndPresentations]',
    (CAMP_KEY, LANG_KEY) => `${baseURL}/church/goals/monthlyAllocation/${CAMP_KEY}/${LANG_KEY}`
  ],
  ['CustomizeMAUpdate[ChurchMinistryAndPresentations]', () => `${baseURL}/church/goals/monthlyAllocation/customize`],
  [
    'CustomizeMAGet[GideonCard]',
    (CAMP_KEY, LANG_KEY) => `${baseURL}/gideoncardbible/goals/monthlyAllocation/${CAMP_KEY}/${LANG_KEY}`
  ],
  ['CustomizeMAUpdate[GideonCard]', () => `${baseURL}/gideoncardbible/goals/monthlyAllocation/customize`],
  [
    'CustomizeMAGet[AuxiliaryScripture]',
    (CAMP_KEY, LANG_KEY) => `${baseURL}/auxiliary/goals/monthlyAllocation/${CAMP_KEY}/${LANG_KEY}`
  ],
  ['CustomizeMAUpdate[AuxiliaryScripture]', () => `${baseURL}/auxiliary/goals/monthlyAllocation/customize`],
  [
    'CustomizeMAGet[Placements]',
    (CAMP_KEY, LANG_KEY, SHORT_CODE) =>
      `${baseURL}/placements/goals/monthlyAllocation/${CAMP_KEY}/${LANG_KEY}/${SHORT_CODE}`
  ],
  ['CustomizeMAUpdate[Placements]', () => `${baseURL}/placements/goals/monthlyAllocation/customize`],
  [
    'CustomizeMAGet[PWTs]',
    (CAMP_KEY, LANG_KEY, SHORT_CODE) => `${baseURL}/pwt/goals/monthlyAllocation/${CAMP_KEY}/${LANG_KEY}/${SHORT_CODE}`
  ],
  ['CustomizeMAUpdate[PWTs]', () => `${baseURL}/pwt/goals/monthlyAllocation/customize`],
  ['CampGoalsProgressStatusGet', CAMP_KEY => `${baseURL}/camp/goal/progress/${CAMP_KEY}`],
  ['GoalsGet[FaithFund]', (CAMP_KEY, LANG_KEY) => `${baseURL}/faithfund/goals/${CAMP_KEY}/${LANG_KEY}`],
  ['GoalsUpdate[FaithFund]', () => `${baseURL}/faithfund/goals/upsert`],
  [
    'CustomizeMAGet[FaithFund]',
    (CAMP_KEY, LANG_KEY) => `${baseURL}/faithfund/goals/monthlyAllocation/${CAMP_KEY}/${LANG_KEY}`
  ],
  ['CustomizeMAUpdate[FaithFund]', () => `${baseURL}/faithfund/goals/monthlyAllocation/customize`],

  // State
  ['StateDashboard', STATE_KEY => `${baseURL}/goal/state/${STATE_KEY}/dashboard`],
  [
    'StateCampsDetails',
    (STATE_KEY, REGION, AREA, CATEGORY) =>
      `${baseURL}/goal/state/${STATE_KEY}/camps?region=${REGION}&area=${AREA}&category=${CATEGORY}`
  ],
  [
    'StateGoalsReport',
    (STATE_KEY, CSV_TYPE, FISCAL_YEAR) => `${baseURL}/goal/state/${STATE_KEY}/report/${CSV_TYPE}/${FISCAL_YEAR}`
  ],
  [
    'CampStatusReportForState',
    (STATE_KEY, FISCAL_YEAR) => `${baseURL}/goal/state/${STATE_KEY}/lockedreport/${FISCAL_YEAR}`
  ],
  ['StateLockUnlockCampGoals', () => `${baseURL}/goal/state/lockCampGoals`],
  [
    'GetStateQuickCampGoalPlanning',
    (STATE_KEY, CAMP_KEY) => `${baseURL}/goal/state/${STATE_KEY}/camp/${CAMP_KEY}/goals`
  ],
  ['SaveStateQuickCampGoalPlanning', () => `${baseURL}/goal/state/upsertCampGoals`],
  ['LockState', STATE_KEY => `${baseURL}/goal/state/${STATE_KEY}/lock`],
  ['SaveStateCampGoalByCategory', () => `${baseURL}/goal/state/campGoal`],
  ['RunStateTargetGoal', () => `${baseURL}/goal/state/runtargetgoal`],
  ['GoalsGet[FaithFund]', (CAMP_KEY, LANG_KEY) => `${baseURL}/faithfund/goals/${CAMP_KEY}/${LANG_KEY}`],
  ['GoalsUpdate[FaithFund]', () => `${baseURL}/faithfund/goals/upsert`],
  [
    'CustomizeMAGet[FaithFund]',
    (CAMP_KEY, LANG_KEY) => `${baseURL}/faithfund/goals/monthlyAllocation/${CAMP_KEY}/${LANG_KEY}`
  ],
  ['CustomizeMAUpdate[FaithFund]', () => `${baseURL}/faithfund/goals/monthlyAllocation/customize`],
  ['StateCSVUpload', (STATE_KEY, IND_KEY) => `${baseURL}/goal/state/csvUpload/${STATE_KEY}/${IND_KEY}`]
]

export const GET_GOAL_API_ENDPOINT = endpointName => new Map(goalsEndpoints).get(endpointName)
