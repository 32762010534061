/*eslint-disable*/
import axiosErrorHandler from '../axiosErrorHandler.js'
import { GET_GOAL_API_ENDPOINT } from '@/config/goals.api.config.js'

export default class StepperService {
  static async getCampGoalsProgress(CAMP_KEY, LANG_KEY) {
    const URL = GET_GOAL_API_ENDPOINT('CampGoalsProgressStatusGet')(CAMP_KEY, LANG_KEY)
    const response = await axiosErrorHandler({
      endpoint: URL,
      apiType: 'get'
    })
    return response
  }
}
