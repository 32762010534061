/* eslint-disable */
import axios from 'axios'
import { API_CONFIG } from '../config/api.config.js'
import { REPORT_CONFIG } from '../config/report.config.js'
import { SCRIPTURE_API_CONFIG } from '../config/scripture.api.config.js'
import constantData from '@/json/data.json'
const ANNUAL_DINNER_MEMBERSHIP_SPEAKER_TYPE = 'membership_dinner_speakers'
const PASTOR_BANQUET_SPEAKER = 'pastor_banquet_speaker'
export const state = () => ({
  campOfficerPositions: [],
  campOfficerSelections: [],
  dinnerSpeakers: [],
  individualTestimonyType: '',
  membersList: [],
  pastorBanquetSpeakers: [],
  qualifiedSpeakers: [],
  scriptureBlitzSpeakers: [],
  speakerObject: {},
  stateCamps: [],
  stateMemberListReportCSV: '',
  stateMemberListReportPDF: '',
  testimonySpeakers: [],
  testimonyTypes: [],
  widowsList: [],
  widowsReportCsv: '',
  dinnerSpeakersCSVUrl: '',
  pastorBanquetSpeakersCSVUrl: '',
  campOfficerSelectionsCSVUrl: '',
  qualifiedChurchSpeakersCSVUrl: '',
  scriptureBlitzSpeakersCSVUrl: '',
  stateCampsCSVUrl: '',
  testimonySpeakersCSVUrl: ''
})

export const getters = {
  campOfficerPositions: state => {
    return state.campOfficerPositions
  },
  campOfficerSelections: state => {
    return state.campOfficerSelections
  },
  dinnerSpeakers: state => {
    return state.dinnerSpeakers
  },
  individualTestimonyType: state => {
    return state.individualTestimonyType
  },
  membersList: state => {
    return state.membersList
  },
  pastorBanquetSpeakers: state => {
    return state.pastorBanquetSpeakers
  },
  qualifiedSpeakers: state => {
    return state.qualifiedSpeakers
  },
  scriptureBlitzSpeakers: state => {
    return state.scriptureBlitzSpeakers
  },
  speakerObject: state => {
    return state.speakerObject
  },
  stateCamps: state => {
    return state.stateCamps
  },
  stateMemberListReportCSV: state => {
    return state.stateMemberListReportCSV
  },
  stateMemberListReportPDF: state => {
    return state.stateMemberListReportPDF
  },
  testimonySpeakers: state => {
    return state.testimonySpeakers
  },
  testimonyTypes: state => {
    return state.testimonyTypes
  },
  widowsList: state => {
    return state.widowsList
  },
  widowsReportCsv: state => {
    return state.widowsReportCsv
  },
  dinnerSpeakersCSVUrl: state => {
    return state.dinnerSpeakersCSVUrl
  },
  pastorBanquetSpeakersCSVUrl: state => {
    return state.pastorBanquetSpeakersCSVUrl
  },
  campOfficerSelectionsCSVUrl: state => {
    return state.campOfficerSelectionsCSVUrl
  },
  qualifiedChurchSpeakersCSVUrl: state => {
    return state.qualifiedChurchSpeakersCSVUrl
  },
  scriptureBlitzSpeakersCSVUrl: state => {
    return state.scriptureBlitzSpeakersCSVUrl
  },
  stateCampsCSVUrl: state => {
    return state.stateCampsCSVUrl
  },
  testimonySpeakersCSVUrl: state => {
    return state.testimonySpeakersCSVUrl
  }
}

export const SET_DINNER_SPEAKERS = 'SET_DINNER_SPEAKERS'
export const SET_PASTOR_BANQUET_SPEAKERS = 'SET_PASTOR_BANQUET_SPEAKERS'
export const SET_QUALIFIED_SPEAKERS = 'SET_QUALIFIED_SPEAKERS'
export const SET_CAMP_OFFICER_POSITIONS = 'SET_CAMP_OFFICER_POSITIONS'
export const SET_CAMP_OFFICER_SELECTIONS = 'SET_CAMP_OFFICER_SELECTIONS'
export const SET_SCRIPTURE_BLITZ_SPEAKERS = 'SET_SCRIPTURE_BLITZ_SPEAKERS'
export const SET_SPEAKER_OBJECT = 'SET_SPEAKER_OBJECT'
export const SET_STATE_CAMP = 'SET_STATE_CAMP'
export const SET_STATE_MEMBER_LIST_CSV = 'SET_STATE_MEMBER_LIST_CSV'
export const SET_STATE_MEMBER_LIST_PDF = 'SET_STATE_MEMBER_LIST_PDF'
export const SET_MEMBERS_LIST = 'SET_MEMBERS_LIST'
export const SET_WIDOWS_LIST = 'SET_WIDOWS_LIST'
export const SET_TESTIMONY_SPEAKERS = 'SET_TESTIMONY_SPEAKERS'
export const SET_INDIVIDUAL_TESTIMONY_TYPE = 'SET_INDIVIDUAL_TESTIMONY_TYPE'
export const SET_TESTIMONY_TYPES = 'SET_TESTIMONY_TYPES'
export const SET_WIDOWS_REPORT_CSV = 'SET_WIDOWS_REPORT_CSV'
export const SET_DINNER_SPEAKERS_CSV = 'SET_DINNER_SPEAKERS_CSV'
export const SET_PASTOR_BANQUET_SPEAKERS_CSV = 'SET_PASTOR_BANQUET_SPEAKERS_CSV'
export const SET_CAMP_OFFICER_SELECTIONS_CSV = 'SET_CAMP_OFFICER_SELECTIONS_CSV'
export const SET_QUALIFIED_CHURCH_SPEAKERS_CSV = 'SET_QUALIFIED_CHURCH_SPEAKERS_CSV'
export const SET_SCRIPTURE_BLITZ_SPEAKERS_CSV = 'SET_SCRIPTURE_BLITZ_SPEAKERS_CSV'
export const SET_STATE_CAMPS_CSV = 'SET_STATE_CAMPS_CSV'
export const SET_TESTIMONY_SPEAKERS_CSV = 'SET_TESTIMONY_SPEAKERS_CSV'

export const mutations = {
  [SET_DINNER_SPEAKERS](state, data) {
    state.dinnerSpeakers = data
  },
  [SET_PASTOR_BANQUET_SPEAKERS](state, data) {
    state.pastorBanquetSpeakers = data
  },
  [SET_QUALIFIED_SPEAKERS](state, data) {
    state.qualifiedSpeakers = data
  },
  [SET_CAMP_OFFICER_POSITIONS](state, data) {
    state.campOfficerPositions = data
  },
  [SET_CAMP_OFFICER_SELECTIONS](state, data) {
    state.campOfficerSelections = data
  },
  [SET_SCRIPTURE_BLITZ_SPEAKERS](state, data) {
    state.scriptureBlitzSpeakers = data
  },
  [SET_SPEAKER_OBJECT](state, data) {
    state.speakerObject = data
  },
  [SET_STATE_CAMP](state, data) {
    state.stateCamps = data
  },
  [SET_STATE_MEMBER_LIST_CSV](state, data) {
    state.stateMemberListReportCSV = data
  },
  [SET_STATE_MEMBER_LIST_PDF](state, data) {
    state.stateMemberListReportPDF = data
  },
  [SET_MEMBERS_LIST](state, data) {
    state.membersList = data
  },
  [SET_WIDOWS_LIST](state, data) {
    state.widowsList = data
  },
  [SET_WIDOWS_REPORT_CSV](state, data) {
    state.widowsReportCsv = data
  },
  [SET_TESTIMONY_SPEAKERS](state, data) {
    state.testimonySpeakers = data
  },
  [SET_TESTIMONY_TYPES](state, data) {
    state.testimonyTypes = data
  },
  [SET_INDIVIDUAL_TESTIMONY_TYPE](state, data) {
    state.individualTestimonyType = data
  },
  [SET_DINNER_SPEAKERS_CSV](state, data) {
    state.dinnerSpeakersCSVUrl = data
  },
  [SET_PASTOR_BANQUET_SPEAKERS_CSV](state, data) {
    state.pastorBanquetSpeakersCSVUrl = data
  },
  [SET_CAMP_OFFICER_SELECTIONS_CSV](state, data) {
    state.campOfficerSelectionsCSVUrl = data
  },
  [SET_QUALIFIED_CHURCH_SPEAKERS_CSV](state, data) {
    state.qualifiedChurchSpeakersCSVUrl = data
  },
  [SET_SCRIPTURE_BLITZ_SPEAKERS_CSV](state, data) {
    state.scriptureBlitzSpeakersCSVUrl = data
  },
  [SET_STATE_CAMPS_CSV](state, data) {
    state.stateCampsCSVUrl = data
  },
  [SET_TESTIMONY_SPEAKERS_CSV](state, data) {
    state.testimonySpeakersCSVUrl = data
  }
}

export const actions = {
  async getDinnerSpeakers({ commit, rootGetters }, org_key) {
    let langKey = rootGetters['user/userLanguageKey']
    const response = await apiCalls.getACRSpeakers(org_key, langKey, ANNUAL_DINNER_MEMBERSHIP_SPEAKER_TYPE)
    if (response && response.status === 200) {
      await commit('SET_DINNER_SPEAKERS', response.data)
    }
  },
  async upsertDinnerSpeakers({ commit, rootGetters }, data) {
    const response = await apiCalls.upsertACRSpeakers(ANNUAL_DINNER_MEMBERSHIP_SPEAKER_TYPE, data)
    if (response && response.status === 200) {
      return response.data
    }
    return false
  },
  async getPastorBanquetSpeakers({ commit, rootGetters }, org_key) {
    let langKey = rootGetters['user/userLanguageKey']
    const response = await apiCalls.getACRSpeakers(org_key, langKey, PASTOR_BANQUET_SPEAKER)
    if (response && response.status === 200) {
      await commit('SET_PASTOR_BANQUET_SPEAKERS', response.data)
    }
  },
  async upsertPastorBanquetSpeakers({ commit, rootGetters }, data) {
    const response = await apiCalls.upsertACRSpeakers(PASTOR_BANQUET_SPEAKER, data)
    if (response && response.status === 200) {
      return response.data
    }
    return false
  },
  async getQualifiedSpeakers({ commit, rootGetters }, org_key) {
    let langKey = rootGetters['user/userLanguageKey']
    const response = await apiCalls.getQualifiedSpeakers(org_key, langKey)
    if (response && response.status === 200) {
      await commit('SET_QUALIFIED_SPEAKERS', response.data)
    }
  },
  async getCampOfficerPositions({ commit, rootGetters }, org_key) {
    let langKey = rootGetters['user/userLanguageKey']
    const response = await apiCalls.getCampOfficerPositions(org_key, langKey)
    if (response && response.status === 200) {
      await commit('SET_CAMP_OFFICER_POSITIONS', response.data)
    }
  },
  async getCampOfficerSelections({ commit, rootGetters }, params) {
    let langKey = rootGetters['user/userLanguageKey']
    const response = await apiCalls.getCampOfficerSelections(params.org_key, params.ctp_key, langKey)
    if (response && response.status === 200) {
      await commit('SET_CAMP_OFFICER_SELECTIONS', response.data)
    }
  },
  async getScriptureBlitzSpeakers({ commit, rootGetters }, org_key) {
    let langKey = rootGetters['user/userLanguageKey']
    const response = await apiCalls.getScriptureBlitzSpeakers(org_key, langKey)
    if (response && response.status === 200) {
      await commit('SET_SCRIPTURE_BLITZ_SPEAKERS', response.data)
    }
  },
  async getStateCamp({ commit, rootGetters }, org_key) {
    let langKey = rootGetters['user/userLanguageKey']
    const response = await apiCalls.getStateCamp(org_key, langKey)
    if (response && response.status === 200) {
      await commit('SET_STATE_CAMP', response.data)
    }
  },
  async loadStateMemberListReportUrls({ commit, rootGetters }, state_key) {
    let csvURL = reportCalls.getStateMemberListReportCSV(state_key, rootGetters['user/userLanguageKey'])
    let pdfURL = reportCalls.getStateMemberListReportPDF(state_key, rootGetters['user/userLanguageKey'])
    await Promise.all([commit('SET_STATE_MEMBER_LIST_CSV', csvURL), commit('SET_STATE_MEMBER_LIST_PDF', pdfURL)])
    return true
  },
  async getMembersList({ commit, rootGetters }, org_key) {
    let langKey = rootGetters['user/userLanguageKey']
    const response = await apiCalls.getMembersList(org_key, langKey)
    if (response && response.status === 200) {
      await commit('SET_MEMBERS_LIST', response.data)
    }
  },
  async getWidowsList({ commit, rootGetters }, org_key) {
    const langKey = rootGetters['user/userLanguageKey']
    const indKey = rootGetters['user/userId']
    const response = await apiCalls.getWidowsList(org_key, langKey)
    if (response && response.status === 200) {
      commit(SET_WIDOWS_LIST, response.data)
      const csvUrl = reportCalls.getWidowListReportCsv(org_key, langKey, indKey)
      commit(SET_WIDOWS_REPORT_CSV, csvUrl)
    }
  },
  async getTestimonySpeakers({ commit, rootGetters }, org_key) {
    let langKey = rootGetters['user/userLanguageKey']
    const response = await apiCalls.getTestimonySpeakers(org_key, langKey)
    if (response && response.status === 200) {
      await commit('SET_TESTIMONY_SPEAKERS', response.data)
    }
  },
  async getTestimonyTypes({ commit, rootGetters }) {
    let langKey = rootGetters['user/userLanguageKey']
    const response = await apiCalls.getTestimonyTypes(langKey)
    if (response && response.status === 200) {
      await commit('SET_TESTIMONY_TYPES', response.data)
    }
  },
  async getIndividualTestimonyType({ commit, rootGetters }, ind_key) {
    const response = await apiCalls.getIndividualTestimonyType(ind_key)
    if (response && response.status === 200) {
      await commit('SET_INDIVIDUAL_TESTIMONY_TYPE', response.data)
    }
  },
  async setSpeakerObject({ commit }, data) {
    await commit('SET_SPEAKER_OBJECT', data)
  },
  async upsertTestimonySpeaker({ commit, rootGetters }, params) {
    const response = await apiCalls.upsertTestimonySpeaker(params)
    if (response && response.status === 200) {
      return response.data
    }
    return false
  },
  async loadDinnerSpeakersUrls({ commit, rootGetters }, state_key) {
    let langKey = rootGetters['user/userLanguageKey']
    let csvURL = reportCalls.getDinnerSpeakersReportCSV(state_key, langKey, ANNUAL_DINNER_MEMBERSHIP_SPEAKER_TYPE)
    await Promise.all([commit('SET_DINNER_SPEAKERS_CSV', csvURL)])
    return true
  },
  async loadPastorBanquetSpeakersUrls({ commit, rootGetters }, org_key) {
    let langKey = rootGetters['user/userLanguageKey']
    let csvURL = reportCalls.getPastorBanquetSpeakersReportCSV(org_key, langKey, PASTOR_BANQUET_SPEAKER)
    await Promise.all([commit('SET_PASTOR_BANQUET_SPEAKERS_CSV', csvURL)])
    return true
  },
  async loadCampOfficerSelectionsUrls({ commit, rootGetters }, params) {
    let langKey = rootGetters['user/userLanguageKey']
    let csvURL = reportCalls.getCampOfficerSelectionsReportCSV(params.org_key, params.ctp_key, langKey)
    await Promise.all([commit('SET_CAMP_OFFICER_SELECTIONS_CSV', csvURL)])
    return true
  },
  async loadQualifiedChurchSpeakersUrls({ commit, rootGetters }, org_key) {
    let langKey = rootGetters['user/userLanguageKey']
    let csvURL = await reportCalls.getQualifiedChurchSpeakersCSV(org_key, langKey)
    await Promise.all([commit('SET_QUALIFIED_CHURCH_SPEAKERS_CSV', csvURL)])
  },
  async loadScriptureBlitzSpeakersUrls({ commit, rootGetters }, org_key) {
    let langKey = rootGetters['user/userLanguageKey']
    let csvURL = await reportCalls.getScriptureBlitzSpeakersCSV(org_key, langKey)
    await Promise.all([commit('SET_SCRIPTURE_BLITZ_SPEAKERS_CSV', csvURL)])
  },
  async loadStateCampsUrls({ commit, rootGetters }, org_key) {
    let langKey = rootGetters['user/userLanguageKey']
    let csvURL = await reportCalls.getStateCampsCSV(org_key, langKey)
    await Promise.all([commit('SET_STATE_CAMPS_CSV', csvURL)])
  },
  async loadTestimonySpeakersUrls({ commit, rootGetters }, org_key) {
    let langKey = rootGetters['user/userLanguageKey']
    let csvURL = await reportCalls.getTestimonySpeakersCSV(org_key, langKey)
    await Promise.all([commit('SET_TESTIMONY_SPEAKERS_CSV', csvURL)])
  }
}

export const apiCalls = {
  getACRSpeakers(orgKey, langKey, acr_type_key) {
    const route = API_CONFIG.getACRSpeakers(orgKey, langKey, acr_type_key)
    return axios.get(route)
  },
  upsertACRSpeakers(acr_type_key, data) {
    const route = API_CONFIG.upsertACRSpeakers(acr_type_key)
    return axios.post(route, data)
  },
  upsertTestimonySpeaker(data) {
    const route = API_CONFIG.upsertTestimonySpeaker()
    return axios.post(route, data)
  },
  getQualifiedSpeakers(orgKey, langKey) {
    const route = API_CONFIG.getQualifiedSpeakers(orgKey, langKey)
    return axios.get(route)
  },
  getCampOfficerPositions(orgKey, langKey) {
    const route = API_CONFIG.getCampOfficerPositions(orgKey, langKey)
    return axios.get(route)
  },
  getCampOfficerSelections(orgKey, ctpKey, langKey) {
    const route = API_CONFIG.getCampOfficerSelections(orgKey, ctpKey, langKey)
    return axios.get(route)
  },
  getScriptureBlitzSpeakers(orgKey, langKey) {
    const route = API_CONFIG.getScriptureBlitzSpeakers(orgKey, langKey)
    return axios.get(route)
  },
  getStateCamp(orgKey, langKey) {
    const route = API_CONFIG.getStateCamp(orgKey, langKey)
    return axios.get(route)
  },
  getMembersList(orgKey, langKey) {
    const route = API_CONFIG.getMembersList(orgKey, langKey)
    return axios.get(route)
  },
  getWidowsList(orgKey, langKey) {
    const route = API_CONFIG.getWidowsList(orgKey, langKey)
    return axios.get(route)
  },
  getTestimonySpeakers(orgKey, langKey) {
    const route = API_CONFIG.getTestimonySpeakers(orgKey, langKey)
    return axios.get(route)
  },
  getIndividualTestimonyType(indKey) {
    const route = API_CONFIG.getIndividualTestimonyType(indKey)
    return axios.get(route)
  },
  getTestimonyTypes(langKey) {
    const route = API_CONFIG.getTestimonyTypes(langKey)
    return axios.get(route)
  }
}

export const reportCalls = {
  getWidowListReportCsv(org_key, lng_key, ind_key) {
    return REPORT_CONFIG.getWidowListReport(org_key, lng_key, ind_key, 'excel')
  },
  getStateMemberListReportCSV(state_key, lng_key) {
    const route = REPORT_CONFIG.stateMembersListReport(state_key, lng_key, 'excel')
    return route
  },
  getStateMemberListReportPDF(state_key, lng_key) {
    const route = REPORT_CONFIG.stateMembersListReport(state_key, lng_key, 'pdf')
    return route
  },
  getDinnerSpeakersReportCSV(org_key, lng_key, acr_type_key) {
    let nagKey = constantData.default_nag_key
    const route = REPORT_CONFIG.getDinnerSpeakersReportUrl(org_key, lng_key, acr_type_key, nagKey, 'excel')
    return route
  },
  getPastorBanquetSpeakersReportCSV(org_key, lng_key, acr_type_key) {
    let nagKey = constantData.default_nag_key
    const route = REPORT_CONFIG.getPastorBanquetSpeakersReportUrl(org_key, lng_key, acr_type_key, nagKey, 'excel')
    return route
  },
  getCampOfficerSelectionsReportCSV(org_key, ctp_key, lng_key) {
    const route = REPORT_CONFIG.getCampOfficerSelectionsReportUrl(org_key, ctp_key, lng_key, 'excel')
    return route
  },
  getQualifiedChurchSpeakersCSV(org_key, lng_key) {
    const route = REPORT_CONFIG.getQualifiedChurchSpeakersReportUrl(org_key, lng_key, 'excel')
    return route
  },
  getScriptureBlitzSpeakersCSV(org_key, lng_key) {
    const route = REPORT_CONFIG.getScriptureBlitzSpeakersReportUrl(org_key, lng_key, 'excel')
    return route
  },
  getStateCampsCSV(org_key, lng_key) {
    const route = REPORT_CONFIG.getStateCampsReportUrl(org_key, lng_key, 'excel')
    return route
  },
  getTestimonySpeakersCSV(org_key, lng_key) {
    const route = REPORT_CONFIG.getTestimonySpeakersReportUrl(org_key, lng_key, 'excel')
    return route
  }
}

export const directory = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
